import _get from 'lodash/get';
import _compact from 'lodash/compact';
import _uniq from 'lodash/uniq';
import _uniqBy from 'lodash/uniqBy';
import _sortBy from 'lodash/sortBy';

export const getDoughTypes = variations => {
  const newVariations = variations.map(variant => {
    if (variant.kind !== null) {
      return {
        id: variant.kind.id,
        label: variant.dough,
      };
    }

    return null;
  });

  const uniqTypes = _uniqBy(newVariations, 'label');

  return _sortBy(uniqTypes, 'label').reverse();
};

export const getVariationsByDoughPizza = (variations, doughId) => {
  const variationsWithKindId = variations.filter(variant => variant.kind !== null);

  return variationsWithKindId.filter(variant => variant.kind.id === Number(doughId));
};

export const getVariationsByDough = (variations, dough) => {
  return variations.filter(variant => variant.dough === dough);
};

export const getSizes = variations => {
  const uniqSizes = _uniqBy(
    variations.map(variant => variant.size),
    'value',
  );

  return _compact(_sortBy(uniqSizes, 'value'));
};

export const getVariation = (variations, size, stuffedCrust = 'none') => {
  const result = variations.find(
    variant =>
      _get(variant, 'size.value') === Number(size) &&
      (!variant.stuffed_crust || variant.stuffed_crust === stuffedCrust),
  );

  return result || _get(variations, '[0]', {});
};

// доступно ли чтото кроме стандартного
export const stuffedCrustIsAvailable = (variations, size) => {
  return Boolean(
    variations.find(
      variant => variant.stuffed_crust !== 'none' && _get(variant, 'size.value') === Number(size),
    ),
  );
};

// доступно ли чтото при этом стандартное остуствует
export const onlyStuffedCrustIsAvailable = (variations, size) => {
  const crustIsAvailable = stuffedCrustIsAvailable(variations, size);

  return (
    crustIsAvailable &&
    !variations.find(
      variant => variant.stuffed_crust === 'none' && _get(variant, 'size.value') === Number(size),
    )
  );
};

export const stuffedCrustsAvailableDefaultSort = (variations, size) => {
  return _uniq(
    variations
      .filter(variant => _get(variant, 'size.value') === Number(size))
      .map(variant => variant.stuffed_crust),
  );
};

function sortCrusts(stuffed_crust, stuffed_crust_second) {
  if (stuffed_crust === 'none') {
    return 1;
  }

  if (stuffed_crust_second === 'none') {
    return -1;
  }

  if (stuffed_crust === 'cheese' && stuffed_crust_second === 'sausage') {
    return 1;
  }

  if (stuffed_crust === 'sausage' && stuffed_crust_second === 'cheese') {
    return -1;
  }

  return 0;
}

export const stuffedCrustsAvailable = (variations, size) => {
  return stuffedCrustsAvailableDefaultSort(variations, size).sort(sortCrusts);
};

export const getPersonCount = variation => {
  const characteristics = _get(variation, 'characteristics', []);
  const characteristic = characteristics.find(({ code }) => code === 'per_person');

  return _get(characteristic, 'value');
};

export const getRemovedIngredientsCount = variation => {
  const includeIngredients = _get(variation, 'include_ingredients', []);

  return includeIngredients
    .filter(item => item.removed)
    .reduce((acc, item) => acc + Number(item.count), 0);
};

export const filterGoods = goods =>
  goods.map(
    ({
      id,
      category,
      category_id,
      name,
      description,
      alias,
      data,
      show_ingredients_button,
      types,
      variations,
      is_combo,
      good_type,
    }) => ({
      id,
      category,
      category_id,
      name,
      description,
      alias,
      data,
      show_ingredients_button,
      types,
      variations,
      is_combo,
      good_type,
    }),
  );

export const filterCatalog = catalog =>
  catalog.map(({ id, alias, name, position, goods }) => ({
    id,
    alias,
    name,
    position,
    goods: filterGoods(goods),
  }));
