/* eslint-disable react/prop-types */
import React, { useState, useCallback, useContext } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { debounce } from 'lodash';
import { Separator, RichCell } from '@vkontakte/vkui';
import { Icon24Gift } from '@vkontakte/icons';

// Components
import Amount from '../Amount';
import QuantityControl from '../QuantityControl';
import VariationInfo from '../VariationInfo';

// State
import { removeItem, updateItem } from '../../state/reducers/cart/actions';

// Utils
import ResponsiveContext from '../../utils/ResponsiveContext';
import { isGift } from '../../utils/catalog';

// Icons
import PizzaIcon from '../../icons/common/pizza.svg';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const CartItem = props => {
  const { compositionItem, editable } = props;
  const { item, count, discount_price, price, type, hash } = compositionItem;
  const {
    old_price,
    bonus,
    gift,
    image,
    name,
    custom_name,
    size,
    kind,
    category_id,
    stuffed_crust,
  } = item;

  const isGiftType = isGift(compositionItem);
  const dispatch = useDispatch();
  const { isPhone } = useContext(ResponsiveContext);
  const [isImgLoaded, setIsImagLoaded] = useState(true);

  const updateHandler = params => {
    dispatch(updateItem({ hash, type, item, ...params }));
  };

  const removeHandler = () => {
    dispatch(removeItem(hash));
  };

  const changeQuantityHandler = useCallback(
    debounce(
      quantity =>
        updateHandler({
          sauces: (item?.added_sauces || []).map(sauce => sauce.id),
          count: quantity,
        }),
      250,
    ),
    [item],
  );

  const PriceComponent =
    isGiftType && Number(discount_price) === 0 ? (
      <Icon24Gift className={cx('CartItem__gift')} />
    ) : (
      <Amount
        className={cx('CartItem__amount', { 'CartItem__amount-gift': isGiftType })}
        value={Math.max(old_price || price, price)}
        size={!isPhone ? 'l' : 's'}
        newValue={discount_price}
        bonus={bonus}
      />
    );

  const QuantityComponent = (
    <div className={cx('CartItem__quantity')}>
      {discount_price !== 0 && !isGiftType
        ? editable && (
            <QuantityControl
              quantity={count}
              max={gift ? count : undefined}
              onChange={changeQuantityHandler}
              onRemove={() => removeHandler()}
            />
          )
        : null}
    </div>
  );

  const ImageComponent = (
    <>
      {isImgLoaded ? (
        <img
          src={image}
          alt={custom_name || name}
          className={cx('CartItem__image')}
          onError={() => setIsImagLoaded(false)}
        />
      ) : (
        <div className={cx('CartItem__image_alt')}>
          <PizzaIcon className={cx('CartItem__image_alt-svg')} />
        </div>
      )}
    </>
  );

  const DescriptionComponent = (
    <VariationInfo
      size={size}
      kind={kind}
      type={type}
      className={cx('CartItem__description')}
      category_id={category_id}
      stuffed_crust={stuffed_crust}
    />
  );

  return (
    <>
      <div className={cx('CartItem__cell')}>
        <RichCell
          caption={DescriptionComponent}
          bottom={QuantityComponent}
          before={ImageComponent}
          after={PriceComponent}
          multiline
        >
          <span className={cx('CartItem__title')}>{custom_name || name}</span>
        </RichCell>
      </div>
      <Separator wide />
    </>
  );
};

CartItem.defaultProps = {
  editable: true,
};

CartItem.propTypes = {
  compositionItem: PropTypes.object.isRequired,
  editable: PropTypes.bool,
};

export default CartItem;
