import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _isEmpty from 'lodash/isEmpty';
import _find from 'lodash/find';

// Components
import DoughSelector from '../Selectors/DoughSelector';
import SizeSelector from '../Selectors/SizeSelector';
import CheeseBorderToggle from '../CheeseBorderToggle';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function PizzaSelector(props) {
  const {
    selectStuffedCrust,
    className,
    doughTypes,
    currentDough,
    changeDough,
    sizes,
    currentSize,
    changeSize,
    stuffedCrustIsAvailable,
    disabledStuffedCrust,
    stuffedCrustsAvailable,
    activeStuffedCrust,
    toggleStuffedCrust,
    leftSidePizzaId,
    rightSidePizzaId,
    pageForPopout,
  } = props;

  const cheeseBorderOnChange = value => selectStuffedCrust(value);
  const stuffedCrustIsAdd = activeStuffedCrust !== 'none';

  useEffect(() => {
    // Если выбранного размера нет в другом виде теста,
    // то активируем первый размер из списка
    if (!_isEmpty(sizes) && !_find(sizes, { value: currentSize })) {
      changeSize(sizes[0].value);
    }
  }, [currentDough]);

  useEffect(() => {
    // для половинок если изменились параметры то следует вернуть в none или первое значение в списке
    if (stuffedCrustIsAdd) {
      if (!stuffedCrustsAvailable.includes(activeStuffedCrust)) {
        if (stuffedCrustsAvailable.includes('none') || !stuffedCrustsAvailable.length) {
          toggleStuffedCrust('none');
        } else {
          toggleStuffedCrust(stuffedCrustsAvailable[0] || 'none');
        }
      }
    } else {
      toggleStuffedCrust(activeStuffedCrust);
    }
  }, [currentSize, currentDough, leftSidePizzaId, rightSidePizzaId]);

  return (
    <div className={cx('PizzaSelector', className)}>
      <DoughSelector
        types={doughTypes}
        onChange={changeDough}
        currentDough={currentDough}
        className={cx('PizzaSelector__dough')}
      />

      <div className={cx('PizzaSelector__variations')}>
        {sizes && (
          <SizeSelector
            sizes={sizes}
            onChange={changeSize}
            currentSize={currentSize}
            className={cx('PizzaSelector__sizes')}
          />
        )}
        {stuffedCrustIsAvailable && (
          <CheeseBorderToggle
            className={cx('PizzaSelector__cheese', {
              PizzaSelector__cheese_disabled: false, // если будет реализованна инная логика, дисейбл кнопки есть
            })}
            selected={stuffedCrustIsAdd}
            list={stuffedCrustsAvailable}
            pageForPopout={pageForPopout}
            disabled={disabledStuffedCrust}
            onChange={cheeseBorderOnChange}
            activeStuffedCrust={activeStuffedCrust}
          />
        )}
      </div>
    </div>
  );
}

PizzaSelector.defaultProps = {
  className: '',
  leftSidePizzaId: 0,
  rightSidePizzaId: 0,
  stuffedCrustsAvailable: [],
};

PizzaSelector.propTypes = {
  pageForPopout: PropTypes.string,
  className: PropTypes.string,
  sizes: PropTypes.array.isRequired,
  doughTypes: PropTypes.array.isRequired,
  changeSize: PropTypes.func.isRequired,
  changeDough: PropTypes.func.isRequired,
  selectStuffedCrust: PropTypes.func.isRequired,
  toggleStuffedCrust: PropTypes.func.isRequired,
  stuffedCrustsAvailable: PropTypes.array,
  activeStuffedCrust: PropTypes.string.isRequired,
  leftSidePizzaId: PropTypes.number,
  rightSidePizzaId: PropTypes.number,
  stuffedCrustIsAvailable: PropTypes.bool,
  disabledStuffedCrust: PropTypes.bool,
  currentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  currentDough: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};
