import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Group, Search, Spinner, ModalPage, Placeholder } from '@vkontakte/vkui';

// Components
import ModalHeader from '../partials/ModalHeader';
import CityList from '../partials/CityList';

// Actions
import { fetchUserCity } from '../../state/reducers/city/actions';
import { resetCartState } from '../../state/reducers/cart/actions';
import { requestGoodsAction } from '../../state/reducers/catalog/actions';
import { setActiveModal } from '../../state/reducers/ui/actions';

// Utils
import { onlyMoscowCitiesSelector, otherCitiesSelector } from '../../state/reducers/city/selectors';
import { cleaner } from '../../utils/helpers';
import { TokenStorage, CityStorage } from '../../utils/Storage';

const isAllowedCityDecorator = (list, allowedList) => {
  return list.map(listItem => {
    const condition = allowedList.find(allowedId => Number(allowedId) === listItem.id);

    if (condition) {
      return { ...listItem, allowed: true };
    }

    return { ...listItem, allowed: false };
  });
};

export default function SearchAddressModal(props) {
  const dispatch = useDispatch();

  const { list, allowedList, isLoading } = useSelector(state => state.city);

  const checkedCities = isAllowedCityDecorator(list, allowedList);

  const MoscowCities = onlyMoscowCitiesSelector(checkedCities);
  const otherCities = otherCitiesSelector(checkedCities);
  //
  const [filteredCities, setFilteredCities] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const { id: modalId } = props;

  const setCityHandler = city => {
    dispatch(setActiveModal(null));

    CityStorage.value = city.id;
    TokenStorage.remove();

    dispatch(requestGoodsAction());
    dispatch(resetCartState());
    dispatch(fetchUserCity());
  };

  const onChangeHandler = e => {
    const { value } = e.target;

    if (value === ' ') {
      return;
    }

    setSearchValue(value);

    if (!value) {
      setFilteredCities([]);
      setShowFilter(false);
      return;
    }

    const filteredList = checkedCities.filter(listItem =>
      cleaner(listItem.name).includes(cleaner(value)),
    );

    if (!filteredList.length && value) {
      setFilteredCities([]);
      setShowFilter(true);
      return;
    }

    setFilteredCities(filteredList);
    setShowFilter(true);
  };

  return (
    <ModalPage id={modalId} settlingHeight={80} dynamicContentHeight header={<ModalHeader />}>
      <Search onChange={onChangeHandler} value={searchValue} />
      {isLoading ? (
        <Spinner />
      ) : (
        <>
          {showFilter ? (
            <>
              {filteredCities.length ? (
                <CityList cities={filteredCities} onChange={setCityHandler} />
              ) : (
                <Group>
                  <Placeholder>Город не найден</Placeholder>
                </Group>
              )}
            </>
          ) : (
            <>
              <CityList cities={MoscowCities} header="Москва и область" onChange={setCityHandler} />
              <CityList cities={otherCities} header="Другие города" onChange={setCityHandler} />
            </>
          )}
        </>
      )}
    </ModalPage>
  );
}

SearchAddressModal.propTypes = {
  id: PropTypes.string.isRequired,
};
