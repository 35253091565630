import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import {
  Div,
  Panel,
  Title,
  FormLayout,
  FixedLayout,
  PanelHeader,
  PanelHeaderBack,
  Input,
  FormItem,
  FormLayoutGroup,
} from '@vkontakte/vkui';

// Components
import Button from '../../components/Button';
import Amount from '../../components/Amount';
import Snackbar from '../../components/Snackbar';

// Actions
import { setView } from '../../state/reducers/ui/actions';
import { payOrder } from '../../state/reducers/order/actions';
import { setHolderName } from '../../state/reducers/card/actions';

// Utils
import { MAIN_VIEW } from '../../constants/ViewConstants';
import CardFormatter from '../../formatters/CardFormatter';
import useCloudPaymentsApi from '../../hooks/useCloudPaymentsApi';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const initialState = {
  cvv: '',
  name: '',
  cardNumber: '',
  expDateMonthYear: '',
};

const reducer = (state, newState) => ({ ...state, ...newState });

const OnlinePaymentPanel = props => {
  const { id } = props;

  const dispatch = useDispatch();
  const [form, setForm] = useState(null);

  const { errors, createPacket, clearError } = useCloudPaymentsApi(form);

  const { isPaying } = useSelector(state => state.order.checkout);
  const { price, discount_price } = useSelector(state => state.cart.cartData);

  const [formState, setFormState] = useReducer(reducer, initialState);

  const hasError = Object.values(errors).some(Boolean);
  const hasValues = Object.values(formState).every(Boolean);

  const setStatus = err => (err ? 'error' : 'default');
  const handleBack = () => dispatch(setView(MAIN_VIEW));
  const handleSetName = () => dispatch(setHolderName(formState.name));
  const handlePay = () => {
    createPacket();
    return hasError || !hasValues ? null : dispatch(payOrder());
  };

  const handleChange = e => {
    // в зависимости от поля ввода выбираем текущий форматтер
    // в зависимости от выбранного ключа кадое поле обрабатывается по своему
    const fieldName = e.target.dataset.cp;
    let formatter = '';

    clearError(fieldName);

    switch (fieldName) {
      case 'expDateMonthYear':
        formatter = 'formatDate';
        break;
      case 'cardNumber':
        formatter = 'formatNumber';
        break;
      case 'name':
        formatter = 'formatName';
        break;
      default:
        formatter = 'onlyDigits';
    }

    return setFormState({ [fieldName]: CardFormatter[formatter](e.target.value) });
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack className={cx('OnlinePayment__back')} onClick={handleBack} />}
      >
        Оплата заказа
      </PanelHeader>

      <FormLayout getRef={ref => setForm(ref)} className={cx('OnlinePayment__form')}>
        {/* WARNING нельзя устанавливать атрибут name на поля формы для cloud payments полей */}
        <FormItem
          top="Номер карты"
          status={setStatus(errors.cardNumber)}
          bottom={errors.cardNumber}
        >
          <Input
            placeholder="XXXX XXXX XXXX XXXX"
            data-cp="cardNumber"
            value={formState.cardNumber}
            onChange={handleChange}
            maxLength={19}
          />
        </FormItem>
        <FormLayoutGroup mode="horizontal">
          <FormItem
            top="ММ/ГГ"
            status={setStatus(errors.expDateMonthYear)}
            bottom={errors.expDateMonthYear}
          >
            <Input
              placeholder="00/00"
              data-cp="expDateMonthYear"
              onChange={handleChange}
              value={formState.expDateMonthYear}
            />
          </FormItem>
          <FormItem top="CVV код" status={setStatus(errors.cvv)} bottom={errors.cvv}>
            <Input
              data-cp="cvv"
              type="password"
              maxLength={3}
              placeholder="***"
              value={formState.cvv}
              onChange={handleChange}
            />
          </FormItem>
        </FormLayoutGroup>
        <FormItem top="Имя держателя" status={setStatus(errors.name)} bottom={errors.name}>
          <Input
            placeholder="CARTHOLDER NAME"
            data-cp="name"
            maxLength={25}
            value={formState.name}
            onChange={handleChange}
            onBlur={handleSetName}
          />
        </FormItem>
      </FormLayout>

      <FixedLayout vertical="bottom" filled>
        <Div className={cx('OnlinePayment__title_price')}>
          <Title level="2" weight="semibold" className={cx('OnlinePayment__title')}>
            Сумма заказа
          </Title>
        </Div>
        <Div>
          <Amount value={Math.min(price, discount_price)} size="l" />
        </Div>
        <div className={cx('OnlinePayment__bottom')}>
          <Button stretched margin onClick={handlePay} loading={isPaying}>
            Оплатить
          </Button>
        </div>
      </FixedLayout>
      <Snackbar />
    </Panel>
  );
};

OnlinePaymentPanel.propTypes = {
  id: PropTypes.string.isRequired,
};

export default OnlinePaymentPanel;
