import * as React from 'react';

const Logo = props => (
  <svg
    id="prefix__\u0421\u043B\u043E\u0439_1"
    data-name="\u0421\u043B\u043E\u0439 1"
    viewBox="0 0 115 47"
    width={115}
    height={47}
    {...props}
  >
    <style>{'.prefix__st2{fill:#da291c}.prefix__st3{fill:#007a53}.prefix__st4{fill:#fff}'}</style>
    <path
      d="M106.37 21.34c-7.42-4.28-24.55-8.63-31.79-9.21l-5.45-.44c-5.82-.47-9.03-.73-11.49-.73-2.46 0-16.93 1.17-16.93 1.17-7.25.57-24.37 4.93-31.79 9.21-3.48 2.01-3.86 3.42-3.86 8.15v12.05c0 .98.12 2.41 1.14 3.49 1.18 1.25 2.7 1.25 4.47 1.25H104.62c1.77 0 3.29 0 4.47-1.25 1.02-1.08 1.14-2.51 1.14-3.49V29.49c0-4.73-.38-6.14-3.86-8.15z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#007a53"
    />
    <path
      d="M109.07 41.54c0 .76-.08 1.86-.8 2.62-.84.89-2.02.89-3.65.89H10.67c-1.63 0-2.81 0-3.65-.89-.72-.76-.8-1.86-.8-2.62V29.49c0-4.57.4-5.43 3.26-7.08 7.3-4.21 24.18-8.49 31.33-9.06l5.45-.44c5.8-.66 9.02-.88 11.43-.88s6.06.15 11.36.88l5.45.44c7.15.57 24.03 4.85 31.33 9.06 2.86 1.65 3.26 2.51 3.26 7.08v12.05h-.02z"
      fillRule="evenodd"
      clipRule="evenodd"
      fill="#fff"
    />
    <path
      className="prefix__st2"
      d="M80.41 7.98l-.68-.21c-.19-.05-4.57-1.24-6.02-1.28l.32-1.75c.02-.13-.03-.26-.13-.4-.62-.84-7.07-2.7-16.34-2.7-9.14 0-15.84 1.72-16.47 2.48-.1.12-.14.24-.11.37l.44 1.99c-1.46.04-5.83 1.23-6.02 1.28l-.68.21.41.47 2.59 2.75-.56 4.66-.06.65.59-.15c.54-.13 1.15-.31 1.79-.5 1.48-.44 3.16-.94 4.34-.94.01 0 .88-.02 1.74-.09.3-.03.47-.11.5-.27.1-.4-.91-1.08-1.17-1.25.45-.4 7.44-1.16 12.67-1.13h.23c5.22 0 12.05.75 12.45 1.13-.26.17-1.27.85-1.18 1.26.04.15.2.24.5.27.86.08 1.73.09 1.74.09 1.18 0 2.85.5 4.34.94.64.19 1.25.37 1.79.5l.59.15-.63-5.3L80 8.45l.41-.47z"
    />
    <path
      className="prefix__st3"
      d="M71.27 12.85l-.14-.11c-.55-.53-7.51-1.37-13.43-1.29-5.93-.08-13.32.89-13.72 1.46l-.06.07-.05-.19c-.02-.08-1.62-7.53-1.72-7.92-.01-.04.03-.12.09-.19.58-.74 7.34-2.2 15.46-2.2 3.59 0 7.33.4 10.53.98 2.56.46 4.39.96 4.7 1.36.04.06.03.17.02.23-.09.39-1.51 6.99-1.53 7.06l-.15.74zM43.1 13.74l-1.5-6.5h-.03c-1.15 0-5.39 1.14-5.39 1.14l2.41 2.54-.52 4.49s1.46-.38 2.12-.56c.76-.21 1.48-.41 2.1-.55.47-.1.84-.13 1.17-.15.07-.01.24-.02.31-.02l-.14-.08-.53-.31zM72.01 13.86l1.5-6.5h.03c1.15 0 5.39 1.14 5.39 1.14l-2.41 2.54.52 4.49s-1.46-.38-2.12-.56c-.76-.21-1.48-.41-2.1-.55-.47-.1-.84-.13-1.17-.15-.07-.01-.24-.02-.31-.02l.14-.08.53-.31z"
    />
    <path
      className="prefix__st2"
      d="M98.15 26.26l-.96-.18c.08-.82.18-1.66.26-2.47.42.12.84.27 1.26.4-.17.73-.38 1.49-.56 2.25zM9.6 27.67c3.9-2.92 8.29-3.95 8.29-3.95l-.03 4.24.07 13.61h-3.38l-.02-13.32-1.79.72.03 12.58-3.17.02v-13.9zM34.91 41.57V22.73l-1.91.3.04 18.54h-3.45l.02-16.59c0-.42-.02-4.17-.02-4.17 2.88-.74 8.84-1.51 8.84-1.51l.02 22.27h-3.54zM19.02 41.57l.39-2.75s.25-2.31.36-2.75l1.59-13.41c2.62-.83 4.6-1.04 4.6-1.04l1.42 14.61.66 5.34h-3.03l-.63-6.07h-1.76l-.56 6.07h-3.04m4.43-15.22h-.03l-.57 6.54h1.2l-.6-6.54zM39.74 41.57s.44-5.06.55-5.5l1.47-17.19c1.78-.21 4.62-.48 4.62-.48l2.14 17.84c.07.35.66 5.33.66 5.33h-3.33l-.48-6.96h-2.23l-.38 6.96h-3.22m4.66-17.82h-.03l-.8 7.78h1.67l-.84-7.78zM53.44 35.78V21.92c0-.41.05-3.87.05-3.87 4.35-.23 8.49 0 8.49 0s-.03 3.45-.03 3.87v15.75h1.22v3.88H59.9V40.3h-4.23v1.25h-3.52V37.7l.47-.01s.82-.51.82-1.31v-.6zm5.26-15h-1.79v14.94c0 1-.1 1.33-.3 1.91h2.09V20.78zM71.95 27.99l2.14-9.1c1.17.1 3.37.46 3.37.46L74.55 30.1l2.59 11.48h-3.18l-1.66-9.56h-.03l.13 9.56h-3.67l.03-8.71-.03-.85-1.39 9.56h-3.23l2.25-11.39-2.09-7.08c-.53-2.07-1.18-5.01-1.18-5.01.8-.01 3.19.21 3.19.21L68.76 28l-.03-9.62c.64.01 3.47.34 3.47.34l-.25 9.27zM81.73 41.65c-2.42 0-4.09-1.5-4.09-4.12V23.54c0-2.63 1.67-3.93 4.09-3.79 3.26.18 4.08 1.63 4.08 4.26v13.52c0 2.63-1.67 4.12-4.08 4.12m0-3.13c.7 0 .66-.75.66-.83V23.82c0-.07.04-.82-.66-.82-.71 0-.67.74-.67.82v13.87c0 .07-.04.83.67.83zM92.81 41.57l-.02-7.07h-1.92l.01 7.07h-3.61V21.1l3.59.97.01 8.74h1.92v-8.4l3.59 1.04v18.12zM101.92 28.86c-.73 0-.64.78-.64 1.24v8.04c0 .46-.09 1.24.64 1.24s.89-.79.89-1.24v-1.27h2.69s.1 2.56-.63 3.54c-.73.99-1.28 1.35-2.7 1.39-1.41.04-2.76-.27-3.46-.93-.97-.9-.93-2.08-.93-3.31V29.5c0-1 .13-2.27.95-2.95.76-.63 2.01-.6 3.34-.55 1.34.05 3.03.71 3.25 1.89.22 1.18.18 3.34.18 3.34h-2.69V30.1c0-.46-.16-1.24-.89-1.24"
    />
    <path
      className="prefix__st4"
      d="M45.53 5.47l3.78-.59.74 4.76-.96.15-.62-3.96-1.86.29.62 3.96-.96.15-.74-4.76zM50.59 4.73l.91-.07.26 3.2 1.69-3.35.97-.08.38 4.8-.92.07-.25-3.13-1.69 3.29-.97.08-.38-4.81zM55.69 4.37h.97l.02 4.01 1.89-.01-.02-4.01h.97l.02 4.01h.4l.01 1.86h-.81V9.18l-3.42.02-.03-4.83zM60.91 4.4l.97.08-.32 3.99 1.88.15.32-3.99.97.08-.32 3.99.4.03-.15 1.85-.81-.06.08-1.05-3.4-.27.38-4.8zM69.57 10.37l-1.04-.18-.22-1.15-1.9-.33-.58 1.01-1.02-.18 2.68-4.42 1.01.18 1.07 5.07zm-1.43-2.19l-.34-1.87-.95 1.65 1.29.22z"
    />
    <g>
      <path
        className="prefix__st3"
        d="M108.83 21.15c-.96 0-1.67-.75-1.67-1.68 0-.93.71-1.69 1.67-1.69s1.67.76 1.67 1.69c0 .93-.72 1.68-1.67 1.68zm0-3.12c-.83 0-1.41.64-1.41 1.44 0 .79.58 1.44 1.41 1.44s1.41-.65 1.41-1.44c0-.8-.58-1.44-1.41-1.44zm.57 2.21c-.22 0-.3-.06-.3-.24v-.2c0-.13-.06-.22-.2-.22h-.29v.64h-.29V18.7h.61c.33 0 .45.16.45.41v.05c0 .19-.06.29-.25.33.18.02.26.17.26.32v.17c0 .05.02.06.08.06v.2h-.07zm-.31-1.13c0-.1-.04-.18-.18-.18h-.29v.46h.27c.13 0 .2-.07.2-.21v-.07z"
      />
    </g>
  </svg>
);

export default Logo;
