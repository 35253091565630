import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Components
import MetaOptions from '../MetaOptions';

// Utils
import { isNew, isHit } from '../../utils/catalog';

// Icons
import PizzaIcon from '../../icons/common/pizza.svg';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function ProductCardImage(props) {
  const [badge, setBadge] = useState('');
  const [isLoaded, setIsLoaded] = useState(true);

  const { classNameWrapper, classNameBadge, classNameMeta, types, name, image } = props;

  useEffect(() => {
    if (isHit(types)) {
      setBadge('Hit');
    }

    if (isNew(types)) {
      setBadge('New');
    }
  }, [types]);

  return (
    <figure className={cx('ProductCardImage', classNameWrapper)}>
      {badge && <div className={classNameBadge}>{badge}</div>}
      <MetaOptions types={types} className={classNameMeta} />
      {isLoaded ? (
        <LazyLoadImage alt={name} src={image} onError={() => setIsLoaded(false)} />
      ) : (
        <div className={cx('ProductCardImage_alt')}>
          <PizzaIcon className={cx('ProductCardImage_alt-svg')} />
        </div>
      )}
    </figure>
  );
}

ProductCardImage.defaultProps = {
  name: '',
  classNameWrapper: '',
  classNameBadge: '',
  classNameMeta: '',
};

ProductCardImage.propTypes = {
  classNameWrapper: PropTypes.string,
  classNameBadge: PropTypes.string,
  classNameMeta: PropTypes.string,
  name: PropTypes.string,
  types: PropTypes.array.isRequired,
  image: PropTypes.string.isRequired,
};
