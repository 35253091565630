import React from 'react';
import ReactDom from 'react-dom';
import { Provider } from 'react-redux';

import bridge from '@vkontakte/vk-bridge';
import { VK_APP_INIT } from './constants/BridgeEvents';
import unregister from './core/serviceWorker';

import App from './core/App';
import store from './state';

// Инициализация приложения в среде vk,
// необходима для корректной работы методов vk
bridge.send(VK_APP_INIT, {});

ReactDom.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root'),
);

// Чистим кеш srviceWorker-а
unregister();
