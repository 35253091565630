// Docs
// https://vk.com/dev/vk_bridge_events

export const VK_APP_INIT = 'VKWebAppInit';
export const VK_APP_CLOSE = 'VKWebAppClose';

export const VK_GET_USER_INFO = 'VKWebAppGetUserInfo';
export const VK_GET_GEODATA = 'VKWebAppGetGeodata';
export const VK_GET_USER_EMAIL = 'VKWebAppGetEmail';
export const VK_GET_USER_PHONE = 'VKWebAppGetPhoneNumber';
export const VK_GET_USER_CART = 'VKWebAppGetPersonalCard';

// Storage
export const VK_STORAGE_GET = 'VKWebAppStorageGet';
export const VK_STORAGE_GET_RESULT = 'VKWebAppStorageGetResult';
export const VK_STORAGE_GET_FAIL = 'VKWebAppStorageGetFailed';

export const VK_STORAGE_SET = 'VKWebAppStorageSet';
export const VK_STORAGE_SET_RESULT = 'VKWebAppStorageSetResult';
export const VK_STORAGE_SET_FAIL = 'VKWebAppStorageSetFailed';
