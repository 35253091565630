import cartApi from './api';
import { filterGifts } from './utils';
import * as types from './actionTypes';
import { TokenStorage } from '../../../utils/Storage';

export const actionApplyStock = ({ stock_code, gifts = [] }) => (dispatch, getState) => {
  const { selected = [] } = getState().cart.stockLoader.data;

  dispatch({ type: types.APPLY_STOCK_REQUEST });

  return cartApi
    .requestApplyStock({ stock_code, gifts: [...selected, ...gifts] })
    .then(({ cart, details, ...stockData }) =>
      dispatch({
        type: types.APPLY_STOCK_SUCCESS,
        payload: { cart, stockData: { ...stockData, ...details } },
      }),
    )
    .catch(({ message }) => dispatch({ type: types.APPLY_STOCK_FAILURE, payload: message }));
};

export const actionAddReward = data => (dispatch, getState) => {
  const { gifts = [] } = data;
  const cartState = getState().cart;
  const { code, selected = [] } = cartState.stockLoader.data;
  const { isReplace = false, index } = cartState.stockData;

  const mergedGifts = isReplace
    ? selected
        .slice(0, index)
        .concat(gifts)
        .concat(selected.slice(index + 1))
    : [...selected, ...gifts];

  dispatch({ type: types.APPLY_STOCK_REQUEST });

  return cartApi
    .requestApplyStock({ stock_code: code, gifts: mergedGifts })
    .then(({ cart, details, ...stockData }) =>
      dispatch({
        type: types.APPLY_STOCK_SUCCESS,
        payload: { cart, stockData: { ...stockData, ...details } },
      }),
    )
    .catch(() => dispatch({ type: types.APPLY_STOCK_FAILURE }));
};

export const actionClearStock = () => dispatch => {
  dispatch({ type: types.CLEAR_STOCK_REQUEST });

  cartApi
    .requestClearStock()
    .then(payload => dispatch({ type: types.CLEAR_STOCK_SUCCESS, payload }))
    .catch(() => dispatch({ type: types.CLEAR_STOCK_FAILURE }));
};

export const updateItem = params => (dispatch, getState) => {
  const { hash } = params;
  const { code } = getState().cart.stockLoader.data;

  dispatch({ type: types.UPDATE_ITEM_REQUEST, payload: hash });

  return cartApi
    .requestUpdateItem(params)
    .then(data => {
      dispatch({ type: types.UPDATE_ITEM_SUCCESS, payload: data });

      if (code) {
        dispatch(actionApplyStock({ stock_code: code }));
      }
    })
    .catch(({ message }) =>
      dispatch({ type: types.UPDATE_ITEM_FAILURE, payload: { hash, error: message } }),
    );
};

export const removeItem = hash => (dispatch, getState) => {
  const { code } = getState().cart.stockLoader.data;

  dispatch({ type: types.REMOVE_ITEM_REQUEST, payload: { hash } });

  return cartApi
    .requestRemoveItem(hash)
    .then(payload => {
      dispatch({ type: types.REMOVE_ITEM_SUCCESS, payload });

      if (code) {
        dispatch(actionApplyStock({ stock_code: code }));
      }
    })
    .catch(({ message }) =>
      dispatch({ type: types.REMOVE_ITEM_FAILURE, payload: { hash, error: message } }),
    );
};

export const actionAddToCart = composition => (dispatch, getState) => {
  const { code } = getState().cart.stockLoader.data;

  dispatch({ type: types.ADD_TO_CART_REQUEST });

  return cartApi
    .requestAddToCart(composition)
    .then(payload => {
      if (payload.unauthorized_token) {
        TokenStorage.value = payload.unauthorized_token;
      }

      if (code) {
        dispatch(actionApplyStock({ stock_code: code }));
      }

      dispatch({ type: types.ADD_TO_CART_SUCCESS, payload });
    })
    .catch(({ message }) => dispatch({ type: types.ADD_TO_CART_FAILURE, error: message }));
};

export const actionRequestCart = () => dispatch => {
  // Если нет токена, то не отправляем запрос на получение корзины
  if (!TokenStorage.value) {
    return false;
  }

  dispatch({ type: types.FETCH_ITEMS_REQUEST });

  return cartApi
    .requestCart()
    .then(payload => {
      const { unauthorized_token, stock_code, composition } = payload;
      const gifts = filterGifts(composition);

      if (unauthorized_token) {
        TokenStorage.value = unauthorized_token;
      }

      if (stock_code) {
        dispatch(actionApplyStock({ stock_code, gifts }));
      }

      dispatch({ type: types.FETCH_ITEMS_SUCCESS, payload });
    })
    .catch(({ message }) => dispatch({ type: types.FETCH_ITEMS_FAILURE, error: message }));
};

export const fetchCartItemsIsNeeded = (promoFromLink = null) => {
  return (dispatch, getState) => {
    const { isLoading, isLoaded } = getState().cart.requestLoader;

    if (!isLoading && !isLoaded) {
      return dispatch(actionRequestCart(promoFromLink));
    }

    return false;
  };
};

const clearCartStart = () => ({
  type: types.CLEAR_REQUEST,
});

const clearCartDone = () => ({
  type: types.CLEAR_SUCCESS,
});

const clearCartFail = payload => ({
  type: types.CLEAR_FAILURE,
  payload,
});

export const clearCart = () => {
  return dispatch => {
    dispatch(clearCartStart());

    cartApi
      .requestClear()
      .then(() => dispatch(clearCartDone()))
      .catch(({ message }) => dispatch(clearCartFail(message)));
  };
};

export const setStockGoodsData = payload => ({
  type: types.SET_STOCK_GOODS_DATA,
  payload,
});

export const resetStockGoodsData = () => ({
  type: types.SET_STOCK_GOODS_DATA,
  payload: { index: null, type: null, isReplace: false },
});

export const resetCartState = () => ({
  type: types.RESET_CART_STATE,
});

export const clearError = () => ({
  type: types.CLEAR_ERROR,
});

export const resetStockError = () => ({
  type: types.RESET_STOCK_ERROR,
});
