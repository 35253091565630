import React, { useEffect } from 'react';
import classNames from 'classnames/bind';
import { Snackbar } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setSnackbar } from '../../state/reducers/ui/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const SnackbarComponent = () => {
  const dispatch = useDispatch();

  const { title, duration, type } = useSelector(state => state.ui.snackbar);

  const isSuccess = type === 'success';
  const isError = type === 'error';

  const handleOnClose = () => {
    dispatch(setSnackbar({ title: '' }));
  };

  useEffect(() => {
    return () => {
      dispatch(setSnackbar({ title: '' }));
    };
  }, []);

  return !title ? null : (
    <Snackbar duration={duration} onClose={handleOnClose}>
      <span
        className={cx({
          'Snackbar-error': isError,
          'Snackbar-success': isSuccess,
        })}
      >
        {title}
      </span>
    </Snackbar>
  );
};

export default SnackbarComponent;
