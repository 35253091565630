import _get from 'lodash/get';

const formatIngredient = ingredient => ({
  id: ingredient.id,
  type: ingredient.type,
  count: ingredient.count,
});

const getGoodData = good => {
  const goodId = _get(good, 'id');
  const includeIngredients = _get(good, 'include_ingredients', []);
  const addedIngredients = _get(good, 'added_ingredients', []).map(formatIngredient);
  const addedSauces = _get(good, 'added_sauces', []);
  const manuallyRemovedIngredients = includeIngredients
    .filter(ingredient => ingredient.removed)
    .map(ingredient => ({ id: ingredient.id, count: 0 }));
  const removedIngredients = _get(good, 'removed_ingredients', []).map(formatIngredient);

  return {
    good_id: goodId,
    ingredients: [...addedIngredients, ...removedIngredients, ...manuallyRemovedIngredients],
    sauces: addedSauces.length > 0 ? addedSauces.map(sauce => sauce.id) : undefined,
  };
};

export const preSaveFormatComposition = composition => {
  const filteredComposition = composition.filter(
    ({ type }) => type === 'good' || type === 'combo' || type === 'promotional' || type === 'bonus',
  );

  return filteredComposition.map(good => {
    const { item, ...otherProps } = good;

    if (good.type !== 'combo') {
      return {
        ...getGoodData(item),
        ...otherProps,
      };
    }

    const comboBoxItems = item.combo_box_items.map(comboBoxItem => {
      const { level_id, price, dicount_price, count } = comboBoxItem;
      return {
        ...getGoodData(comboBoxItem),
        level_id,
        price,
        dicount_price,
        count,
        type: 'good',
      };
    });

    return {
      combo_box_id: good.item.combo_box_id,
      combo_box_items: comboBoxItems,
      ...otherProps,
    };
  });
};

export const filterGifts = (composition = []) => {
  return composition
    .filter(good => good.type === 'gift')
    .map(({ item }) => ({ id: item.id, ingredients: item.added_ingredients }));
};
