import * as types from './actionTypes';
import catalogApi from './api';

// Utils
import config from '../../../core/config';
import { CityStorage } from '../../../utils/Storage';

export const requestGoodsAction = params => dispatch => {
  if (!CityStorage.value) {
    return false;
  }

  dispatch({ type: types.FETCH_GOODS_REQUEST });

  return catalogApi
    .requestGoods({ ...params, lang: config.lang })
    .then(payload => dispatch({ type: types.FETCH_GOODS_SUCCESS, payload }))
    .catch(() => dispatch({ type: types.FETCH_GOODS_FAILURE, hasError: true }));
};

export const chooseProduct = product => {
  return {
    type: types.CHOOSE_PRODUCT,
    payload: product,
  };
};

export const setFilteredPizzaIds = pizza => ({
  type: types.SET_FILTERED_PIZZA_IDS,
  payload: pizza,
});

export const setPizzaFilterId = filter => ({
  type: types.SET_PIZZA_FILTERID,
  payload: filter,
});
