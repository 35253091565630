import * as types from './actionTypes';

const initialState = {
  cryptogram: null,
  holderName: null,
};

const cardReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_CRYPTOGRAM: {
      return {
        ...state,
        cryptogram: action.payload,
      };
    }

    case types.SET_HOLDERNAME: {
      return {
        ...state,
        holderName: action.payload || state.holderName,
      };
    }

    case types.AUTH_3DS: {
      return {
        ...state,
        isAdding: false,
        isAdded: action.status,
        error: action.error,
      };
    }

    default:
      return state;
  }
};

export default cardReducer;
