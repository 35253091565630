import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { View, ScreenSpinner } from '@vkontakte/vkui';

// Panels
import CatalogPanel from '../../Panels/CatalogPanel';
import ProductCardPanel from '../../Panels/ProductCardPanel';

// Actions
import { setPizzaFilterId, setFilteredPizzaIds } from '../../state/reducers/catalog/actions';
import { setPagePopout } from '../../state/reducers/ui/actions';

// Utils
import { CATALOG_PANEL, PRODUCT_CARD_PANEL } from '../../constants/PanelConstants';
import useHistory from '../../hooks/useHistory';

const pageType = 'homePage';

const MainView = () => {
  const dispatch = useDispatch();
  const { history, next, prev, activePanel } = useHistory(CATALOG_PANEL);

  const { isLoading } = useSelector(state => state.catalog.catalogLoader);
  const { popout } = useSelector(state => state.ui.homePage);

  const isCatalogPanel = activePanel === CATALOG_PANEL;

  useEffect(() => {
    return () => {
      dispatch(setFilteredPizzaIds([]));
      dispatch(setPizzaFilterId(null));
    };
  }, []);

  useEffect(() => {
    if (isLoading && isCatalogPanel) {
      dispatch(setPagePopout(pageType, <ScreenSpinner />));
    } else {
      dispatch(setPagePopout(pageType, null));
    }
  }, [isLoading]);

  return (
    <View popout={popout} history={history} onSwipeBack={prev} activePanel={activePanel}>
      <CatalogPanel id={CATALOG_PANEL} nextPage={next} />
      <ProductCardPanel id={PRODUCT_CARD_PANEL} nextPage={next} view="catalog" />
    </View>
  );
};

export default MainView;
