const prefix = '@restaurant';

export const FETCH_LIST_REQUEST = `${prefix}/FETCH_LIST_REQUEST`;
export const FETCH_LIST_SUCCESS = `${prefix}/FETCH_LIST_SUCCESS`;
export const FETCH_LIST_FAILURE = `${prefix}/FETCH_LIST_FAILURE`;

export const FETCH_SCHEDULE_REQUEST = `${prefix}/FETCH_SCHEDULE_REQUEST`;
export const FETCH_SCHEDULE_SUCCESS = `${prefix}/FETCH_SCHEDULE_SUCCESS`;
export const FETCH_SCHEDULE_FAILURE = `${prefix}/FETCH_SCHEDULE_FAILURE`;

export const FETCH_DELIVERY_INTERVALS_REQUEST = `${prefix}/FETCH_DELIVERY_INTERVALS_REQUEST`;
export const FETCH_DELIVERY_INTERVALS_SUCCESS = `${prefix}/FETCH_DELIVERY_INTERVALS_SUCCESS`;
export const FETCH_DELIVERY_INTERVALS_FAILURE = `${prefix}/FETCH_DELIVERY_INTERVALS_FAILURE`;

export const FETCH_PICKUP_INTERVALS_REQUEST = `${prefix}/FETCH_PICKUP_INTERVALS_REQUEST`;
export const FETCH_PICKUP_INTERVALS_SUCCESS = `${prefix}/FETCH_PICKUP_INTERVALS_SUCCESS`;
export const FETCH_PICKUP_INTERVALS_FAILURE = `${prefix}/FETCH_PICKUP_INTERVALS_FAILURE`;

export const FETCH_DETAILS_REQUEST = `${prefix}/FETCH_DETAILS_REQUEST`;
export const FETCH_DETAILS_SUCCESS = `${prefix}/FETCH_DETAILS_SUCCESS`;
export const FETCH_DETAILS_FAILURE = `${prefix}/FETCH_DETAILS_FAILURE`;

export const SET_DETAILS_SUCCESS = `${prefix}/SET_DETAILS_SUCCESS`;

export const SET_RESTAURANT_DETAILS = `${prefix}/SET_RESTAURANT_DETAILS`;
export const RESET_RESTAURANT_DETAILS = `${prefix}/RESET_RESTAURANT_DETAILS`;
