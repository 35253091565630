import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { List, Separator, SimpleCell, IconButton } from '@vkontakte/vkui';
import { Icon24Dropdown } from '@vkontakte/icons';

// Components
import CartItem from '../../../../components/CartItem';
import Amount from '../../../../components/Amount';
import Collapse from '../../../../components/Collapse';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function Cart(props) {
  const { className, orderData } = props;
  const [isOpen, setIsOpen] = useState(true);

  return (
    <div className={cx('Cart', className)}>
      <Separator wide />
      <SimpleCell
        onClick={() => setIsOpen(prev => !prev)}
        after={
          <IconButton className={cx('Cart__toggle')}>
            <Icon24Dropdown />
          </IconButton>
        }
      >
        <Amount value={orderData.cart.price} newValue={orderData.cart.discount_price} />
      </SimpleCell>
      <Collapse isOpen={isOpen}>
        <List>
          {orderData.cart.composition.map(compositionItem => (
            <CartItem
              key={compositionItem.hash}
              editable={false}
              compositionItem={compositionItem}
            />
          ))}
        </List>
      </Collapse>
    </div>
  );
}

Cart.defaultProps = {
  className: '',
};

Cart.propTypes = {
  orderData: PropTypes.object.isRequired,
  className: PropTypes.string,
};
