/* eslint-disable no-undef */
// DOCS - https://developers.cloudpayments.ru/#skript-checkout;

import { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

// Utils
import { getCloudPaymentsKey } from '../state/reducers/restaurant/selectors';

// Actions
import { setCryptogram } from '../state/reducers/card/actions';

const initialState = {
  cvv: '',
  name: '',
  cardNumber: '',
  expDateMonthYear: '',
};

const useCloudPaymentsApi = formRef => {
  const dispatch = useDispatch();

  const [errors, setErrors] = useState(initialState);
  const apiRef = useRef(null);

  const cloudKey = useSelector(getCloudPaymentsKey);
  const clearError = name => setErrors(prev => ({ ...prev, [name]: '' }));

  const createCryptoPacket = () => {
    const { success, packet, messages } = apiRef.current.createCryptogramPacket();
    if (success) {
      dispatch(setCryptogram(packet));
    } else {
      setErrors(prev => ({ ...prev, ...messages }));
    }
  };

  useEffect(() => {
    if (cloudKey && formRef) {
      apiRef.current = new cp.Checkout(cloudKey, formRef);
    }
  }, [cloudKey, formRef]);

  return { errors, createPacket: createCryptoPacket, clearError };
};

export default useCloudPaymentsApi;
