import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import _orderBy from 'lodash/orderBy';
import _isEmpty from 'lodash/isEmpty';
import { Select, FormItem } from '@vkontakte/vkui';

// Actions
import {
  fetchDeliveryIntervals,
  fetchPickupIntervals,
} from '../../../../state/reducers/restaurant/actions';
import { setCheckoutDeliveryTime } from '../../../../state/reducers/order/actions';

// Utils
import { localesDate } from '../../../../utils/formatters';

export default function OrderTimeFields() {
  const dispatch = useDispatch();
  // order
  const { deliveryType, deliveryTime, currentAddress } = useSelector(state => state.order.checkout);
  // restaurant
  const { deliveryIntervals, pickupIntervals, details } = useSelector(state => state.restaurant);
  const { list } = deliveryType === 'pickup' ? pickupIntervals : deliveryIntervals;

  const getDays = timeList =>
    !_isEmpty(timeList)
      ? Object.keys(timeList).map(day => ({
          value: day,
          label: isToday(parseISO(day)) ? 'Сегодня' : localesDate(day, 'd MMMM'),
        }))
      : [];

  const getHoursMinutes = (timeList, day) =>
    day
      ? _orderBy(
          timeList[day]?.map(intvls => ({
            label: `${intvls[0]} - ${intvls[1]}`,
            value: intvls[0],
          })),
          ['value'],
          ['asd'],
        )
      : [];

  const days = getDays(list);
  const hoursMinutes = getHoursMinutes(list, deliveryTime.day);

  useEffect(() => {
    dispatch(setCheckoutDeliveryTime({ day: '', hoursMinutes: '' }));

    if (deliveryType === 'delivery') {
      dispatch(fetchDeliveryIntervals({ address: currentAddress.address_in_line }));
    } else if (deliveryType === 'pickup') {
      dispatch(fetchPickupIntervals({ restaurant_id: details.data.id }));
    }
  }, [details.data.id, deliveryType]);

  // если к нам пришел новый список, то ставим первые значения по умолчанию
  useEffect(() => {
    if (!deliveryTime.day) {
      const newDays = getDays(list);

      if (newDays.length > 0) {
        dispatch(
          setCheckoutDeliveryTime({
            day: newDays[0].value,
          }),
        );
      }
    }
  }, [list]);

  useEffect(() => {
    if (deliveryTime.day && !_isEmpty(list)) {
      const newHoursMinutes = getHoursMinutes(list, deliveryTime.day);

      dispatch(
        setCheckoutDeliveryTime({
          hoursMinutes: newHoursMinutes[0].value,
        }),
      );
    }
  }, [deliveryTime.day]);

  return (
    <>
      <FormItem top="День">
        <Select
          name="day"
          options={days}
          value={deliveryTime.day}
          disabled={days.length === 0}
          onChange={e => dispatch(setCheckoutDeliveryTime({ day: e?.target?.value || e }))}
        >
          {days.map(day => (
            <option value={day.value} key={day}>
              {day.label}
            </option>
          ))}
        </Select>
      </FormItem>
      <FormItem top="Время">
        <Select
          name="hoursMinutes"
          options={hoursMinutes}
          value={deliveryTime.hoursMinutes}
          disabled={hoursMinutes.length === 0}
          onChange={e => dispatch(setCheckoutDeliveryTime({ hoursMinutes: e?.target?.value || e }))}
        >
          {hoursMinutes.map(hour => (
            <option value={hour.value} key={hour}>
              {hour.label}
            </option>
          ))}
        </Select>
      </FormItem>
    </>
  );
}
