/* eslint-disable no-return-assign */
import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Cell, List, Placeholder, Title, withModalRootContext } from '@vkontakte/vkui';

// Icons
import Icon56WriteOutline from '@vkontakte/icons/dist/56/write_outline';

// Utils
import useDebounce from '../../../hooks/useDebounce';
import { getSuggestCityArray, getSuggestKladrArray } from '../../../state/reducers/city/selectors';

// Actions
import { setCheckoutCurrentAddress } from '../../../state/reducers/order/actions';
import { setUserCoordinates } from '../../../state/reducers/city/actions';
import { setActiveModal } from '../../../state/reducers/ui/actions';

// API
import suggestionsRequest from '../../../utils/suggestionsApi';

// Styles
import styles from '../styles.styl';

const cx = classNames.bind(styles);

const addressUrlParams = '/suggest/address';
// default amount of addresses
const count = 10;

const initialPlaceholder = {
  title: 'Введите адрес в поле поиска.',
  content: '',
};

const AddressesList = props => {
  const dispatch = useDispatch();

  const isMount = useRef(true);

  const kladrsArray = useSelector(getSuggestKladrArray);
  const citiesArray = useSelector(getSuggestCityArray);

  const { query, updateModalHeight } = props;

  const [suggestedAddresses, setSuggestedAddresses] = useState([]);
  const [placeholder, setPlaceholder] = useState(initialPlaceholder);

  const debouncedQuery = useDebounce(query, 500);

  const onChooseAddressHandler = address => {
    const { value, data } = address;

    const { geo_lon, geo_lat } = data;

    if (geo_lon && geo_lat) {
      dispatch(setUserCoordinates([geo_lat, geo_lon]));
    }

    dispatch(setCheckoutCurrentAddress({ address_in_line: value }));
    dispatch(setActiveModal(null));
  };

  useEffect(() => {
    return () => (isMount.current = false);
  }, []);

  useEffect(() => {
    if (!query) {
      setPlaceholder(initialPlaceholder);
    }
  }, [query]);

  useEffect(() => {
    const locations = citiesArray.concat(kladrsArray);
    const hasLocations = locations.length > 0;

    if (!query) {
      setSuggestedAddresses([]);
    }

    if (hasLocations && debouncedQuery) {
      suggestionsRequest({ query: debouncedQuery, locations, count }, addressUrlParams).then(
        ({ data }) => {
          if (isMount.current) {
            const { suggestions } = data;

            if (!suggestions.length && debouncedQuery) {
              setPlaceholder({
                title: 'К сожалению адрес не найден.',
                content: 'Проверьте соответствие города указанному адресу.',
              });
            }

            setSuggestedAddresses(suggestions);
          }
        },
      );
    }
  }, [debouncedQuery, kladrsArray, citiesArray]);

  useEffect(() => {
    updateModalHeight();
  }, [suggestedAddresses]);

  const PlaceholderTitle = (
    <Title size="2" weight="semibold" className={cx('AddressesList__placeholder_header')}>
      {placeholder.title}
    </Title>
  );

  return (
    <div className={cx('SearchAddressesModal__content')}>
      {suggestedAddresses.length > 0 ? (
        <List>
          {suggestedAddresses.map(address => (
            <Cell key={address.value} onClick={() => onChooseAddressHandler(address)}>
              <div className={cx('SearchAddressesModal__cell')}>{address.value}</div>
            </Cell>
          ))}
        </List>
      ) : (
        <Placeholder
          icon={<Icon56WriteOutline className={cx('AddressesList__placeholder_icon')} />}
          header={PlaceholderTitle}
        >
          {placeholder.content}
        </Placeholder>
      )}
    </div>
  );
};

AddressesList.propTypes = {
  query: PropTypes.string.isRequired,
  updateModalHeight: PropTypes.any.isRequired,
};

export default withModalRootContext(AddressesList);
