import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { ModalPage, Search, Separator } from '@vkontakte/vkui';
import classNames from 'classnames/bind';

// Components
import ModalHeader from '../partials/ModalHeader';
import AddressesList from './partials/AddresesList';

// Utils
import { cleaner } from '../../utils/helpers';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function SearchAddressModal(props) {
  const { id } = props;

  const address = useSelector(state => state.order.checkout.currentAddress.address_in_line);

  const [query, setQuery] = useState('');
  const onChangeHandler = e => setQuery(e.target.value);

  useEffect(() => {
    setQuery(address);
  }, [address]);

  return (
    <ModalPage
      id={id}
      settlingHeight={60}
      dynamicContentHeight
      header={<ModalHeader />}
      className={cx('SearchAddressesModal')}
    >
      <Search after={null} value={query} onChange={onChangeHandler} />
      <Separator wide />
      <AddressesList query={query} />
    </ModalPage>
  );
}

SearchAddressModal.propTypes = {
  id: PropTypes.string.isRequired,
};
