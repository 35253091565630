import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Tabbar, TabbarItem } from '@vkontakte/vkui';
import classNames from 'classnames/bind';

// Icons
import PizzaIcon from '../../icons/common/pizza-secondary.svg';
import ShoppingCartIcon from '../../icons/common/shopping-cart.svg';

// Utils
import { ORDER_STORY, MAIN_STORY } from '../../constants/ViewConstants';
import { getAllAmountOfGoodsInCart } from '../../state/reducers/cart/selectors';

// Actions
import { setStory } from '../../state/reducers/ui/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function TabbarComponent() {
  const dispatch = useDispatch();

  const [goodsAmountInCart, setGoodsAmountInCart] = useState('');

  const { composition } = useSelector(state => state.cart.cartData);

  const handleSetOrderView = () => dispatch(setStory(ORDER_STORY));

  const handleSetMainView = () => dispatch(setStory(MAIN_STORY));

  useEffect(() => {
    const itemsInCartAmount = getAllAmountOfGoodsInCart(composition);
    setGoodsAmountInCart(itemsInCartAmount);
  }, [composition]);

  return (
    <Tabbar itemsLayout="vertical" shadow={false}>
      <TabbarItem onClick={handleSetMainView}>
        <PizzaIcon width="24" height="24" className={cx('Tabbar__icon')} />
      </TabbarItem>
      <TabbarItem label={goodsAmountInCart || null} onClick={handleSetOrderView}>
        <ShoppingCartIcon width="24" height="24" className={cx('Tabbar__icon')} />
      </TabbarItem>
    </Tabbar>
  );
}
