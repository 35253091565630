import React, { useState } from 'react';
import { View } from '@vkontakte/vkui';

// Panels
import ThankYouPanel from '../../Panels/ThankYouPanel';

// Utils
import { THANK_YOU_PANEL } from '../../constants/PanelConstants';

const ThankYouView = () => {
  const [popup, setPopup] = useState(null);

  return (
    <View popup={popup} onSwipeBack={() => null} activePanel={THANK_YOU_PANEL}>
      <ThankYouPanel id={THANK_YOU_PANEL} setPopup={setPopup} />
    </View>
  );
};

export default ThankYouView;
