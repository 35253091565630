import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';

// Components
import StuffedCrust from '../StuffedCrust';

// Icons
import CheeseBorderIcon from '../../icons/common/cheese-border.svg';

// Actions
import { setPagePopout } from '../../state/reducers/ui/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function CheeseBorderToggle(props) {
  const {
    selected,
    onChange,
    list,
    special,
    disabled,
    className,
    activeStuffedCrust,
    pageForPopout,
  } = props;
  const dispatch = useDispatch();

  const onChangeBorderClick = value => {
    onChange(value);
  };

  const stuffedCrustPopout = (
    <StuffedCrust
      list={list}
      pageForPopout={pageForPopout}
      onClick={onChangeBorderClick}
      activeStuffedCrust={activeStuffedCrust}
    />
  );

  const toggle = () => dispatch(setPagePopout(pageForPopout, stuffedCrustPopout));

  return (
    <div className={cx('CheeseBorderToggle', className)}>
      <button
        type="button"
        onClick={toggle}
        disabled={disabled}
        className={cx(`CheeseBorderToggle__toggle${special ? '--special' : ''}`, {
          [`CheeseBorderToggle__toggle${special ? '--special' : ''}_active`]: selected,
        })}
      >
        <div className={cx('CheeseBorderToggle__icon')}>
          <CheeseBorderIcon width={43} height={21} />
        </div>
      </button>
    </div>
  );
}

CheeseBorderToggle.defaultProps = {
  className: '',
  list: [],
};

CheeseBorderToggle.propTypes = {
  pageForPopout: PropTypes.string.isRequired,
  className: PropTypes.string,
  selected: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  special: PropTypes.bool,
  list: PropTypes.array,
  activeStuffedCrust: PropTypes.string,
};
