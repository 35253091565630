import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _join from 'lodash/join';
import _groupBy from 'lodash/groupBy';
import _compact from 'lodash/compact';
import _map from 'lodash/map';

// Utils
import { isPizza } from '../../utils/catalog';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

class VariationInfo extends Component {
  makeInfo = () => {
    const kind = isPizza(this.props) ? this.props.kind?.name : '';
    const size = this.props.size
      ? `${this.props.size?.value || ''}&nbsp;${this.props.size?.unit || ''}`
      : '';
    const cheeseBorder =
      isPizza(this.props) && this.props.stuffed_crust !== 'none' ? this.props.stuffed_crust : '';
    const values = [kind, size, cheeseBorder];
    return _join(_compact(values), ', ');
  };

  makeComboboxInfo = () => {
    const { combo_box_items } = this.props;
    const categories = _groupBy(combo_box_items, 'category');

    return _join(
      _map(categories, (category, index) => `${index}&nbsp;(${category.length})`),
      ', ',
    );
  };

  render() {
    const info = this.props.type !== 'combo' ? this.makeInfo() : this.makeComboboxInfo();

    if (!info.replace('&nbsp;', '').trim()) {
      return null;
    }

    return (
      <div
        className={cx('VariationInfo', this.props.className)}
        dangerouslySetInnerHTML={{ __html: info }}
      />
    );
  }
}

VariationInfo.defaultProps = {
  className: '',
  stuffed_crust: '',
};

VariationInfo.propTypes = {
  className: PropTypes.string,
  category_id: PropTypes.number,
  size: PropTypes.object,
  type: PropTypes.string,
  stuffed_crust: PropTypes.string,
  combo_box_items: PropTypes.array,
};

export default VariationInfo;
