import React, { useEffect, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Group } from '@vkontakte/vkui';
import classNames from 'classnames/bind';

// Component
import ProductList from '../ProductList';

// Actions
import { clearError, fetchCartItemsIsNeeded } from '../../../state/reducers/cart/actions';

// Utils
import useHandleError from '../../../hooks/useHandleError';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const Catalog = props => {
  const dispatch = useDispatch();

  const { nextPage } = props;

  const { categories, list, isLoaded } = useSelector(state => state.catalog.catalogLoader);

  const getGoods = categoryId => list.filter(good => good.category_id === categoryId);

  const getError = state => state.cart;
  useHandleError(getError, clearError);

  const catalogSections = useMemo(() => {
    return categories.length > 0 ? (
      categories.map(category => (
        <ProductList
          key={category.id}
          nextPage={nextPage}
          title={category.name}
          goods={getGoods(category.id)}
          categoryAlias={category.alias}
        />
      ))
    ) : (
      <ProductList goods={list} nextPage={nextPage} />
    );
  }, [categories, nextPage]);

  useEffect(() => {
    dispatch(fetchCartItemsIsNeeded());
  }, []);

  return <Group className={cx('Catalog')}>{isLoaded && catalogSections}</Group>;
};

Catalog.propTypes = {
  nextPage: PropTypes.func.isRequired,
};

export default Catalog;
