import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.styl';

const cx = classNames.bind(styles);

class Type extends PureComponent {
  handleClick = () => {
    this.props.onChange(this.props.type);
  };

  render() {
    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        onClick={this.handleClick}
        className={cx('DoughSelector__type', {
          DoughSelector__type_active: this.props.isActive,
        })}
      >
        {this.props.label}
      </div>
    );
  }
}

Type.propTypes = {
  label: PropTypes.string.isRequired,
  type: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  isActive: PropTypes.bool.isRequired,
};

export default Type;
