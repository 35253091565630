const prefix = '@cart';

export const UPDATE_ITEM_REQUEST = `${prefix}/UPDATE_ITEM_REQUEST`;
export const UPDATE_ITEM_SUCCESS = `${prefix}/UPDATE_ITEM_SUCCESS`;
export const UPDATE_ITEM_FAILURE = `${prefix}/UPDATE_ITEM_FAILURE`;

export const REMOVE_ITEM_REQUEST = `${prefix}/REMOVE_ITEM_REQUEST`;
export const REMOVE_ITEM_SUCCESS = `${prefix}/REMOVE_ITEM_SUCCESS`;
export const REMOVE_ITEM_FAILURE = `${prefix}/REMOVE_ITEM_FAILURE`;

export const FETCH_ITEMS_REQUEST = `${prefix}/FETCH_ITEMS_REQUEST`;
export const FETCH_ITEMS_SUCCESS = `${prefix}/FETCH_ITEMS_SUCCESS`;
export const FETCH_ITEMS_FAILURE = `${prefix}/FETCH_ITEMS_FAILURE`;

export const CLEAR_REQUEST = `${prefix}/CLEAR_REQUEST`;
export const CLEAR_SUCCESS = `${prefix}/CLEAR_SUCCESS`;
export const CLEAR_FAILURE = `${prefix}/CLEAR_FAILURE`;

export const RESET_CART_STATE = `${prefix}/RESET_CART_STATE`;
export const CLEAR_ERROR = `${prefix}/CLEAR_ERROR`;

export const APPLY_STOCK_REQUEST = `${prefix}/APPLY_STOCK_REQUEST`;
export const APPLY_STOCK_SUCCESS = `${prefix}/APPLY_STOCK_SUCCESS`;
export const APPLY_STOCK_FAILURE = `${prefix}/APPLY_STOCK_FAILURE`;

export const CLEAR_STOCK_REQUEST = `${prefix}/CLEAR_STOCK_REQUEST`;
export const CLEAR_STOCK_SUCCESS = `${prefix}/CLEAR_STOCK_SUCCESS`;
export const CLEAR_STOCK_FAILURE = `${prefix}/CLEAR_STOCK_FAILURE`;

export const SET_STOCK_GOODS_DATA = `${prefix}/SET_STOCK_GOODS_DATA`;

export const ADD_TO_CART_REQUEST = `${prefix}/ADD_TO_CART_REQUEST`;
export const ADD_TO_CART_SUCCESS = `${prefix}/ADD_TO_CART_SUCCESS`;
export const ADD_TO_CART_FAILURE = `${prefix}/ADD_TO_CART_FAILURE`;

export const RESET_STOCK_ERROR = `${prefix}/RESET_STOCK_ERROR`;
