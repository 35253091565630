import React, { useState } from 'react';
import { View } from '@vkontakte/vkui';

// Panels
import InitAddressPanel from '../../Panels/InitAddressPanel';

// Utils
import { INIT_ADDRESS_PANEL } from '../../constants/PanelConstants';

const InitAddressView = () => {
  const [popout, setPopout] = useState(null);

  return (
    <View popout={popout} onSwipeBack={() => null} activePanel={INIT_ADDRESS_PANEL}>
      <InitAddressPanel id={INIT_ADDRESS_PANEL} setPopout={setPopout} />
    </View>
  );
};

export default InitAddressView;
