import React from 'react';
import { Panel, PanelHeaderBack, PanelHeader, Div, Headline } from '@vkontakte/vkui';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

// Components
import GoodCard from '../../components/GoodCard';

// Icons
import Logo from '../../icons/Logo';

// State
import { getStockData } from '../../state/reducers/cart/selectors';

// Styles
import styles from './styles.styl';
import { CHECKOUT_PANEL } from '../../constants/PanelConstants';

const cx = classNames.bind(styles);

const StockGoodsPanel = ({ id, nextPage }) => {
  const { goods, title } = useSelector(getStockData);

  const back = () => {
    nextPage(CHECKOUT_PANEL);
  };

  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack className={cx('StockGoodsPanel__back')} onClick={back} />}
      >
        <Logo className={cx('StockGoodsPanel__logo')} />
      </PanelHeader>
      <Div>
        <Headline className={cx('StockGoodsPanel__title')} weight="semibold">
          {title}
        </Headline>
        <div className={cx('StockGoodsPanel__goods')}>
          {(goods || []).map(good => {
            return <GoodCard key={good.id} nextPage={nextPage} {...good} view="stock" />;
          })}
        </div>
      </Div>
    </Panel>
  );
};

StockGoodsPanel.propTypes = {
  id: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default StockGoodsPanel;
