import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import _findIndex from 'lodash/findIndex';

// Components
import Size from './Size';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const SizeSelector = props => {
  const { currentSize, onChange, sizes, className } = props;

  useEffect(() => {
    if (!currentSize) {
      const initialSize = sizes[0].value;
      onChange(initialSize);
    }
  }, []);

  const handleChange = size => {
    if (size.value !== currentSize) {
      onChange(size.value);
    }

    return false;
  };

  const getActiveInd = () => {
    return _findIndex(sizes, { value: currentSize }) === -1
      ? 0
      : _findIndex(sizes, { value: currentSize });
  };

  const activeInd = getActiveInd();

  const overlayStyle = {
    width: `${100 / sizes.length}%`,
    transform: `translateX(${activeInd * 100}%)`,
  };

  return (
    <div className={cx('SizeSelector', className)}>
      <div className={cx('SizeSelector__overlay')} style={overlayStyle} />
      {sizes.map(size => (
        <Size
          size={size}
          key={size.value}
          onChange={handleChange}
          selected={currentSize === size.value}
        />
      ))}
    </div>
  );
};

SizeSelector.defaultProps = {
  className: '',
};

SizeSelector.propTypes = {
  className: PropTypes.string,
  sizes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  currentSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export default memo(SizeSelector);
