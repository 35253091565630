export const CODE_VEGETARIAN = 'veggie';
export const CODE_SPICE = 'spice';
export const CODE_NEW = 'new';
export const CODE_HALAL = 'halal';
export const CODE_HIT = 'bestseller';

export const isVegeterian = types => {
  return Boolean(
    types.find(type => type?.code === CODE_VEGETARIAN || type?.name === 'Вегетарианская'),
  );
};

export const isHot = types => {
  return Boolean(types.find(type => type?.code === CODE_SPICE || type?.name === 'Острая'));
};

export const isNew = types => {
  return Boolean(types.find(type => type?.code === CODE_NEW || type?.name === 'Новинка'));
};

export const isHalal = types => {
  return Boolean(types.find(type => type?.code === CODE_HALAL || type?.name === 'Халяль'));
};

export const isHit = types => {
  return Boolean(types.find(type => type?.code === CODE_HIT || type?.name === 'Хит'));
};

export const isPizza = variation => {
  return variation?.kind;
};

export const isSet = props => {
  return props.type === 'promotional';
};

export const isCombobox = props => {
  if (props.good_type !== undefined && props.good_type !== '') {
    return props.good_type === 'combo';
  }
  return props.type === 'combo';
};

export const isGift = props => {
  if (props.good_type !== undefined && props.good_type !== '') {
    return props.good_type === 'gift';
  }
  return props.type === 'gift';
};

export const isBonus = props => {
  if (props.good_type !== undefined && props.good_type !== '') {
    return props.good_type === 'bonus';
  }
  return props.type === 'bonus';
};
