import React, { useEffect, useRef } from 'react';
import { Div, Spinner, CellButton } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  Icon28CheckCircleOn,
  Icon24ChevronRight,
  Icon24Delete,
  Icon32Gift,
} from '@vkontakte/icons';

// Components
import Collapse from '../../../components/Collapse';

// State
import {
  actionApplyStock,
  actionClearStock,
  setStockGoodsData,
  resetStockGoodsData,
  resetStockError,
} from '../../../state/reducers/cart/actions';

// Utils
import { STOCK_GOODS_PANEL } from '../../../constants/PanelConstants';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const Promocode = ({ nextPage }) => {
  const dispatch = useDispatch();
  const inputRef = useRef(null);

  const { isLoading, isLoaded, isCleared, data = {}, error } = useSelector(
    state => state.cart.stockLoader,
  );
  const { conditions, rewards, code } = data;

  const stockActionsHandler = () => {
    if (isLoaded) {
      dispatch(actionClearStock());
      dispatch(resetStockGoodsData());
    } else {
      dispatch(actionApplyStock({ stock_code: inputRef.current.value, gifts: [] }));
    }
  };

  const setStockGoodsDataHandler = (type, index, isReplace = false) => {
    dispatch(setStockGoodsData({ type, index, isReplace }));
    nextPage(STOCK_GOODS_PANEL, true);
  };

  const resetErrorOnFocus = () => {
    if (error) {
      dispatch(resetStockError());
    }
  };

  const setIcon = () => {
    if (isLoading) {
      return <Spinner size="small" />;
    }
    if (isLoaded) {
      return <Icon24Delete />;
    }
    return <Icon24ChevronRight />;
  };

  const isAllConditionsChecked = () => {
    return conditions.every(condition => condition.check);
  };

  useEffect(() => {
    if (code) {
      inputRef.current.value = code;
    }
  }, [code]);

  useEffect(() => {
    if (isCleared) {
      inputRef.current.value = '';
    }
  }, [isCleared]);

  useEffect(() => {
    return () => {
      if (error) {
        dispatch(resetStockError());
      }
    };
  }, [error]);

  return (
    <>
      <Div className={cx('Promocode')}>
        <input
          ref={inputRef}
          onFocus={resetErrorOnFocus}
          className={cx('Promocode__input', { 'Promocode__input-error': Boolean(error) })}
          placeholder="Введите промокод"
        />
        <button
          className={cx('Promocode__button', {
            'Promocode__button-secondary': isLoaded || isLoading,
            'Promocode__button-active': code,
          })}
          onClick={stockActionsHandler}
          disabled={isLoading}
          type="button"
        >
          {setIcon()}
        </button>
      </Div>
      {error && <Div className={cx('Promocode__error')}>{error}</Div>}
      <Collapse isOpen={Boolean(conditions || rewards)} timing={150}>
        {conditions?.map((condition, index) => {
          const { criteria, check, goods } = condition;

          return (
            <CellButton
              key={criteria}
              before={<Icon28CheckCircleOn />}
              className={cx('Promocode__criteria', { 'Promocode__criteria-checked': check })}
              onClick={() => goods && setStockGoodsDataHandler('condition', index)}
              hasActive={Boolean(goods)}
              hasHover={Boolean(goods)}
            >
              <span>{criteria}</span>
            </CellButton>
          );
        })}
        {rewards?.map((reward, index) => {
          const { message, applied, goods } = reward;
          const isAllChecked = isAllConditionsChecked();
          const isActive = Boolean(goods) && isAllChecked;
          return (
            <CellButton
              key={message}
              before={<Icon32Gift />}
              className={cx('Promocode__reward', {
                'Promocode__reward-ready': !applied && isAllChecked,
                'Promocode__reward-applied': applied,
              })}
              onClick={() => isActive && setStockGoodsDataHandler('reward', index, applied)}
              hasActive={isActive}
              hasHover={isActive}
              description={
                applied && <span className={cx('Promocode__reward-change')}>Изменить</span>
              }
              multiline
            >
              <span>{message}</span>
            </CellButton>
          );
        })}
      </Collapse>
    </>
  );
};

Promocode.propTypes = {
  nextPage: PropTypes.func.isRequired,
};

export default Promocode;
