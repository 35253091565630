import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { View } from '@vkontakte/vkui';

// Panels
import BasketPanel from '../../Panels/BasketPanel';
import CheckoutPanel from '../../Panels/CheckoutPanel';
import CheckoutMapPanel from '../../Panels/CheckoutMapPanel';
import StockGoodsPanel from '../../Panels/StockGoodsPanel';
import ProductCardPanel from '../../Panels/ProductCardPanel';

// Actions
import { fetchRestaurantsIsNeeded } from '../../state/reducers/restaurant/actions';

// Utils
import {
  BASKET_PANEL,
  CHECKOUT_PANEL,
  CHECKOUT_MAP_PANEL,
  STOCK_GOODS_PANEL,
  PRODUCT_CARD_PANEL,
} from '../../constants/PanelConstants';
import useHistory from '../../hooks/useHistory';

const OrderView = () => {
  const dispatch = useDispatch();
  const { history, next, prev, activePanel } = useHistory(BASKET_PANEL);
  const { popout } = useSelector(state => state.ui.homePage);

  useEffect(() => {
    dispatch(fetchRestaurantsIsNeeded());
  }, []);

  return (
    <View activePanel={activePanel} onSwipeBack={prev} history={history} popout={popout}>
      <BasketPanel id={BASKET_PANEL} nextPage={next} />
      <CheckoutPanel id={CHECKOUT_PANEL} nextPage={next} />
      <CheckoutMapPanel id={CHECKOUT_MAP_PANEL} nextPage={next} />
      <StockGoodsPanel id={STOCK_GOODS_PANEL} nextPage={next} />
      <ProductCardPanel id={PRODUCT_CARD_PANEL} nextPage={next} view="stock" />
    </View>
  );
};

export default OrderView;
