import isEmail from 'validator/lib/isEmail';
import isMobilePhone from 'validator/lib/isMobilePhone';

import { plainPhoneNumber } from './telephone';

import config from '../core/config';

export const emailValidator = email => {
  if (isEmail(email)) {
    return { isValid: true };
  }
  return { isValid: false, errorMessage: 'Некорректный e-mail' };
};

export const phoneValidator = phone => {
  const plainPhone = plainPhoneNumber(phone);

  if (isMobilePhone(plainPhone, config.phone.region, { strictMode: true })) {
    return { isValid: true };
  }
  return { isValid: false, errorMessage: 'Некорректный номер телефона' };
};
