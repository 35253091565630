import React, { useReducer, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormLayoutGroup, FormItem, Input } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';

// Components
import PhoneField from '../../../components/PhoneField';

// Action
import { setCheckoutUserData } from '../../../state/reducers/order/actions';

// Utils
import { emailValidator, phoneValidator } from '../../../utils/validators';
import { VK_GET_USER_CART } from '../../../constants/BridgeEvents';

const ContactInformationForm = () => {
  const dispatch = useDispatch();

  const { user_data, error } = useSelector(state => state.order.checkout);

  const [isCartRequested, setIsRequestedUserCart] = useState(false);

  const [errors, setErrors] = useState({
    username: '',
    phone: '',
    email: '',
  });

  const [userData, setUserData] = useReducer((state, newState) => ({ ...state, ...newState }), {
    username: user_data.username || '',
    email: user_data.email || '',
    phone: user_data.phone || '',
  });

  const setStatus = err => (err ? 'error' : 'default');

  const handleOnChange = e => {
    const { name, value } = e.target;

    if (errors[name]) {
      setErrors(prev => ({ ...prev, [name]: '' }));
    }

    return setUserData({ [name]: value });
  };

  const handleOnBlur = () => dispatch(setCheckoutUserData(userData));

  const validateOnBlur = e => {
    const { name, value } = e.target;

    const validators = {
      email: emailValidator,
      phone: phoneValidator,
    };

    const { isValid, errorMessage } = validators[name](value);

    if (!isValid) {
      setErrors(prev => ({ ...prev, [name]: errorMessage }));
    }

    return handleOnBlur();
  };

  const handleGetPersonalCard = () => {
    if (!isCartRequested) {
      bridge
        .send(VK_GET_USER_CART, { type: ['phone', 'email'] })
        .then(data => {
          if (data) {
            const email = data.email || userData.email;
            let phone = data.phone;

            const { isValid, errorMessage } = phoneValidator(phone);

            if (!isValid) {
              phone = '';
              setErrors(prev => ({ ...prev, phone: errorMessage }));
            }

            dispatch(setCheckoutUserData({ phone, email }));
            setUserData({ phone, email });
          }
        })
        .catch(console.warn);
      setIsRequestedUserCart(true);
    }
  };

  useEffect(() => {
    if (error?.user_data) {
      setErrors(prev => ({ ...prev, ...error?.user_data, username: error?.user_data.name }));
      window.scrollTo(0, 0);
    }
  }, [error?.user_data]);

  return (
    <FormLayoutGroup>
      <FormItem top="Имя *" bottom={errors.username} status={setStatus(errors.username)}>
        <Input
          name="username"
          value={userData.username}
          onBlur={handleOnBlur}
          onChange={handleOnChange}
        />
      </FormItem>
      <FormItem top="Телефон *" bottom={errors.phone} status={setStatus(errors.phone)}>
        <PhoneField
          name="phone"
          value={userData.phone}
          onChange={handleOnChange}
          onBlur={validateOnBlur}
          onFocus={handleGetPersonalCard}
        />
      </FormItem>
      <FormItem top="E-mail *" bottom={errors.email} status={setStatus(errors.email)}>
        <Input
          name="email"
          value={userData.email}
          onChange={handleOnChange}
          onBlur={validateOnBlur}
          onFocus={handleGetPersonalCard}
        />
      </FormItem>
    </FormLayoutGroup>
  );
};

export default ContactInformationForm;
