/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import {
  Panel,
  PanelHeader,
  PanelHeaderBack,
  FormLayout,
  Textarea,
  Footer,
  Subhead,
  FormItem,
  Separator,
} from '@vkontakte/vkui';

// Comopnents
import ContactInformationForm from './ContactInformationForm';
import DeliveryTimeForm from './DeliveryTimeForm';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Promocode from './Promocode';
import Snackbar from '../../components/Snackbar';
import Button from '../../components/Button';

// Utils
import { THANK_YOU_VIEW, ONLINE_PAYMENT_VIEW } from '../../constants/ViewConstants';
import useHandleError from '../../hooks/useHandleError';
import { BASKET_PANEL } from '../../constants/PanelConstants';

// Actions
import { setView, setSnackbar } from '../../state/reducers/ui/actions';
import { fetchRestaurantsIsNeeded } from '../../state/reducers/restaurant/actions';
import { setCheckoutDescription, payOrder, resetError } from '../../state/reducers/order/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const Container = ({ top, children }) => (
  <>
    <Subhead className={cx('Checkout__title')} weight="semibold">
      {top}
    </Subhead>
    {children}
    <Separator className={cx('Checkout__separator')} />
  </>
);

const CheckoutPanel = props => {
  const { id, nextPage } = props;
  const dispatch = useDispatch();

  const { isPayed, isPaying, payType } = useSelector(state => state.order.checkout);
  const { address_in_line } = useSelector(state => state.order.checkout.currentAddress);
  const { username, phone, email } = useSelector(state => state.order.checkout.user_data);

  const isOrderAllowed = Boolean(address_in_line);
  const isOnlinePayment = payType === 'online';

  const onBlurHandler = event => {
    const { value } = event.target;
    dispatch(setCheckoutDescription(value));
  };

  const handleClick = () => {
    if (isOnlinePayment) {
      if (!username || !email || !phone) {
        window.scrollTo(0, 0);
        return dispatch(
          setSnackbar({ title: 'Необходимо указать все поля со звездочкой', type: 'error' }),
        );
      }
      return dispatch(setView(ONLINE_PAYMENT_VIEW));
    }

    return dispatch(payOrder());
  };

  const getError = state => {
    return state.order.checkout;
  };

  const toBasketPanel = () => {
    nextPage(BASKET_PANEL);
  };

  useEffect(() => {
    dispatch(fetchRestaurantsIsNeeded());
  }, []);

  useEffect(() => {
    // Перекидывает на thank you view после успешного заказа
    if (isPayed) {
      dispatch(setView(THANK_YOU_VIEW));
    }
  }, [isPayed]);

  useHandleError(getError, resetError);

  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack className={cx('Checkout__back')} onClick={toBasketPanel} />}
      />
      <FormLayout className={cx('Checkout__form')}>
        <Container top="Контактная информация">
          <ContactInformationForm />
        </Container>
        <Container top="Доставка">
          <AddressForm nextPage={nextPage} />
        </Container>
        <Container top="Время доставки">
          <DeliveryTimeForm />
        </Container>
        <Container top="Оплата">
          <PaymentForm />
        </Container>
        <Promocode nextPage={nextPage} />
        <Separator className={cx('Checkout__separator')} />
        <FormItem top="Комментарий курьеру">
          <Textarea name="descriptionToCourier" onBlur={onBlurHandler} />
        </FormItem>
        <Button
          margin
          size="m"
          stretched
          loading={isPaying}
          onClick={handleClick}
          disabled={!isOrderAllowed}
        >
          Заказать
        </Button>
      </FormLayout>
      <Footer className={cx('Checkout__footer')}>
        Нажимая "Заказать" вы даете согласие на обработку ваших персональных данных
      </Footer>
      <Snackbar />
    </Panel>
  );
};

CheckoutPanel.propTypes = {
  nextPage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default CheckoutPanel;
