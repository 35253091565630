import * as types from './actionTypes';

const initialState = {
  errors: {},
};

export default function errorsReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_ERROR:
      return {
        ...state,
        errors: action.payload,
      };
    default:
      return state;
  }
}
