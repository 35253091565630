import formatString from 'format-string-by-pattern';
import { Formatter } from './Formatter';

class CardFormatter extends Formatter {
  cardPattern = 'XXXX XXXX XXXX XXXX';

  formatDate = value => {
    const formatValue = !value ? '' : this.onlyDigits(value);

    let month = formatValue.substring(0, 2);
    let year = formatValue.substring(2, 4);

    if (month.length === 1 && month > 1) {
      month = `0${month}`;
    } else if (month.length === 2) {
      if (Number(month) === 0) {
        month = '01';
      } else if (month > 12) {
        month = '12';
      }
    }

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear().toString().substring(2);

    if (year.length === 1 && year < currentYear[0]) {
      year = '';
    } else if (year.length === 2) {
      if (year[0] < currentYear[0]) {
        year = currentYear;
      }

      if (year <= currentYear) {
        const currentMonth = currentDate.getMonth();

        year = Number(month) < currentMonth ? Number(currentYear) + 1 : currentYear;
      }
    }
    return formatString('XX/XX', month + year);
  };

  formatNumber = value => {
    const cardNumber = !value ? '' : this.onlyDigits(value);

    return formatString(this.cardPattern, cardNumber);
  };

  formatName = value => {
    return !value ? '' : this.onlyLetters(value).toUpperCase();
  };
}

export default new CardFormatter();
