import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Helpers
import { formatNumber } from '../../utils/helpers';

// Components
import Currency from '../Currency';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function Amount(props) {
  const { className, value, newValue, showCurrency, size, prefix, bonus, special } = props;

  const currentPrice = newValue !== undefined && newValue !== value ? newValue : value;
  const oldPrice = newValue !== undefined && newValue !== value ? value : false;
  const currency = bonus ? 'Б' : <Currency />;

  if (oldPrice !== false) {
    return (
      <div
        className={cx(`Amount${special ? '--special' : ''}`, className, {
          [`Amount_size_${size}`]: size,
        })}
      >
        {Boolean(oldPrice) && (
          <div className={cx('Amount__oldPrice')}>
            {formatNumber(oldPrice, { digits: 2 })} {showCurrency && currency}
          </div>
        )}
        {Boolean(prefix) && <span className={cx('Amount__prefix')}>{prefix} </span>}
        <div className={cx('Amount__price')}>
          {formatNumber(currentPrice, { digits: 2 })} {showCurrency && currency}
        </div>
      </div>
    );
  }

  return (
    <div
      className={cx(`Amount${special ? '--special' : ''}`, 'Amount__price', className, {
        [`Amount_size_${size}`]: size,
      })}
    >
      {Boolean(prefix) && <span className={cx('Amount__prefix')}>{prefix} </span>}
      {formatNumber(currentPrice, { digits: 2 })} {showCurrency && currency}
    </div>
  );
}

Amount.defaultProps = {
  className: '',
  value: '-',
  size: 'l',
  showCurrency: true,
  prefix: '',
  bonus: false,
};

Amount.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  newValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  showCurrency: PropTypes.bool,
  size: PropTypes.oneOf(['xs', 's', 'm', 'l']),
  prefix: PropTypes.any,
  bonus: PropTypes.bool,
  special: PropTypes.bool,
};
