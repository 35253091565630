import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { FormLayoutGroup, CellButton, FormItem, Input, Caption } from '@vkontakte/vkui';
import { Icon24Place } from '@vkontakte/icons';

// Utils
import { CHOOSE_ADDRESS_MODAL } from '../../../constants/ModalConstants';
import { CHECKOUT_MAP_PANEL } from '../../../constants/PanelConstants';
import { getUserCityId } from '../../../state/reducers/city/selectors';
import Formatter from '../../../formatters/Formatter';

// Actions
import { fetchRestaurantDetails } from '../../../state/reducers/restaurant/actions';
import { setCheckoutCurrentAddress } from '../../../state/reducers/order/actions';
import { fetchUserCityIfNeeded } from '../../../state/reducers/city/actions';
import { setActiveModal } from '../../../state/reducers/ui/actions';

export default function AddressForm(props) {
  const dispatch = useDispatch();
  const { nextPage } = props;

  const userCityId = useSelector(getUserCityId);
  const { currentAddress } = useSelector(state => state.order.checkout);

  const [address, setAddress] = useReducer((state, newState) => ({ ...state, ...newState }), {
    flat: currentAddress.flat || '',
    floor: currentAddress.floor || '',
    porch: currentAddress.porch || '',
    intercom: currentAddress.intercom || '',
  });

  const handleAddresserModal = () => dispatch(setActiveModal(CHOOSE_ADDRESS_MODAL));
  const handleOnBlur = () => dispatch(setCheckoutCurrentAddress(address));
  const handleOnChange = e => setAddress({ [e.target.name]: Formatter.onlyDigits(e.target.value) });
  const handleSetPanel = () => nextPage(CHECKOUT_MAP_PANEL);

  useEffect(() => {
    dispatch(fetchUserCityIfNeeded(userCityId));
  }, [userCityId]);

  useEffect(() => {
    if (currentAddress.address_in_line) {
      dispatch(fetchRestaurantDetails({ address: currentAddress.address_in_line }));
    }
  }, [currentAddress.address_in_line]);

  return (
    <>
      <FormItem top="Адрес *">
        <Input
          readOnly
          name="address_in_line"
          onFocus={handleAddresserModal}
          value={currentAddress.address_in_line}
        />
      </FormItem>
      <CellButton before={<Icon24Place />} onClick={handleSetPanel}>
        <Caption level={1} weight="semibold">
          Указать на карте
        </Caption>
      </CellButton>
      <FormLayoutGroup mode="horizontal">
        <FormItem top="Квартира">
          <Input
            name="flat"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={address.flat}
            maxLength={10}
          />
        </FormItem>
        <FormItem top="Домофон">
          <Input
            name="intercom"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={address.intercom}
            maxLength={10}
          />
        </FormItem>
      </FormLayoutGroup>
      <FormLayoutGroup mode="horizontal">
        <FormItem top="Этаж">
          <Input
            name="floor"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={address.floor}
            maxLength={10}
          />
        </FormItem>
        <FormItem top="Подъезд">
          <Input
            name="porch"
            onBlur={handleOnBlur}
            onChange={handleOnChange}
            value={address.porch}
            maxLength={3}
          />
        </FormItem>
      </FormLayoutGroup>
    </>
  );
}

AddressForm.propTypes = {
  nextPage: PropTypes.func.isRequired,
};
