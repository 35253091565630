import { post } from '../../../utils/Request';
import { TokenStorage } from '../../../utils/Storage';

export default {
  pay: params =>
    post({
      url: 'order/save',
      data: TokenStorage.withToken(params),
    }),
};
