import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Panel, FixedLayout, PanelHeader, Placeholder, PanelHeaderButton } from '@vkontakte/vkui';
import { Icon28Delete } from '@vkontakte/icons';

// Components
import OrderCartItemsList from './OrderCartItemsList';
import Snackbar from '../../components/Snackbar';
import Amount from '../../components/Amount';
import Button from '../../components/Button';

// Actions
import { clearCart } from '../../state/reducers/cart/actions';

// Utils
import { CHECKOUT_PANEL } from '../../constants/PanelConstants';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const BasketPanel = props => {
  const { id, nextPage } = props;
  const { composition, price, discount_price } = useSelector(state => state.cart.cartData);

  const dispatch = useDispatch();
  const isHasComposition = composition.length > 0;

  const handleSetPanel = () => nextPage(CHECKOUT_PANEL);

  const СlearOrderButton = (
    <>
      {isHasComposition ? (
        <PanelHeaderButton onClick={() => dispatch(clearCart())}>
          <Icon28Delete className={cx('BasketPanel__button-clear')} />
        </PanelHeaderButton>
      ) : null}
    </>
  );

  return (
    <Panel id={id}>
      <PanelHeader left={СlearOrderButton}>Корзина</PanelHeader>
      {isHasComposition ? (
        <>
          <OrderCartItemsList />
          <FixedLayout vertical="bottom" filled className={cx('BasketPanel__bottom')}>
            <div className={cx('BasketPanel__price')}>
              <Amount value={price} newValue={discount_price} size="l" />
            </div>
            <Button onClick={handleSetPanel} stretched margin size="m">
              Оформить заказ
            </Button>
          </FixedLayout>
        </>
      ) : (
        <Placeholder>
          Корзина пуста. <br />
          Выберите пиццу из меню.
        </Placeholder>
      )}
      <Snackbar />
    </Panel>
  );
};

BasketPanel.propTypes = {
  id: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
};

export default BasketPanel;
