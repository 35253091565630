import { createStore, applyMiddleware, combineReducers, compose } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import ui from './reducers/ui';
import errors from './reducers/errors';
import card from './reducers/card';
import cart from './reducers/cart';
import city from './reducers/city';
import user from './reducers/user';
import order from './reducers/order';
import catalog from './reducers/catalog';
import restaurant from './reducers/restaurant';

const reducers = combineReducers({
  ui,
  cart,
  card,
  city,
  user,
  order,
  errors,
  catalog,
  restaurant,
});

const __DEV__ = process.env.NODE_ENV === 'development';
const composer = __DEV__ ? composeWithDevTools : compose;

export default createStore(reducers, composer(applyMiddleware(thunk)));
