import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Headline, Group, Spinner } from '@vkontakte/vkui';
import Icon16Verified from '@vkontakte/icons/dist/16/verified';

// Context
import ResponsiveContext from '../../../../utils/ResponsiveContext';

// Icons
import InWay from '../../../../icons/orderStatus/in-way.svg';
import Prepared from '../../../../icons/orderStatus/prepared.svg';
import Delivered from '../../../../icons/orderStatus/delivered.svg';
import Taken from '../../../../icons/orderStatus/taken.svg';
import Ready from '../../../../icons/orderStatus/ready.svg';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function OrderStatusSteps(props) {
  const { orderData } = props;

  // context
  const { isPhone } = useContext(ResponsiveContext);

  const statuses = orderData.delivery_type !== 'pickup' ? [1, 2, 4, 5] : [1, 2, 3];

  const orderStatusClassSetter = id => {
    return isPhone && orderData.order_status === id;
  };

  const statusItem = (id, text, Icon, currentStepDiff) => {
    const isPrevious = currentStepDiff > 0;
    const isActive = currentStepDiff === 0;

    const getIcon = () => {
      if (Number(orderData.order_status) === 1) {
        return null;
      }

      if (isActive && statuses[statuses.length - 1] !== id) {
        return <Spinner size="small" className={cx('Steps__item-icon-loader')} />;
      }

      if (isPrevious) {
        return <Icon16Verified className={cx('Steps__item-icon-check')} />;
      }

      return null;
    };

    return (
      <div
        key={id}
        className={cx('Steps__item', {
          Steps__item_active: isActive,
          Steps__item_previous: isPrevious,
        })}
      >
        <Icon width={70} height={60} />
        <div className={cx('Steps__item-title')}>
          <Headline
            className={cx('Steps__item-name', {
              'Steps__item-name_active': isActive,
              'Steps__item-name_previous': isPrevious,
            })}
          >
            {text}
          </Headline>
          {getIcon()}
        </div>
      </div>
    );
  };

  const renderStatus = id => {
    const newOrderStatus =
      Number(orderData.order_status) === 0 ? 1 : Number(orderData.order_status);
    const currentStepDiff = newOrderStatus - id;

    switch (id) {
      case 1:
        return statusItem(id, 'Заказ принят', Taken, currentStepDiff);
      case 2:
        return statusItem(id, 'Заказ готовится', Prepared, currentStepDiff);
      case 3:
        return statusItem(id, 'Заказ готов', Ready, currentStepDiff);
      case 4:
        return statusItem(id, 'Курьер в пути', InWay, currentStepDiff);
      case 5:
        return statusItem(id, 'Доставлено', Delivered, currentStepDiff);
      default:
        return false;
    }
  };

  return (
    <Group>
      <div className={cx('Steps')}>
        <div
          className={cx('Steps__list', {
            'Steps__order-default': orderStatusClassSetter(0),
            'Steps__order-taken': orderStatusClassSetter(1),
            'Steps__order-prepared': orderStatusClassSetter(2),
            'Steps__order-ready': orderStatusClassSetter(3),
            'Steps__order-inWay': orderStatusClassSetter(4),
            'Steps__order-delivered': orderStatusClassSetter(5),
          })}
        >
          {statuses.map(id => renderStatus(id))}
        </div>
      </div>
    </Group>
  );
}

OrderStatusSteps.defaultProps = {
  className: '',
};

OrderStatusSteps.propTypes = {
  className: PropTypes.string,
  orderId: PropTypes.number.isRequired,
  orderData: PropTypes.object.isRequired,
  handleSetOrderData: PropTypes.func.isRequired,
};
