import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { ModalRoot } from '@vkontakte/vkui';

// Modals
import SearchCitiesModal from './SearchCitiesModal';
import SearchAddressesModal from './SearchAddressesModal';

// Utils
import { CHOOSE_ADDRESS_MODAL, CHOOSE_CITY_MODAL } from '../constants/ModalConstants';

// Actions
import { setActiveModal } from '../state/reducers/ui/actions';

export default function MainModal() {
  const { activeModal } = useSelector(state => state.ui);
  const dispatch = useDispatch();

  const handleSetModal = () => dispatch(setActiveModal(null));

  return (
    <ModalRoot activeModal={activeModal} onClose={handleSetModal}>
      <SearchCitiesModal id={CHOOSE_CITY_MODAL} />
      <SearchAddressesModal id={CHOOSE_ADDRESS_MODAL} dynamicContentHeight />
    </ModalRoot>
  );
}
