import { TokenStorage } from '../../../utils/Storage';
import { get, post, del } from '../../../utils/Request';
import { preSaveFormatComposition } from './utils';

const requestApplyStock = data => post({ url: 'stock/apply', data });

const requestClearStock = () => post({ url: 'stock/clear', data: TokenStorage.withToken() });

const requestCart = () => get({ url: 'cart/get', data: TokenStorage.withToken() });

const requestClear = () => del({ url: 'cart/clear', data: TokenStorage.withToken() });

const requestAddToCart = composition =>
  post({
    url: 'cart/add',
    data: TokenStorage.withToken({ composition: preSaveFormatComposition(composition) }),
  });

const requestUpdateItem = params =>
  post({
    url: 'cart/update',
    data: TokenStorage.withToken({
      composition:
        params.type !== 'combo'
          ? preSaveFormatComposition([params])
          : [
              {
                type: 'combo',
                count: params.count,
                hash: params.hash,
              },
            ],
    }),
  });

const requestRemoveItem = hash => {
  // Может передаваться строка (хеш) а может массив хешей

  if (typeof hash === 'string') {
    return del({
      url: 'cart/remove',
      data: TokenStorage.withToken({ hash }),
    });
  }

  return del({
    url: 'cart/remove',
    data: TokenStorage.withToken(),
    body: { hash },
  });
};

export default {
  requestRemoveItem,
  requestUpdateItem,
  requestApplyStock,
  requestClearStock,
  requestAddToCart,
  requestClear,
  requestCart,
};
