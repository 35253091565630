import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const initialState = {
  checkout: {
    orderTime: 0,
    deliveryType: 'delivery',
    deliveryInterval: '',
    payType: 'cash',
    isPaying: false,
    isPayed: false,
    error: '',
    isContactFreeDelivery: false,
    user_data: {
      username: '',
      phone: '',
      email: '',
      subscription_state: false,
      sms_state: false,
    },
    currentAddress: {
      id: null,
      address_in_line: '',
      flat: '',
      floor: '',
      porch: '',
      intercom: '',
    },
    bonusesType: 'another_bonuses',
    deliveryTimeType: 'instantly',
    deliveryTime: { day: '', hoursMinutes: '' },
    change: '',
    description: '',
    // Ответ на order/save
    order_data: {},
    order_id: null,
    payment: {},
  },
};

export default function orderReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_CHECKOUT_USER_DATA:
      return immutable.assign(state, ['checkout', 'user_data'], {
        ...action.payload,
      });

    case types.SET_CHECKOUT_DELIVERY_TYPE:
      return immutable.assign(state, 'checkout', { deliveryType: action.payload });

    case types.SET_CHECKOUT_CURRENT_ADDRESS:
      return immutable.assign(state, ['checkout', 'currentAddress'], {
        ...action.payload,
      });

    case types.SET_CHECKOUT_PAY_TYPE:
      return immutable.assign(state, 'checkout', {
        payType: action.payload,
      });

    case types.SET_CHECKOUT_DELIVERY_TIME_TYPE:
      return immutable.assign(state, 'checkout', {
        deliveryTimeType: action.payload,
      });

    case types.SET_CHECKOUT_DELIVERY_TIME:
      return immutable.assign(state, ['checkout', 'deliveryTime'], {
        ...action.payload,
      });

    case types.SET_CHECKOUT_DESCRIPTION:
      return immutable.assign(state, 'checkout', {
        description: action.payload,
      });

    case types.PAY_REQUEST:
      return immutable.assign(state, 'checkout', {
        isPaying: true,
        isPayed: false,
      });

    case types.PAY_SUCCESS:
      return immutable.assign(state, 'checkout', {
        ...action.payload,
        isPaying: false,
        isPayed: action.payload.isPayed,
        error: action.payload.error,
      });

    case types.PAY_FAILURE:
      return immutable.assign(state, 'checkout', {
        isPaying: false,
        isPayed: false,
        error: action.payload,
      });

    case types.SET_CHECKOUT_IS_PAYED:
      return immutable.assign(state, 'checkout', {
        isPayed: action.payload.isPayed,
        error: action.payload.error,
      });

    case types.RESET_CHECKOUT: {
      // TODO узнать для чего payload
      if (action.payload) {
        return {
          ...state,
          checkout: {
            ...state.checkout,
            [action.payload]: initialState.checkout[action.payload],
          },
        };
      }

      return { ...state, checkout: initialState.checkout };
    }

    case types.CHECKOUT_CLEAR_ERROR:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          error: '',
        },
      };

    case types.RESET_ORDER_PAYMENT:
      return {
        ...state,
        checkout: {
          ...state.checkout,
          order_data: {},
          order_id: null,
          payment: {},
        },
      };

    default:
      return state;
  }
}
