import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { Button, HorizontalScroll } from '@vkontakte/vkui';

// Icons
import Icon16Cancel from '@vkontakte/icons/dist/16/cancel';

// State
import { setPizzaFilterId } from '../../state/reducers/catalog/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function PizzaFilter(props) {
  const { goods, changeFilterPizza } = props;

  const dispatch = useDispatch();

  const { filterId } = useSelector(state => state.catalog);

  const expressFilter = () => {
    const types = {};

    goods.forEach(good => {
      (good.types || []).forEach(type => {
        if (!types[type?.name]) {
          types[type?.name] = true;
        }
      });
    });

    return Object.keys(types).map(type => ({ id: type, label: type }));
  };

  const changeFilter = () => {
    const filteredGoods = goods.filter(good => {
      if (
        !filterId ||
        (good.types || []).some(type => {
          return type.name === filterId;
        })
      ) {
        return true;
      }

      return false;
    });

    changeFilterPizza(filteredGoods.map(fileredGood => fileredGood.id));
  };

  const onSelect = itemId => {
    if (filterId === itemId) {
      dispatch(setPizzaFilterId(null));
    } else {
      dispatch(setPizzaFilterId(itemId));
    }
  };

  useEffect(() => {
    changeFilter();
  }, [filterId]);

  return (
    <HorizontalScroll>
      <div className={cx('PizzaFilter__wrapper')}>
        {expressFilter().map(item => {
          const isSelected = filterId === item.id;

          return (
            <Button
              size="m"
              key={item.id}
              mode="outline"
              onClick={() => onSelect(item.id)}
              className={cx('PizzaFilter__button', {
                'PizzaFilter__button-selected': isSelected,
              })}
              after={isSelected && <Icon16Cancel />}
            >
              {item.label}
            </Button>
          );
        })}
      </div>
    </HorizontalScroll>
  );
}

PizzaFilter.propTypes = {
  changeFilterPizza: PropTypes.func.isRequired,
  goods: PropTypes.array.isRequired,
};
