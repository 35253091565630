/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { ActionSheet, ActionSheetItem, Avatar } from '@vkontakte/vkui';

// Actions
import { setPagePopout } from '../../state/reducers/ui/actions';

// Assets
import stuffedCrustNone from './assets/stuffedCrustNone.png';
import stuffedCrustCheese from './assets/stuffedCrustCheese.png';
import stuffedCrustSausage from './assets/stuffedCrustSausage.png';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const stuffedCrustImages = {
  none: stuffedCrustNone,
  cheese: stuffedCrustCheese,
  sausage: stuffedCrustSausage,
};

const stuffedCrustContent = {
  none: 'Стандартный борт',
  cheese: 'Сырный борт',
  sausage: 'Колбасный борт',
};

export default function StuffedCrust(props) {
  const dispatch = useDispatch();

  const { onClick, list, pageForPopout } = props;

  const onCloseHandler = () => dispatch(setPagePopout(pageForPopout, null));

  const IosCloseItem = (
    <ActionSheetItem autoclose mode="cancel">
      <span className={cx('StuffedCrust__item', 'StuffedCrust__item-close')}>Закрыть</span>
    </ActionSheetItem>
  );

  return (
    <ActionSheet onClose={onCloseHandler} iosCloseItem={IosCloseItem}>
      {list.map(type => {
        return (
          <ActionSheetItem
            autoclose
            key={type}
            onClick={() => onClick(type)}
            before={<Avatar src={stuffedCrustImages[type]} />}
          >
            <span className={cx('StuffedCrust__item')}>{`${[stuffedCrustContent[type]]}`}</span>
          </ActionSheetItem>
        );
      })}
    </ActionSheet>
  );
}

StuffedCrust.defaultProps = {
  className: '',
  list: [],
};

StuffedCrust.propTypes = {
  pageForPopout: PropTypes.string.isRequired,
  className: PropTypes.string,
  list: PropTypes.array,
  onClick: PropTypes.func,
  onClose: PropTypes.func,
  active: PropTypes.bool,
  btnRef: PropTypes.object,
  activeStuffedCrust: PropTypes.string,
};
