const prefix = '@order';

export const RESET_ORDER_STATE = `${prefix}/RESET_ORDER_STATE`;

export const ADD_TO_CART_REQUEST = `${prefix}/ADD_TO_CART_REQUEST`;
export const ADD_TO_CART_SUCCESS = `${prefix}/ADD_TO_CART_SUCCESS`;
export const ADD_TO_CART_FAILURE = `${prefix}/ADD_TO_CART_FAILURE`;

export const SET_CHECKOUT_USER_DATA = `${prefix}/SET_CHECKOUT_USER_DATA`;
export const SET_CHECKOUT_DELIVERY_TYPE = `${prefix}/SET_CHECKOUT_DELIVERY_TYPE`;
export const SET_CHECKOUT_CURRENT_ADDRESS = `${prefix}/SET_CHECKOUT_CURRENT_ADDRESS`;
export const SET_CHECKOUT_DELIVERY_TIME_TYPE = `${prefix}/SET_CHECKOUT_DELIVERY_TIME_TYPE`;
export const SET_CHECKOUT_DELIVERY_TIME = `${prefix}/SET_CHECKOUT_DELIVERY_TIME`;
export const SET_CHECKOUT_PAY_TYPE = `${prefix}/SET_CHECKOUT_PAY_TYPE`;
export const SET_CHECKOUT_CONTACT_FREE_DELIVERY = `${prefix}/SET_CHECKOUT_CONTACT_FREE_DELIVERY`;
export const SET_CHECKOUT_DESCRIPTION = `${prefix}/SET_CHECKOUT_DESCRIPTION`;
export const SET_CHECKOUT_IS_PAYED = `${prefix}/SET_CHECKOUT_IS_PAYED`;

export const RESET_CHECKOUT = `${prefix}/RESET_CHECKOUT`;
export const RESET_ORDER_PAYMENT = `${prefix}/RESET_ORDER_PAYMENT`;

export const PAY_REQUEST = `${prefix}/PAY_REQUEST`;
export const PAY_SUCCESS = `${prefix}/PAY_SUCCESS`;
export const PAY_FAILURE = `${prefix}/PAY_FAILURE`;

export const CHECKOUT_CLEAR_ERROR = `${prefix}/CHECKOUT_CLEAR_ERROR`;
