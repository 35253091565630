import * as types from './actionTypes';

const initialState = {
  isClearing: false,
  erroe: '',
  cartData: {
    can_pay_personally: undefined,
    cart_id: undefined,
    cash_back: undefined,
    composition: [],
    discount_price: undefined,
    price: undefined,
    sailplay_discount: undefined,
    stock_code: undefined,
    unauthorized_token: undefined,
  },
  addLoader: {
    isLoading: false,
    isLoaded: false,
  },
  requestLoader: {
    isLoading: false,
    isLoaded: false,
  },
  stockLoader: {
    isCleared: false,
    isLoading: false,
    isLoaded: false,
    error: '',
    data: {},
  },
  stockData: {
    type: null, // reward | condition
    index: null,
    isReplace: false,
  },
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case types.ADD_TO_CART_REQUEST: {
      return {
        ...state,
        addLoader: {
          isLoading: true,
          isLoaded: false,
        },
      };
    }

    case types.ADD_TO_CART_SUCCESS: {
      return {
        ...state,
        cartData: action.payload,
        addLoader: {
          isLoading: false,
          isLoaded: true,
        },
      };
    }

    case types.ADD_TO_CART_FAILURE: {
      return {
        ...state,
        addLoader: {
          isLoading: false,
          isLoaded: false,
        },
      };
    }

    case types.UPDATE_ITEM_REQUEST: {
      return {
        ...state,
        error: '',
      };
    }

    case types.UPDATE_ITEM_SUCCESS: {
      return {
        ...state,
        cartData: action.payload,
      };
    }

    case types.UPDATE_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case types.REMOVE_ITEM_REQUEST:
      return {
        ...state,
        error: '',
      };

    case types.REMOVE_ITEM_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
      };

    case types.REMOVE_ITEM_FAILURE:
      return {
        ...state,
        error: action.payload.error,
      };

    case types.FETCH_ITEMS_REQUEST:
      return {
        ...state,
        requestLoader: {
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };

    case types.FETCH_ITEMS_SUCCESS:
      return {
        ...state,
        requestLoader: {
          isLoading: false,
          isLoaded: true,
          error: '',
        },
        cartData: action.payload,
      };

    case types.FETCH_ITEMS_FAILURE:
      return {
        ...state,
        requestLoader: {
          isLoading: false,
          isLoaded: false,
          error: action.error,
        },
      };

    case types.CLEAR_REQUEST:
      return {
        ...state,
        isClearing: true,
      };

    case types.CLEAR_SUCCESS:
      return {
        ...initialState,
        requestLoader: {
          ...initialState.requestLoader,
          isLoaded: true,
        },
      };

    case types.CLEAR_FAILURE:
      return {
        ...state,
        isClearing: false,
        requestLoader: {
          ...initialState.requestLoader,
          error: action.error,
        },
      };

    case types.APPLY_STOCK_REQUEST:
      return {
        ...state,
        stockLoader: {
          ...state.stockLoader,
          isLoading: true,
          isLoaded: false,
          error: '',
        },
      };

    case types.APPLY_STOCK_SUCCESS:
      return {
        ...state,
        stockLoader: {
          isCleared: false,
          isLoading: false,
          isLoaded: true,
          error: '',
          data: action.payload.stockData,
        },
        cartData: action.payload.cart,
      };

    case types.APPLY_STOCK_FAILURE:
      return {
        ...state,
        stockLoader: {
          isLoading: false,
          isLoaded: false,
          error: action.payload,
        },
      };

    case types.SET_STOCK_GOODS_DATA:
      return {
        ...state,
        stockData: action.payload,
      };

    case types.CLEAR_STOCK_REQUEST:
      return {
        ...state,
        stockLoader: {
          ...state.stockLoader,
          isLoading: true,
          isLoaded: false,
        },
      };

    case types.CLEAR_STOCK_SUCCESS:
      return {
        ...state,
        cartData: action.payload,
        stockLoader: {
          isCleared: true,
          isLoading: false,
          isLoaded: false,
          data: {},
        },
      };

    case types.CLEAR_STOCK_FAILURE:
      return {
        ...state,
        stockLoader: {
          ...state.stockLoader,
          isLoading: false,
          isLoaded: true,
        },
      };

    case types.RESET_STOCK_ERROR:
      return {
        ...state,
        stockLoader: {
          ...state.stockLoader,
          error: '',
        },
      };

    case types.RESET_CART_STATE:
      return initialState;

    case types.CLEAR_ERROR:
      return {
        ...state,
        error: '',
      };

    default:
      return state;
  }
}
