import React, { useRef, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Div, Title } from '@vkontakte/vkui';
import { useSelector, useDispatch } from 'react-redux';

// Components
import GoodCard from '../../../components/GoodCard';
import PizzaFilter from '../../../components/PizzaFilter';

// Actions
import { setFilteredPizzaIds } from '../../../state/reducers/catalog/actions';

// Utils
import { isCombobox } from '../../../utils/catalog';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const ProductList = props => {
  const { categoryAlias, goods, title, nextPage } = props;

  const dispatch = useDispatch();

  const { filteredPizzaIds } = useSelector(state => state.catalog);

  const isMounted = useRef(true);

  const isPizzaType = categoryAlias === 'pizza';

  const changeFilterPizza = ids => {
    if (isMounted.current) {
      dispatch(setFilteredPizzaIds(ids));
    }
  };

  const goodsList = useMemo(() => {
    if (isPizzaType) {
      return filteredPizzaIds.length > 0
        ? goods.filter(good => filteredPizzaIds.includes(good.id))
        : goods;
    }

    return goods;
  }, [filteredPizzaIds, isPizzaType, goods]);

  useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Div>
      <Title level="1" weight="semibold" className={cx('ProductList__title')}>
        {title}
      </Title>
      {isPizzaType && <PizzaFilter changeFilterPizza={changeFilterPizza} goods={goods} />}
      <div className={cx('ProductList__layout')}>
        {goodsList.map(good => {
          const { alias } = good;

          if (alias === 'sozday' || alias === 'halves' || isCombobox(good)) {
            // функционал создания своей пиццы или половинки двух пицц пока недоступен
            return null;
          }

          return <GoodCard key={good.id} nextPage={nextPage} view="catalog" {...good} />;
        })}
      </div>
    </Div>
  );
};

ProductList.defaultProps = {
  className: '',
  title: '',
  goods: [],
  categoryAlias: '',
};

ProductList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  goods: PropTypes.array,
  categoryAlias: PropTypes.string,
  nextPage: PropTypes.func.isRequired,
};

export default ProductList;
