import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Epic, ConfigProvider, Root, AdaptivityProvider, AppRoot } from '@vkontakte/vkui';
import bridge from '@vkontakte/vk-bridge';

// Styles
import '@vkontakte/vkui/dist/vkui.css';

// Views
import MainView from '../Views/MainView';
import OrderView from '../Views/OrderView';
import ThankYouView from '../Views/ThankYouView';
import InitAddressView from '../Views/InitAddressView';
import OnlinePaymentView from '../Views/OnlinePaymentView';

// Components
import RootModal from '../Modals';
import Tabbar from '../components/Tabbar';

// Utils
import {
  MAIN_STORY,
  ORDER_STORY,
  MAIN_VIEW,
  THANK_YOU_VIEW,
  INIT_ADDRESS_VIEW,
  ONLINE_PAYMENT_VIEW,
} from '../constants/ViewConstants';
import { VK_STORAGE_GET, VK_GET_USER_INFO } from '../constants/BridgeEvents';
import { TokenStorage, CityStorage } from '../utils/Storage';

// Action
import { setView } from '../state/reducers/ui/actions';
import { requestGoodsAction } from '../state/reducers/catalog/actions';
import { actionRequestCart } from '../state/reducers/cart/actions';
import { setCheckoutUserData } from '../state/reducers/order/actions';
import { fetchUserCity, fetchCities, fetchAllowedCities } from '../state/reducers/city/actions';

// Context
import useResponsive from '../hooks/useResponsive';
import ResponsiveContext from '../utils/ResponsiveContext';

export default function App() {
  const dispatch = useDispatch();
  const { activeView, activeStory } = useSelector(state => state.ui);

  // context
  const { isPhone, isPhoneSmall } = useResponsive();

  useEffect(() => {
    bridge
      .send(VK_STORAGE_GET, { keys: ['city_id', 'unauthorized_token'] })
      .then(({ keys }) => {
        const city_id = keys.find(keyItem => keyItem.key === 'city_id');
        const token = keys.find(keyItem => keyItem.key === 'unauthorized_token');

        if (city_id?.value) {
          CityStorage.value = city_id.value;
          dispatch(fetchUserCity());
        }

        if (token?.value) {
          TokenStorage.value = token.value;
          dispatch(actionRequestCart());
        }
      })
      .catch(console.warn);

    // Получаем имя пользователя
    bridge
      .send(VK_GET_USER_INFO, {})
      .then(({ first_name }) => {
        if (first_name) {
          dispatch(setCheckoutUserData({ username: first_name }));
        }
      })
      .catch(console.warn);
  }, []);

  useEffect(() => {
    if (!CityStorage.value) {
      CityStorage.value = 1; // Москва
      dispatch(setView(INIT_ADDRESS_VIEW));
    }
  }, []);

  useEffect(() => {
    dispatch(requestGoodsAction());
    dispatch(fetchCities());
    dispatch(fetchUserCity());
    dispatch(fetchAllowedCities());
  }, []);

  return (
    <ResponsiveContext.Provider value={{ isPhone, isPhoneSmall }}>
      <ConfigProvider>
        <AdaptivityProvider>
          <AppRoot>
            <Root modal={<RootModal />} activeView={activeView}>
              <Epic id={MAIN_VIEW} activeStory={activeStory} tabbar={<Tabbar />}>
                <MainView id={MAIN_STORY} />
                <OrderView id={ORDER_STORY} />
              </Epic>

              <ThankYouView id={THANK_YOU_VIEW} />
              <InitAddressView id={INIT_ADDRESS_VIEW} />
              <OnlinePaymentView id={ONLINE_PAYMENT_VIEW} />
            </Root>
          </AppRoot>
        </AdaptivityProvider>
      </ConfigProvider>
    </ResponsiveContext.Provider>
  );
}
