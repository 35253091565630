import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import {
  Panel,
  Title,
  Separator,
  FixedLayout,
  PanelHeader,
  PanelHeaderBack,
} from '@vkontakte/vkui';

// Components
import SimpleMap from '../../components/SimpleMap';
import Snackbar from '../../components/Snackbar';
import CityList from '../../components/CityList';
import InputField from '../../components/Input';
import Button from '../../components/Button';

// State
import { setCheckoutCurrentAddress } from '../../state/reducers/order/actions';

// Utils
import { CHOOSE_CITY_MODAL } from '../../constants/ModalConstants';
import { MAIN_VIEW } from '../../constants/ViewConstants';
import { getMapHeight } from '../../utils/helpers';

// Actions
import { setView, setActiveModal } from '../../state/reducers/ui/actions';
import { setUserCoordinates } from '../../state/reducers/city/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function InitAddressPanel(props) {
  const dispatch = useDispatch();

  const { id, setPopout } = props;

  const { address_in_line } = useSelector(state => state.order.checkout.currentAddress);
  const { isLoaded } = useSelector(state => state.city);

  const [mapHeight, setMapHeight] = useState(0);
  const [isMapLoaded, setIsMapLoaded] = useState(false);

  const toMainPage = () => dispatch(setView(MAIN_VIEW));
  const openCitiesModal = () => dispatch(setActiveModal(CHOOSE_CITY_MODAL));

  const handleOnClose = () => {
    dispatch(setUserCoordinates([]));
    dispatch(setCheckoutCurrentAddress({ address_in_line: '' }));
    toMainPage();
  };

  useEffect(() => {
    // 212 is a footer height
    // it is the same on all screen sizes
    const height = getMapHeight(180);

    setMapHeight(height);
  }, []);

  useEffect(() => {
    if (isLoaded && isMapLoaded) {
      openCitiesModal();
    }
  }, [isLoaded, isMapLoaded]);

  return (
    <Panel id={id}>
      <PanelHeader
        transparent
        visor={false}
        separator={false}
        left={<PanelHeaderBack className={cx('InitAddressPanel__back')} onClick={handleOnClose} />}
      />
      <SimpleMap
        pageType="homePage"
        setPopout={setPopout}
        height={`${mapHeight}px`}
        onLoad={() => setIsMapLoaded(true)}
      />
      <FixedLayout vertical="bottom" filled>
        <Separator wide />
        <div className={cx('InitAddressPanel__bottom')}>
          <div className={cx('InitAddressPanel__bottom-head')}>
            <Title weight="semibold" level="2" className={cx('InitAddressPanel__title')}>
              Введите адрес
            </Title>
            <CityList />
          </div>
          <InputField readOnly name="address_in_line" value={address_in_line} Î />
          <Button onClick={toMainPage} stretched size="m">
            Подтвердить
          </Button>
        </div>
        <Separator wide />
      </FixedLayout>
      <Snackbar />
    </Panel>
  );
}

InitAddressPanel.defaultProps = {
  setPopout: () => null,
};

InitAddressPanel.propTypes = {
  setPopout: PropTypes.func,
  id: PropTypes.string.isRequired,
};
