const prefix = '@catalog';

export const FETCH_GOODS_REQUEST = `${prefix}/FETCH_GOODS_REQUEST`;
export const FETCH_GOODS_SUCCESS = `${prefix}/FETCH_GOODS_SUCCESS`;
export const FETCH_GOODS_FAILURE = `${prefix}/FETCH_GOODS_FAILURE`;

export const CHOOSE_PRODUCT = `${prefix}/CHOOSE_PRODUCT`;

export const SET_FILTERED_PIZZA_IDS = `${prefix}/SET_FILTERED_PIZZA_IDS`;
export const SET_PIZZA_FILTERID = `${prefix}/SET_PIZZA_FILTERID`;
