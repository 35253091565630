/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-autofocus */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const InputField = props => {
  const {
    ref,
    type,
    half,
    label,
    value,
    status,
    onBlur,
    onFocus,
    onChange,
    readOnly,
    autoFocus,
    className,
    description,
    placeholder,
    ...rest
  } = props;

  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = e => {
    setIsFocused(true);
    onFocus(e);
  };

  const handleBlur = e => {
    setIsFocused(false);
    onBlur(e);
  };

  const classes = cx('InputField__input', `InputField__input_${status}`, {
    InputField__focused: isFocused,
  });

  return (
    <div className={(cx('InputField', { InputField_half: half }), className)}>
      {label && <label className={cx('InputField__label')}>{label}</label>}
      <input
        {...rest}
        ref={ref}
        type={type}
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        className={classes}
        onBlur={handleBlur}
        onFocus={handleFocus}
        autoFocus={autoFocus}
        placeholder={placeholder}
      />
      {description && (
        <div className={cx('InputField__description', `InputField__description_${status}`)}>
          {description}
        </div>
      )}
    </div>
  );
};

InputField.defaultProps = {
  onFocus: () => null,
  onChange: () => null,
  onBlur: () => null,
  readOnly: false,
  className: '',
  status: 'normal',
  autoFocus: false,
  placeholder: '',
  type: 'text',
  label: '',
  description: '',
  half: false,
  ref: null,
};

InputField.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  half: PropTypes.bool,
  description: PropTypes.string,
  status: PropTypes.oneOf(['normal', 'error']),
  type: PropTypes.oneOf(['phone', 'email', 'text', 'password', 'tel']),
  ref: PropTypes.any,
};

export default InputField;
