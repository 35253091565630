import React, { useContext } from 'react';
import classNames from 'classnames/bind';
import { Spinner } from '@vkontakte/vkui';
import { useSelector, useDispatch } from 'react-redux';

// Icons
import Icon28Place from '@vkontakte/icons/dist/28/place';
import Icon16Place from '@vkontakte/icons/dist/16/place';

// Context
import ResponsiveContext from '../../utils/ResponsiveContext';

// Utils
import { CHOOSE_CITY_MODAL } from '../../constants/ModalConstants';
import { setActiveModal } from '../../state/reducers/ui/actions';

// Actions
import { fetchCitiesIfNeeded, fetchUserCityIfNeeded } from '../../state/reducers/city/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const CityList = () => {
  const dispatch = useDispatch();

  // context
  const { isPhoneSmall } = useContext(ResponsiveContext);

  const cityName = useSelector(state => state.city.userCity.data?.name);
  const { isLoaded, list } = useSelector(state => state.city);
  const hasCities = list.length > 0;

  const handleSityListOpen = () => {
    dispatch(fetchCitiesIfNeeded());
    dispatch(fetchUserCityIfNeeded());

    return !isLoaded || !hasCities ? false : dispatch(setActiveModal(CHOOSE_CITY_MODAL));
  };

  return (
    <div
      role="button"
      tabIndex={0}
      onKeyDown={() => null}
      className={cx('CityList')}
      onClick={handleSityListOpen}
    >
      {isPhoneSmall ? (
        <Icon16Place className={cx('CityList__icon')} />
      ) : (
        <Icon28Place className={cx('CityList__icon')} />
      )}
      <h2 className={cx('CityList__name')}>
        {cityName && isLoaded ? (
          cityName
        ) : (
          <>
            Москва <Spinner size="small" className={cx('CityList__loader')} />
          </>
        )}
      </h2>
    </div>
  );
};

export default CityList;
