export const PANEL_3DS = 'PANEL_3DS';
export const BASKET_PANEL = 'BASKET_PANEL';
export const CATALOG_PANEL = 'CATALOG_PANEL';
export const CHECKOUT_PANEL = 'CHECKOUT_PANEL';
export const THANK_YOU_PANEL = 'THANK_YOU_PANEL';
export const INIT_ADDRESS_PANEL = 'INIT_ADDRESS_PANEL';
export const CHECKOUT_MAP_PANEL = 'CHECKOUT_MAP_PANEL';
export const PRODUCT_CARD_PANEL = 'PRODUCT_CARD_PANEL';
export const ONLINE_PAYMENT_PANEL = 'ONLINE_PAYMENT_VIEW';
export const STOCK_GOODS_PANEL = 'STOCK_GOODS_PANEL';
