import config from '../core/config';

const suggestionsUrl = 'https://suggestions.dadata.ru/suggestions/api/4_1/rs';

export default async function suggestionApiRequest(body, suggestionsUrlParams) {
  try {
    const response = await fetch(suggestionsUrl + suggestionsUrlParams, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Token ${config.dadataToken}`,
      },
      body: JSON.stringify(body),
    });

    const data = await response.json();

    return { data };
  } catch (error) {
    return { error: error.message };
  }
}
