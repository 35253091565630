import * as immutable from 'object-path-immutable';
import * as types from './actionTypes';

const initialState = {
  restaurants: {
    list: [],
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  schedule: {
    list: {},
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  deliveryIntervals: {
    list: {},
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  pickupIntervals: {
    list: {},
    isLoading: false,
    isLoaded: false,
    error: '',
  },
  details: {
    data: {},
    isLoading: false,
    isLoaded: false,
    error: '',
  },
};

export default function restaurantReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_LIST_REQUEST:
      return immutable.assign(state, 'restaurants', {
        list: [],
        isLoading: true,
        isLoaded: false,
        error: '',
      });

    case types.FETCH_LIST_SUCCESS:
      return immutable.assign(state, 'restaurants', {
        list: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      });

    case types.FETCH_LIST_FAILURE:
      return immutable.assign(state, 'restaurants', {
        list: [],
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      });

    case types.FETCH_DETAILS_REQUEST: {
      return immutable.assign(state, 'details', {
        data: {},
        isLoading: true,
        isLoaded: false,
        error: '',
      });
    }

    case types.FETCH_DETAILS_SUCCESS: {
      return immutable.assign(state, 'details', {
        data: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      });
    }

    case types.FETCH_DETAILS_FAILURE: {
      return immutable.assign(state, 'details', {
        data: {},
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      });
    }

    case types.FETCH_DELIVERY_INTERVALS_REQUEST: {
      return immutable.assign(state, 'deliveryIntervals', {
        list: {},
        isLoading: true,
        isLoaded: false,
        error: '',
      });
    }

    case types.FETCH_DELIVERY_INTERVALS_SUCCESS: {
      return immutable.assign(state, 'deliveryIntervals', {
        list: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      });
    }

    case types.FETCH_DELIVERY_INTERVALS_FAILURE: {
      return immutable.assign(state, 'deliveryIntervals', {
        list: {},
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      });
    }

    //

    case types.FETCH_PICKUP_INTERVALS_REQUEST: {
      return immutable.assign(state, 'pickupIntervals', {
        list: {},
        isLoading: true,
        isLoaded: false,
        error: '',
      });
    }

    case types.FETCH_PICKUP_INTERVALS_SUCCESS: {
      return immutable.assign(state, 'pickupIntervals', {
        list: action.payload,
        isLoading: false,
        isLoaded: true,
        error: '',
      });
    }

    case types.FETCH_PICKUP_INTERVALS_FAILURE: {
      return immutable.assign(state, 'pickupIntervals', {
        list: {},
        isLoading: false,
        isLoaded: false,
        error: action.payload,
      });
    }

    //

    case types.SET_RESTAURANT_DETAILS: {
      return immutable.assign(state, 'details', {
        data: action.payload || {},
        isLoading: false,
        isLoaded: true,
        error: '',
      });
    }

    case types.RESET_RESTAURANT_DETAILS:
      return { ...state, details: initialState.details };

    default:
      return state;
  }
}
