import * as types from './actionTypes';
import restaurantApi from './api';

// RESTAURANT LIST

function fetchRestaurantsStart() {
  return {
    type: types.FETCH_LIST_REQUEST,
  };
}

function fetchRestaurantsDone(data) {
  return {
    type: types.FETCH_LIST_SUCCESS,
    payload: data,
  };
}

function fetchRestaurantsFail(error) {
  return {
    type: types.FETCH_LIST_FAILURE,
    payload: error,
  };
}

export const fetchRestaurants = () => {
  return dispatch => {
    dispatch(fetchRestaurantsStart());
    return restaurantApi
      .getRestaurants()
      .then(data => {
        return dispatch(fetchRestaurantsDone(data));
      })
      .catch(({ message }) => dispatch(fetchRestaurantsFail(message)));
  };
};

export const fetchRestaurantsIsNeeded = () => {
  return (dispatch, getState) => {
    const { isLoading, isLoaded } = getState().restaurant;

    return !isLoading && !isLoaded ? dispatch(fetchRestaurants()) : false;
  };
};

// SCHEDULE

function fetchRestaurantScheduleDone(data) {
  return {
    type: types.FETCH_SCHEDULE_SUCCESS,
    payload: data,
  };
}

function fetchRestaurantScheduleFail(error) {
  return {
    type: types.FETCH_SCHEDULE_FAILURE,
    payload: error,
  };
}

export const fetchRestaurantSchedule = params => {
  return dispatch => {
    dispatch(fetchRestaurantScheduleStart());
    return restaurantApi
      .getSchedule(params)
      .then(data => {
        return dispatch(fetchRestaurantScheduleDone(data));
      })
      .catch(({ message }) => dispatch(fetchRestaurantScheduleFail(message)));
  };
};

// INTERVALS

function fetchDeliveryIntervalsStart() {
  return {
    type: types.FETCH_DELIVERY_INTERVALS_REQUEST,
  };
}

function fetchDeliveryIntervalsDone(data) {
  return {
    type: types.FETCH_DELIVERY_INTERVALS_SUCCESS,
    payload: data,
  };
}

function fetchDeliveryIntervalsFail(error) {
  return {
    type: types.FETCH_DELIVERY_INTERVALS_FAILURE,
    payload: error,
  };
}

export const fetchDeliveryIntervals = params => {
  return dispatch => {
    dispatch(fetchDeliveryIntervalsStart());
    return restaurantApi
      .getDeliveryIntervals(params)
      .then(data => {
        return dispatch(fetchDeliveryIntervalsDone(data));
      })
      .catch(({ message }) => dispatch(fetchDeliveryIntervalsFail(message)));
  };
};

function fetchPickupIntervalsStart() {
  return {
    type: types.FETCH_PICKUP_INTERVALS_REQUEST,
  };
}

function fetchPickupIntervalsDone(data) {
  return {
    type: types.FETCH_PICKUP_INTERVALS_SUCCESS,
    payload: data,
  };
}

function fetchPickupIntervalsFail(error) {
  return {
    type: types.FETCH_PICKUP_INTERVALS_FAILURE,
    payload: error,
  };
}

export const fetchPickupIntervals = params => {
  return dispatch => {
    dispatch(fetchPickupIntervalsStart());
    return restaurantApi
      .getPickupIntervals(params)
      .then(data => {
        return dispatch(fetchPickupIntervalsDone(data));
      })
      .catch(({ message }) => dispatch(fetchPickupIntervalsFail(message)));
  };
};


// DETAILS

function fetchRestaurantDetailsStart() {
  return {
    type: types.FETCH_DETAILS_REQUEST,
  };
}

function fetchRestaurantDetailsDone(data, analytAddress = null) {
  return {
    type: types.FETCH_DETAILS_SUCCESS,
    payload: data,
    analytAddress,
  };
}

function fetchRestaurantDetailsFail(error, analytAddress = null) {
  return {
    type: types.FETCH_DETAILS_FAILURE,
    payload: error,
    analytAddress,
  };
}

export const fetchRestaurantDetails = params => {
  return dispatch => {
    dispatch(fetchRestaurantDetailsStart());
    return restaurantApi
      .getDetails(params)
      .then(data => {
        // WARNING
        // const { address } = params;
        // const analytAddress = address || nfetchRestaurantDetailsull;
        // analytAddress
        // dispatch(initGooglePayIsNeeded());
        return dispatch(fetchRestaurantDetailsDone(data));
      })
      .catch(({ message }) => {
        const { address } = params;
        const analytAddress = address || null;

        return dispatch(fetchRestaurantDetailsFail(message, analytAddress));
      });
  };
};

export const setRestaurantDetails = (data = {}) => {
  return {
    type: types.SET_DETAILS_SUCCESS,
    payload: data,
  };
};
