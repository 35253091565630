import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames/bind';
import { List } from '@vkontakte/vkui';

// Components
import CartItem from '../../../components/CartItem';

// Styles
import styles from './OrderCartItemsList.styl';

const cx = classNames.bind(styles);

const OrderCartItemsList = () => {
  const { composition } = useSelector(state => state.cart.cartData);

  return (
    <List className={cx('OrderCartItemsList')}>
      {composition.map(compositionItem => (
        <CartItem key={compositionItem.hash} compositionItem={compositionItem} />
      ))}
    </List>
  );
};

export default OrderCartItemsList;
