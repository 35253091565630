import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { ScreenSpinner, Alert } from '@vkontakte/vkui';
import { useDispatch, useSelector } from 'react-redux';
import { YMaps, Map, GeoObject, ZoomControl, Button } from 'react-yandex-maps';
import bridge from '@vkontakte/vk-bridge';

// Action
import { setCheckoutCurrentAddress } from '../../state/reducers/order/actions';
import { setUserCoordinates } from '../../state/reducers/city/actions';

// Utils
import { lang, yandexApiKey } from '../../core/config';
import { VK_GET_GEODATA } from '../../constants/BridgeEvents';
import { defaultMapPoint } from '../../core/config';

// Icons
import NavigationIcon from '../../assets/map/navigation-green.svg';
import PersonIcon from '../../assets/map/person.svg';

export default function SimpleMap(props) {
  const dispatch = useDispatch();

  const { width, height, setPopout, onLoad } = props;

  const cityCoordinates = useSelector(state => state.city.userCity.data?.coordinates || []);
  const coordinates = useSelector(state => state.city.userCoordinates);

  const ymapsRef = useRef(null);

  const handleSetScreenLoader = () => setPopout(<ScreenSpinner />);
  const handleClosePopout = () => setPopout(null);
  const handleGeolocationAlert = () => {
    setPopout(
      <Alert
        actions={[
          {
            title: 'Закрыть',
            autoclose: true,
          },
        ]}
        onClose={handleClosePopout}
      >
        Необходимо включить геолокацию.
      </Alert>,
    );
  };

  const setUserPosition = coords => {
    const ymaps = ymapsRef.current;

    dispatch(setUserCoordinates(coords));

    ymaps.geocode(coords).then(res => {
      const address = res.geoObjects.get(0).getAddressLine();

      dispatch(setCheckoutCurrentAddress({ address_in_line: address }));
    });
  };

  const handleGetGeoPosition = () => {
    navigator.geolocation.getCurrentPosition(() => null);

    handleSetScreenLoader();

    bridge
      .send(VK_GET_GEODATA, {})
      .then(({ available, lat, long }) => {
        handleClosePopout();
        if (available) {
          setUserPosition([lat, long]);
        } else {
          handleGeolocationAlert();
        }
      })
      .catch(() => {
        Promise.resolve(handleClosePopout()).then(() => handleGeolocationAlert());
      });
  };

  const handleApiAvailable = ymaps => {
    ymapsRef.current = ymaps;

    const [lon, lat] = cityCoordinates;

    handleClosePopout();
    onLoad();
    return coordinates.length ? setUserPosition(coordinates) : setUserPosition([lat, lon]);
  };

  const onClickHandler = e => {
    const coords = e.get('coords');

    setUserPosition(coords);
  };

  useEffect(() => {
    handleSetScreenLoader();

    return () => {
      handleClosePopout();
    };
  }, []);

  useEffect(() => {
    if (cityCoordinates.length && !coordinates.length) {
      const [lon, lat] = cityCoordinates;
      dispatch(setUserCoordinates([lat, lon]));
    }
  }, [cityCoordinates]);

  return (
    <>
      <YMaps query={{ lang, apikey: yandexApiKey }}>
        <Map
          width={width}
          height={height}
          modules={['geocode']}
          onClick={onClickHandler}
          onLoad={handleApiAvailable}
          state={{ center: coordinates, zoom: 12 }}
          defaultState={{ center: defaultMapPoint, zoom: 12 }}
        >
          <GeoObject
            geometry={{ type: 'Point', coordinates }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: PersonIcon,
              iconImageSize: [32, 32],
              iconImageOffset: [-16, -32],
              cursor: 'default',
            }}
          />
          <ZoomControl
            options={{
              size: 'small',
              position: { top: `${Math.floor(parseInt(height) / 2)}px`, left: '2vw' },
            }}
          />
          <Button
            options={{
              position: {
                right: '2vw',
                bottom: '30px',
              },
              selectOnClick: false,
            }}
            data={{
              image: NavigationIcon,
            }}
            onClick={handleGetGeoPosition}
          />
        </Map>
      </YMaps>
    </>
  );
}

SimpleMap.defaultProps = {
  width: '100%',
  height: '100%',
  onLoad: () => null,
};

SimpleMap.propTypes = {
  setPopout: PropTypes.func.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  onLoad: PropTypes.func,
};
