import * as types from './actionTypes';

import { MAIN_STORY, MAIN_VIEW } from '../../../constants/ViewConstants';

const initialState = {
  snackbar: {
    title: '',
    duration: '',
    type: '',
  },
  homePage: {
    popout: null,
  },
  orderPage: {
    popout: null,
  },
  activeModal: null,
  activeStory: MAIN_STORY,
  activeView: MAIN_VIEW,
};

export default function uiReducer(state = initialState, action) {
  switch (action.type) {
    case types.SET_PAGE_POPUOT: {
      const { page, popout } = action.payload;
      return {
        ...state,
        [page]: {
          ...state[page],
          popout,
        },
      };
    }

    case types.SET_ACTIVE_MODAL:
      return {
        ...state,
        activeModal: action.payload,
      };

    case types.SET_SNACKBAR:
      return {
        ...state,
        snackbar: {
          ...state.snackbar,
          ...action.payload,
        },
      };

    case types.SET_ACTIVE_STORY:
      return {
        ...state,
        activeStory: action.payload,
      };

    case types.SET_ACTIVE_VIEW:
      return {
        ...state,
        activeView: action.payload,
      };

    default:
      return state;
  }
}
