/* eslint-disable react/sort-comp */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import {
  Text,
  Panel,
  Group,
  Title,
  Separator,
  PanelHeader,
  FixedLayout,
  PanelHeaderBack,
} from '@vkontakte/vkui';

// Components
import Amount from '../../components/Amount';
import Button from '../../components/Button';
import Snackbar from '../../components/Snackbar';
import PizzaSelector from '../../components/PizzaSelector';
import ProductCardImage from '../../components/ProducCardImage';

// Icons
import GiftIcon from '../../icons/common/gift.svg';

// Helpers
import { isSet, isPizza, isGift } from '../../utils/catalog';
import {
  getSizes,
  getVariation,
  getDoughTypes,
  getPersonCount,
  getVariationsByDough,
  stuffedCrustsAvailable,
  stuffedCrustIsAvailable,
  getVariationsByDoughPizza,
  stuffedCrustsAvailableDefaultSort,
} from '../../state/reducers/catalog/utils';
import { CATALOG_PANEL, CHECKOUT_PANEL } from '../../constants/PanelConstants';

// Actions
import { removeItem, actionAddReward, actionAddToCart } from '../../state/reducers/cart/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const pageType = 'homePage';

class ProductCardPanel extends Component {
  constructor(props) {
    super(props);

    this.setInitialDough();
    this.setInitialSize();
    this.setInitialStuffedCrust();
  }

  state = {
    dough: undefined,
    size: undefined,
    disabledStuffedCrust: false, // если только одна вариация и доступен сырный борт, то кнопка сырного борта становится disabled
    stuffedCrust: 'none',
    selectedStuffedCrust: 'none', // Запоминание последнего выбора пользователя
  };

  timeout = null;

  get getProductIndex() {
    return this.props.stockRole === 'conditions' ? this.props.condIdx : this.props.rewardIdx;
  }

  setInitialDough = () => {
    const { choosenProduct } = this.props;

    const initialVariation = choosenProduct?.variations[0];

    if (isPizza(initialVariation) || isSet(this.props.choosenProduct)) {
      if (initialVariation && initialVariation.kind) {
        this.state.dough = initialVariation.kind.id;
      }
    }
  };

  setInitialSize = () => {
    const { choosenProduct } = this.props;

    const initialVariation = choosenProduct.variations[0];

    if (initialVariation?.size) {
      this.state.size = initialVariation.size.value;
    }
  };

  changeDough = dough => {
    this.setState({ dough });
  };

  changeSize = size => {
    this.setState({ size });
  };

  toggleStuffedCrust = () => {
    const { selectedStuffedCrust } = this.state;
    const stuffedCrustsAvailable = this.stuffedCrustsAvailable();

    if (stuffedCrustsAvailable.includes(selectedStuffedCrust)) {
      this.setState({ stuffedCrust: selectedStuffedCrust });
    } else if (stuffedCrustsAvailable.includes('none')) {
      this.setState({ stuffedCrust: 'none' });
    } else {
      this.setState({ stuffedCrust: stuffedCrustsAvailable[0] || 'none' });
    }
  };

  selectStuffedCrust = stuffedCrust => {
    this.setState({
      stuffedCrust,
      selectedStuffedCrust: stuffedCrust,
    });
  };

  activeStuffedCrust = stuffedCrust => {
    this.state.stuffedCrust = stuffedCrust;
    this.state.selectedStuffedCrust = stuffedCrust;
  };

  setInitialStuffedCrust = () => {
    const stuffedCrustsAvailable = this.stuffedCrustsAvailableDefaultSort();
    if (stuffedCrustsAvailable.length) {
      this.activeStuffedCrust(stuffedCrustsAvailable[0]);
    }
    const { stuffedCrust } = this.state;

    if (!stuffedCrustsAvailable.includes(stuffedCrust)) {
      if (stuffedCrustsAvailable.includes('none') || !stuffedCrustsAvailable.length) {
        this.state.stuffedCrust = 'none';
      } else {
        this.state.stuffedCrust = stuffedCrustsAvailable[0] || 'none';
      }
    }
  };

  getVariationsByDough = () => {
    return isPizza(this.props.choosenProduct.variations[0]) || isSet(this.props.choosenProduct)
      ? getVariationsByDoughPizza(this.props.choosenProduct.variations, this.state.dough)
      : getVariationsByDough(this.props.choosenProduct.variations, this.state.dough);
  };

  getSizes = () => {
    const variations = this.getVariationsByDough();
    return getSizes(variations);
  };

  getVariation = () => {
    const variations = this.getVariationsByDough();
    return getVariation(variations, this.state.size, this.state.stuffedCrust);
  };

  stuffedCrustIsAvailable = () => {
    const variations = this.getVariationsByDough();
    return stuffedCrustIsAvailable(variations, this.state.size);
  };

  stuffedCrustsAvailable = () => {
    const variations = this.getVariationsByDough();
    return stuffedCrustsAvailable(variations, this.state.size);
  };

  stuffedCrustsAvailableDefaultSort = () => {
    const variations = this.getVariationsByDough();
    return stuffedCrustsAvailableDefaultSort(variations, this.state.size);
  };

  getPersonCount = () => {
    return getPersonCount(this.getVariation());
  };

  addToCart = () => {
    const variation = this.getVariation();
    const { nextPage, stockRole, view } = this.props;

    if (stockRole === 'reward' && view === 'stock') {
      this.props.actionAddReward({ gifts: [{ id: variation.id, ingredients: [] }] }).then(() => {
        nextPage(CHECKOUT_PANEL, true);
      });
    } else {
      this.props.actionAddToCart([{ type: 'good', item: variation }]).then(() => {
        if (stockRole === 'condition' && view === 'stock') {
          nextPage(CHECKOUT_PANEL, true);
        } else {
          window.history.back();
        }
      });
    }
  };

  render() {
    const { size, dough, stuffedCrust, disabledStuffedCrust } = this.state;

    const { footerRef, id: panelId, choosenProduct, addLoader, view, nextPage } = this.props;

    const doughTypes = getDoughTypes(choosenProduct.variations);
    const { isLoading } = addLoader;
    const sizes = this.getSizes();
    const variation = this.getVariation();
    const stuffedCrustIsAvailable = this.stuffedCrustIsAvailable();
    const stuffedCrustsAvailable = this.stuffedCrustsAvailable();
    const image = variation.image_cart || '';

    const { types, name, variations, data } = choosenProduct;

    const title = (
      <Title level="2" weight="semibold">
        {name}
      </Title>
    );

    return (
      <Panel id={panelId} className={cx('ProductCardPanel')}>
        <PanelHeader
          left={
            <PanelHeaderBack
              className={cx('ProductCardPanel__back')}
              onClick={() =>
                view === 'catalog' ? nextPage(CATALOG_PANEL) : nextPage(CHECKOUT_PANEL, true)
              }
            />
          }
        />
        <div className={cx('ProductCardPanel-top')}>
          <ProductCardImage
            image={image}
            types={types}
            name={name}
            classNameWrapper={cx('ProductCardPanel__image')}
            classNameBadge={cx('ProductCardPanel__badge')}
            classNameMeta={cx('ProductCardPanel__meta')}
          />
        </div>
        <Separator wide />
        <Group className={cx('ProductCardPanel__body')} header={title} separator="hide">
          <div className={cx('ProductCardPanel__body_content')}>
            <Text className={cx('ProductCardPanel__description')} weight="regular">
              {data}
            </Text>
            {isGift(this.props) ? (
              <div className={cx('ProductCardPanel-gift')}>
                <GiftIcon width={32} height={32} />
              </div>
            ) : (
              <Amount
                className={cx('ProductCardPanel__price')}
                value={variation.old_price || variation.price}
                newValue={variation.price}
              />
            )}
          </div>
        </Group>
        <FixedLayout vertical="bottom" filled>
          <Group className={cx('ProductCardPanel__footer')} _ref={footerRef} separator="hide">
            {(isPizza(variations[0]) || isSet(this.props.choosenProduct)) && sizes.length > 0 && (
              <PizzaSelector
                sizes={sizes}
                currentSize={size}
                currentDough={dough}
                doughTypes={doughTypes}
                pageForPopout={pageType}
                changeSize={this.changeSize}
                changeDough={this.changeDough}
                activeStuffedCrust={stuffedCrust}
                disabledStuffedCrust={disabledStuffedCrust}
                toggleStuffedCrust={this.toggleStuffedCrust}
                selectStuffedCrust={this.selectStuffedCrust}
                stuffedCrustsAvailable={stuffedCrustsAvailable}
                stuffedCrustIsAvailable={stuffedCrustIsAvailable}
                className={cx('ProductCardPanel__footer__selector')}
              />
            )}
            <Button onClick={this.addToCart} stretched loading={isLoading}>
              Добавить в корзину
            </Button>
          </Group>
        </FixedLayout>
        <Snackbar />
      </Panel>
    );
  }
}

const connector = connect(
  state => ({
    choosenProduct: state.catalog.choosenProduct,
    composition: state.cart.cartData.composition,
    stockRole: state.cart.stockData.type,
    addLoader: state.cart.addLoader,
  }),
  dispatch => bindActionCreators({ removeItem, actionAddToCart, actionAddReward }, dispatch),
);

ProductCardPanel.defaultProps = {
  className: '',
  types: [],
  variations: [],
  type: 'good',
  good_type: '',
};

ProductCardPanel.propTypes = {
  className: PropTypes.string,
  rewardIdx: PropTypes.number,
  condIdx: PropTypes.number,
  id: PropTypes.string,
  category: PropTypes.string,
  types: PropTypes.array,
  show_ingredients_button: PropTypes.number,
  variations: PropTypes.array,
  special: PropTypes.bool,
  alias: PropTypes.string,
  showFreeSaucesPanel: PropTypes.func,
  clearStock: PropTypes.func,
  stockCode: PropTypes.string,
  showModalConfirm: PropTypes.func,
  good_type: PropTypes.string,
  history: PropTypes.object,
  removeItem: PropTypes.func,
  updateItem: PropTypes.func,
  stockRole: PropTypes.oneOf(['reward', 'condition']),
  view: PropTypes.oneOf(['catalog', 'stock']).isRequired,
  nextPage: PropTypes.func.isRequired,
  addLoader: PropTypes.object,
};

export default connector(ProductCardPanel);
