import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { View } from '@vkontakte/vkui';

// Panels
import Panel3Ds from '../../Panels/Panel3Ds';
import OnlinePaymentPanel from '../../Panels/OnlinePaymentPanel';

// Utils
import { ONLINE_PAYMENT_PANEL, PANEL_3DS } from '../../constants/PanelConstants';

const OnlinePaymentView = () => {
  const { payment, order_id } = useSelector(state => state.order.checkout);

  const [popout, setPopout] = useState(null);
  const [activePanel, setActivePanel] = useState(ONLINE_PAYMENT_PANEL);
  const [history, setHistory] = useState([ONLINE_PAYMENT_PANEL]);

  useEffect(() => {
    if (payment.pa_req) {
      setActivePanel(PANEL_3DS);
    }
  }, [payment]);

  return (
    <View activePanel={activePanel} history={history} popout={popout}>
      <OnlinePaymentPanel id={ONLINE_PAYMENT_PANEL} />
      <Panel3Ds id={PANEL_3DS} payment={payment} order_id={order_id} setPopout={setPopout} />
    </View>
  );
};

export default OnlinePaymentView;
