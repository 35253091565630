// 1 - city id Москвы
export const onlyMoscowCitiesSelector = cities => cities.filter(city => city.parent_id === 1);

export const otherCitiesSelector = cities => cities.filter(city => city.parent_id !== 1);

export const getUserCityId = state => state.city.userCity.data?.id;

// DADATA API

export const getSuggestKladrArray = state => {
  const kladrs = state.city.userCity.data?.suburb_kladr;

  if (!kladrs) {
    return [];
  }

  return kladrs
    .split(';')
    .filter(_kladr => _kladr.trim().length > 0)
    .map(_kladr => ({
      kladr_id: _kladr.trim(),
    }));
};

export const getSuggestCityArray = state => {
  const {
    userCity: { data },
    list,
  } = state.city;

  const parent_id = data?.parent_id;
  const name = data?.name;
  const id = data?.id;

  let cityArray = [];

  if (parent_id && parent_id > 0) {
    cityArray = list
      .filter(cityListItem => cityListItem.name !== name && cityListItem.parent_id === id)
      .map(filteredCity => ({
        city: filteredCity.name,
      }));
  }

  cityArray.push({ city: name });

  return cityArray;
};
