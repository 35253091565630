import React from 'react';
import { ActionSheet, ActionSheetItem } from '@vkontakte/vkui';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import _orderBy from 'lodash/orderBy';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const SizesPopout = props => {
  const { variations, onClick, onClose } = props;

  const IosCloseItem = (
    <ActionSheetItem autoclose mode="cancel">
      <span className={cx('SizesPopout__item', 'SizesPopout__item-close')}>Закрыть</span>
    </ActionSheetItem>
  );

  return (
    <ActionSheet onClose={onClose} iosCloseItem={IosCloseItem}>
      {_orderBy(variations, ['size.value'], 'desc').map(variation => {
        if (!variation?.size) {
          return null;
        }

        const { id, unit, value } = variation.size;

        return (
          <ActionSheetItem key={id} autoclose onClick={() => onClick(value)}>
            <span className={cx('SizesPopout__item')}>{`${value}${unit}`}</span>
          </ActionSheetItem>
        );
      })}
    </ActionSheet>
  );
};

SizesPopout.propTypes = {
  variations: PropTypes.array.isRequired,
  onClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default SizesPopout;
