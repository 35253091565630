import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Input } from '@vkontakte/vkui';

// Utils
import { maskedPhoneNumber, plainPhoneNumber } from '../../utils/telephone';

// Config
import config from '../../core/config';

export default function PhoneField(props) {
  const { name, value, required, onChange, onFocus, onBlur } = props;

  const [isFormatted, setIsFormatted] = useState(true);

  const { firstNumbers } = config.phone;

  const handleOnChange = e => {
    // вдруг мы вставили телефон формата +7 (943) 233-33-33, тогда убираем все лишние символы
    const inputValue = plainPhoneNumber(e.target.value);

    // если мы пытаемся стереть всю строку или до плюса (через backspace + alt или backspace + ctrl)
    if (inputValue.length <= firstNumbers.length) {
      e.target.value = '';
      onChange(e);
      return;
    }

    // если вводим не цифру или если мы превысили макс. кол-во символов
    if (!/^[+][\d]+$/.test(inputValue) || inputValue.length > config.phone.digitsCount) {
      return;
    }

    // если мы пытается стереть плюс или 7 или написать вместо них/между ними цифры
    if (inputValue.indexOf(firstNumbers) !== 0) {
      return;
    }

    onChange(e);
  };

  const handleOnFocus = e => {
    setIsFormatted(false);

    return onFocus(e);
  };

  const handleOnBlur = e => {
    setIsFormatted(true);

    return onBlur(e);
  };

  const formattedValue = isFormatted ? maskedPhoneNumber(value) : plainPhoneNumber(value);

  return (
    <Input
      type="tel"
      name={name}
      required={required}
      onBlur={handleOnBlur}
      onFocus={handleOnFocus}
      onChange={handleOnChange}
      value={formattedValue || firstNumbers}
    />
  );
}

PhoneField.defaultProps = {
  name: '',
  required: false,
  onChange: () => {},
  onFocus: () => {},
  onBlur: () => {},
};

PhoneField.propTypes = {
  name: PropTypes.string,
  required: PropTypes.bool,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
