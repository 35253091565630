import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';

const Collapse = props => {
  const { isOpen, children, timing, className, minHeight } = props;

  const [height, setHeight] = useState(isOpen ? 'auto' : '0px');
  const [overflow, setOverflow] = useState(isOpen ? 'visible' : 'hidden');
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);
  const contentRef = useRef(null);

  useEffect(() => {
    setHeight(isOpen ? `${contentRef.current.scrollHeight}px` : '0px');

    if (isOpen) {
      setTimeout(() => {
        setOverflow('visible');
        setIsAnimationFinished(true);
      }, timing);
    } else {
      setOverflow('hidden');
      setIsAnimationFinished(false);
    }
  }, [isOpen, children]);

  return (
    <div
      className={className}
      ref={contentRef}
      style={{
        overflow,
        minHeight,
        opacity: isOpen ? 1 : 0,
        height: isAnimationFinished && isOpen ? 'auto' : height,
        transition: `height ${timing}ms linear, opacity ${timing}ms ease-out`,
      }}
    >
      {children}
    </div>
  );
};

Collapse.defaultProps = {
  timing: 200,
  className: '',
  minHeight: 0,
};

Collapse.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  timing: PropTypes.number,
  className: PropTypes.string,
  minHeight: PropTypes.number,
};

export default Collapse;
