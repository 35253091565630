/**
 * Format numbers (prices etc)
 * @param {number|string} value: Unformatted number
 * @param {FormatNumbersOptions} options
 * @return {string} Formatted number string
 */
export const formatNumber = (value, { digits = 0 } = { digits: 0 }) => {
  const processedValue = typeof value === 'string' ? value.replace(',', '') : value;
  const rounded = parseFloat(processedValue).toFixed(digits);
  const parts = String(rounded).split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');

  if (digits === 0) {
    return parts[0] !== 'NaN' ? parts[0] : '-';
  }

  return parts[1] && Number(parts[1]) === 0 ? parts[0] : parts.join('.');
};

export const cleaner = str => str.trim().toLowerCase();

// return map size deepends on free place on screen
export const getMapHeight = val => {
  const viewPort = window.innerHeight;
  const height = viewPort - val;

  return height;
};

export const isObj = value => typeof value === 'object' && value !== null;

export const isEmpty = val => val?.length === 0 || Object.keys(val).length === 0;
