import * as types from './actionTypes';

export const auth3Ds = data => ({
  type: types.AUTH_3DS,
  status: data.transaction_status,
  error: data.error,
});

export const setCryptogram = cryptogram => ({
  type: types.SET_CRYPTOGRAM,
  payload: cryptogram,
});

export const setHolderName = holderName => ({
  type: types.SET_HOLDERNAME,
  payload: holderName,
});
