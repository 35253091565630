import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

// Utils
import { isHot, isHalal, isVegeterian } from '../../utils/catalog';

// Icons
import PlantIcon from '../../icons/metaOptions/plant.svg';
import PeperIcon from '../../icons/metaOptions/peper.svg';
import HalalIcon from '../../icons/metaOptions/halal.svg';
// import PersonIcon from '../../icons/metaOptions/person.svg';

export default class MetaOptions extends PureComponent {
  render() {
    const { types, className } = this.props;

    const isMetaOptionsExist = isHot(types) || isHalal(types) || isVegeterian(types);

    return (
      <>
        {isMetaOptionsExist && (
          <div className={('MetaOptions', className)}>
            {isHot(types) && (
              <span className={('Meta-option', 'Meta-option_hot')}>
                <PeperIcon width={16} height={16} />
              </span>
            )}
            {isHalal(types) && (
              <span className={('Meta-option', 'Meta-option_halal')}>
                <HalalIcon width={16} height={16} />
              </span>
            )}
            {isVegeterian(types) && (
              <span className={('Meta-option', 'Meta-option_vegeterian')}>
                <PlantIcon width={16} height={16} />
              </span>
            )}
          </div>
        )}
      </>
    );
  }
}

MetaOptions.defaultProps = {
  className: '',
};

MetaOptions.propTypes = {
  types: PropTypes.array.isRequired,
  className: PropTypes.string,
};
