import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { FormLayoutGroup, Radio } from '@vkontakte/vkui';

// Components
import OrderTimeFields from './OrderTimeFields';

// ACtions
import { setCheckoutDeliveryTimeType } from '../../../state/reducers/order/actions';

export default function DeliveryTimeForm() {
  const dispatch = useDispatch();
  const { deliveryType, deliveryTimeType } = useSelector(state => state.order.checkout);
  const restaurantDetails = useSelector(state => state.restaurant.details);

  // Время доставки: как можно скорее / выбрать время
  const wantedTimes = [
    {
      name: 'Как можно скорее',
      value: 'instantly',
      disabled: !(deliveryType === 'pickup'
        ? restaurantDetails.data.available_takeaway_asap_orders
        : restaurantDetails.data.available_delivery_asap_orders),
    },
    {
      name: 'Выбрать время',
      value: 'intime',
      disabled: !restaurantDetails.data.available_timed_orders,
    },
  ];

  return (
    <FormLayoutGroup>
      {wantedTimes.map(({ name, disabled, value }) => (
        <Radio
          key={name}
          disabled={disabled}
          checked={deliveryTimeType === value}
          onChange={() => dispatch(setCheckoutDeliveryTimeType(value))}
        >
          {name}
        </Radio>
      ))}
      {deliveryTimeType === 'intime' && <OrderTimeFields />}
    </FormLayoutGroup>
  );
}
