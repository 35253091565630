import React from 'react';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';
import { Group, Header, List, Cell, usePlatform } from '@vkontakte/vkui';
import { useSelector } from 'react-redux';

// Icons
import Icon24DoNotDisturb from '@vkontakte/icons/dist/24/do_not_disturb';

// Utils
import { getUserCityId } from '../../../state/reducers/city/selectors';

// Styles
import styles from './CityList.styl';

const cx = classNames.bind(styles);

const CityList = props => {
  const { header, cities, onChange } = props;

  const userCityId = useSelector(getUserCityId);

  const platform = usePlatform();
  const isIOS = platform === 'ios';
  const Icon = <Icon24DoNotDisturb className={cx('CityList__disturb')} />;
  return (
    <Group header={<Header>{header}</Header>}>
      <List>
        {cities.map(city => {
          return (
            <Cell
              key={city.name}
              onChange={() => onChange(city)}
              disabled={!city.allowed}
              selectable={city.allowed}
              checked={city.id === userCityId}
              before={!city.allowed && isIOS && Icon}
              after={!city.allowed && !isIOS && Icon}
            >
              {city.name}
            </Cell>
          );
        })}
      </List>
    </Group>
  );
};

CityList.defaultProps = {
  header: '',
  cities: [],
};

CityList.propTypes = {
  header: PropTypes.string,
  cities: PropTypes.array,
  onChange: PropTypes.func.isRequired,
};

export default CityList;
