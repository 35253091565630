/* eslint-disable max-classes-per-file */
import Cookie from 'js-cookie';
import bridge from '@vkontakte/vk-bridge';

// Utils
import { VK_STORAGE_SET } from '../constants/BridgeEvents';

class Storage {
  constructor(key, options = {}) {
    this._value = '';
    this.key = key;
    this.options = options;
  }

  get value() {
    return this._value ? this._value : Cookie.get(this.key);
  }

  set value(value) {
    this._value = value;
    Cookie.set(this.key, value, this.options);
    bridge.send(VK_STORAGE_SET, { key: this.key, value: String(value) });
  }

  remove = () => {
    this._value = '';
    Cookie.remove(this.key);
    bridge.send(VK_STORAGE_SET, { key: this.key, value: '' });
  };
}

//

class Token extends Storage {
  get value() {
    return super.value;
  }

  set value(token) {
    super.value = token;
  }

  withToken = (data = {}) => {
    return this.value
      ? {
          unauthorized_token: this.value,
          ...data,
        }
      : data;
  };
}

export const TokenStorage = new Token('unauthorized_token', { expires: 1 });

//
class CityId extends Storage {
  get value() {
    return super.value;
  }

  set value(token) {
    super.value = token;
  }
}

export const CityStorage = new CityId('city_id');
