import React from 'react';
import PropTypes from 'prop-types';
import className from 'classnames/bind';
import { Panel, PanelHeader } from '@vkontakte/vkui';

// Components
import Snackbar from '../../components/Snackbar';
import CityList from '../../components/CityList';
import Catalog from './Catalog';

// Icons
import Logo from '../../icons/Logo';

// Styles
import styles from './styles.styl';

const cx = className.bind(styles);

const CatalogPanel = props => {
  const { id, nextPage } = props;

  return (
    <Panel id={id}>
      <PanelHeader left={<Logo className={cx('CatalogPanel__logo')} />}>
        <CityList />
      </PanelHeader>
      <Catalog nextPage={nextPage} />
      <Snackbar />
    </Panel>
  );
};

CatalogPanel.propTypes = {
  nextPage: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
};

export default CatalogPanel;
