import * as types from './actionTypes';

const initialState = {
  catalogLoader: {
    categories: [],
    list: [],
    isLoading: false,
    isLoaded: false,
    hasError: false,
  },

  choosenProduct: null,
  filteredPizzaIds: [],
  filterId: null,
};
export default function catalogReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_GOODS_REQUEST:
      return {
        ...state,
        catalogLoader: {
          ...state.catalogLoader,
          isLoading: true,
          isLoaded: false,
          hasError: false,
        },
      };
    case types.FETCH_GOODS_FAILURE:
      return {
        ...state,
        catalogLoader: {
          ...state.catalogLoader,
          isLoading: false,
          isLoaded: false,
          hasError: true,
        },
      };
    case types.FETCH_GOODS_SUCCESS: {
      const list = [];

      const categories = action.payload.map(category => {
        const { goods, ...otherProps } = category;

        goods.forEach(good => list.push(good));

        return { ...otherProps };
      });

      return {
        ...state,
        catalogLoader: {
          isLoading: false,
          isLoaded: true,
          hasError: false,
          categories,
          list,
        },
      };
    }

    case types.CHOOSE_PRODUCT:
      return {
        ...state,
        choosenProduct: action.payload,
      };

    case types.SET_FILTERED_PIZZA_IDS:
      return {
        ...state,
        filteredPizzaIds: action.payload,
      };

    case types.SET_PIZZA_FILTERID:
      return {
        ...state,
        filterId: action.payload,
      };

    default:
      return state;
  }
}
