import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.styl';

const cx = classNames.bind(styles);

class Size extends PureComponent {
  handleClick = () => {
    this.props.onChange(this.props.size);
  };

  render() {
    const { selected, size } = this.props;

    return (
      <div
        role="button"
        tabIndex={0}
        onKeyDown={() => null}
        className={cx('SizeSelector__size', {
          SizeSelector__size_selected: selected,
        })}
        onClick={this.handleClick}
      >
        {selected ? `${size.value} ${size.unit}` : size.value}
      </div>
    );
  }
}

Size.propTypes = {
  size: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Size;
