import React, { useState } from 'react';
import { PopoutWrapper, Subhead } from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function ConfirmAlert(props) {
  const { onAgreeTitle, onCloseAction, onAgreeAction, onCancelTitle, title } = props;

  const [closing, setClosing] = useState(false);

  const handleClick = () => {
    setClosing(true);
    onCloseAction();
  };

  return (
    <PopoutWrapper hasMask alignX="center" alignY="center" closing={closing} onClick={handleClick}>
      <div className={cx('ConfirmAlert')} onClick={e => e.stopPropagation()}>
        <Subhead weight="semibold" className={cx('ConfirmAlert__text')}>
          {title}
        </Subhead>
        <div className={cx('ConfirmAlert__buttons')}>
          <button type="button" onClick={onCloseAction} className={cx('ConfirmAlert__button')}>
            {onCancelTitle}
          </button>
          <button type="button" onClick={onAgreeAction} className={cx('ConfirmAlert__button')}>
            {onAgreeTitle}
          </button>
        </div>
      </div>
    </PopoutWrapper>
  );
}

ConfirmAlert.defaultProps = {
  onCancelTitle: 'Отмена',
  onAgreeTitle: 'Разрешить',
};

ConfirmAlert.propTypes = {
  title: PropTypes.string.isRequired,
  onAgreeAction: PropTypes.func.isRequired,
  onCloseAction: PropTypes.func.isRequired,
  onCancelTitle: PropTypes.string,
  onAgreeTitle: PropTypes.string,
};
