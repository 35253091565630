import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useDispatch } from 'react-redux';
import { ModalPageHeader, usePlatform, IOS, ANDROID, PanelHeaderButton } from '@vkontakte/vkui';

// Icons
import Icon24Cancel from '@vkontakte/icons/dist/24/cancel';

// Actions
import { setActiveModal } from '../../../state/reducers/ui/actions';

// Styles
import styles from './ModalHeader.styl';

const cx = classNames.bind(styles);

const ModalHeader = props => {
  const { children } = props;

  const dispatch = useDispatch();
  const platform = usePlatform();
  const hadleSetModal = () => dispatch(setActiveModal(null));

  return (
    <ModalPageHeader
      left={
        <>
          {platform === ANDROID && (
            <PanelHeaderButton onClick={hadleSetModal} className={cx('ModalHeader__button')}>
              <Icon24Cancel className={cx('ModalHeader__button__icon')} />
            </PanelHeaderButton>
          )}
        </>
      }
      right={
        <>
          {platform === IOS && (
            <PanelHeaderButton onClick={hadleSetModal} className={cx('ModalHeader__button')}>
              Готово
            </PanelHeaderButton>
          )}
        </>
      }
    >
      {children}
    </ModalPageHeader>
  );
};

ModalHeader.defaultProps = {
  children: null,
};

ModalHeader.propTypes = {
  children: PropTypes.element,
};

export default ModalHeader;
