import _pick from 'lodash/pick';
import * as types from './actionTypes';

const initialState = {
  searchQuery: '',
  list: [],
  allowedList: [],
  isLoading: false,
  isLoaded: false,
  hasError: false,
  userCity: {
    data: {},
    isLoading: false,
    isLoaded: false,
    hasError: false,
    errorMsg: '',
  },
  userCoordinates: [],
};

export default function cityReducer(state = initialState, action) {
  switch (action.type) {
    case types.FETCH_CITIES_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case types.FETCH_CITIES_SUCCESS: {
      // а другие поля нам и не нужны в стейте
      const list = action.payload.map(city =>
        _pick(city, ['id', '_supertag', 'name', 'parent_id']),
      );
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        list,
      };
    }

    case types.FETCH_CITIES_FAILURE:
      return {
        ...state,
        list: [],
        hasError: true,
        isLoading: false,
        isLoaded: false,
      };

    //

    case types.FETCH_ALLOWED_CITIES_REQUEST:
      return {
        ...state,
      };

    case types.FETCH_ALLOWED_CITIES_SUCCESS:
      return {
        ...state,
        allowedList: action.payload,
      };

    case types.FETCH_ALLOWED_CITIES_FAILURE:
      return {
        ...state,
        allowedList: [],
        hasError: true,
      };

    //

    case types.FETCH_USER_CITY_REQUEST:
      return {
        ...state,
        userCity: {
          ...state.userCity,
          isLoading: true,
          hasError: false,
          errorMsg: '',
        },
      };

    case types.FETCH_USER_CITY_SUCCESS:
      return {
        ...state,
        userCity: {
          ...state.userCity,
          isLoading: false,
          isLoaded: true,
          data: action.payload,
        },
        userCoordinates: [],
      };

    case types.FETCH_USER_CITY_FAILURE:
      return {
        ...state,
        userCity: {
          ...state.userCity,
          isLoading: false,
          isLoaded: false,
          data: {},
          hasError: true,
          errorMsg: action.errorMsg,
        },
      };

    case types.SET_USER_COORDINATES:
      return {
        ...state,
        userCoordinates: action.payload,
      };

    default:
      return state;
  }
}
