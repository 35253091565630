import { useState, useEffect } from 'react';
import bridge from '@vkontakte/vk-bridge';

let ref = '';

const useHistory = panel => {
  const [history] = useState([panel]);
  const [activePanel, setActivePanel] = useState(panel);

  const prev = () => {
    if (history.length === 0) {
      bridge.send('VKWebAppClose', { status: 'success' });
    } else if (history.length > 1) {
      if (ref !== history[history.length - 1]) {
        setActivePanel(history[history.length - 1]);
        ref = history[history.length - 1];
      } else {
        history.pop();
        setActivePanel(history[history.length - 1]);
      }
    }
  };

  const next = (name, skip = false) => {
    if (!skip) {
      history.push(name);
    }
    window.history.pushState({ panel: name }, name);
    ref = name;
    setActivePanel(name);
  };

  useEffect(() => {
    window.addEventListener('popstate', prev);
  }, []);

  return { history, activePanel, next, prev };
};

export default useHistory;
