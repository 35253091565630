import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Panel, PanelHeader, FixedLayout, InfoRow, PanelHeaderBack } from '@vkontakte/vkui';

// Components
import SimpleMap from '../../components/SimpleMap';
import Snackbar from '../../components/Snackbar';

// Utils
import { getMapHeight } from '../../utils/helpers';

// Actions
import { setPagePopout } from '../../state/reducers/ui/actions';

// Styles
import styles from './styles.styl';
import { CHECKOUT_PANEL } from '../../constants/PanelConstants';

const cx = classNames.bind(styles);

const CheckoutMapPanel = props => {
  const { id: panelId, nextPage } = props;
  const dispatch = useDispatch();

  const address = useSelector(state => state.order.checkout.currentAddress.address_in_line);

  const [mapHeight, setMapHeight] = useState(0);
  const wrapperRef = useRef(0);

  const handleOnClose = () => window.history.back();
  const setPopout = popout => dispatch(setPagePopout('homePage', popout));

  useEffect(() => {
    // 48 - высота тап бара
    const wrapperHeight = wrapperRef.current.offsetHeight;
    const height = getMapHeight(48 + wrapperHeight);
    setMapHeight(height);
  }, [address]);

  return (
    <Panel id={panelId}>
      <PanelHeader
        left={
          <PanelHeaderBack
            className={cx('CheckoutMapPanel__back')}
            onClick={() => nextPage(CHECKOUT_PANEL)}
          />
        }
        transparent
        visor={false}
        separator={false}
      />
      <SimpleMap
        pageType="orderPage"
        onClose={handleOnClose}
        height={`${mapHeight}px`}
        setPopout={setPopout}
      />
      <FixedLayout filled vertical="bottom">
        <div className={cx('CheckoutMapPanel__address')} ref={wrapperRef}>
          <InfoRow header="Адрес">{address}</InfoRow>
        </div>
      </FixedLayout>
      <Snackbar />
    </Panel>
  );
};

CheckoutMapPanel.propTypes = {
  id: PropTypes.string.isRequired,
};

export default CheckoutMapPanel;
