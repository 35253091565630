import React from 'react';
import { useDispatch } from 'react-redux';
import { FormLayoutGroup, Radio } from '@vkontakte/vkui';

// Actions
import { setCheckoutPayType } from '../../../state/reducers/order/actions';

const PaymentForm = () => {
  const dispatch = useDispatch();

  const onClickHandler = event => {
    const { value } = event.target;
    dispatch(setCheckoutPayType(value));
  };

  return (
    <FormLayoutGroup>
      <Radio name="paymentType" value="cash" defaultChecked onClick={onClickHandler}>
        Наличными
      </Radio>
      <Radio name="paymentType" value="card_to_courier" onClick={onClickHandler}>
        Картой курьеру
      </Radio>
      <Radio name="paymentType" value="online" onClick={onClickHandler}>
        Онлайн оплата
      </Radio>
    </FormLayoutGroup>
  );
};

export default PaymentForm;
