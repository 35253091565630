import React, { useContext, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Subhead, Text } from '@vkontakte/vkui';
import { Transition } from 'react-transition-group';
import { LazyLoadImage } from 'react-lazy-load-image-component';

// Icons
import Icon16Add from '@vkontakte/icons/dist/16/add';

// Components
import SizeSelector from '../Selectors/SizeSelector';
import ConfirmAlert from '../Alerts/ConfirmAlert';
import SizesPopout from '../Alerts/SizesPopout';
import Amount from '../Amount';
import Button from '../Button';

// Utils
import { CHECKOUT_PANEL, PRODUCT_CARD_PANEL } from '../../constants/PanelConstants';
import { isSet, isGift, isPizza } from '../../utils/catalog';
import { getVariation, getSizes } from '../../state/reducers/catalog/utils';
import ResponsiveContext from '../../utils/ResponsiveContext';

// State
import { chooseProduct } from '../../state/reducers/catalog/actions';
import { setPagePopout, setSnackbar } from '../../state/reducers/ui/actions';
import { actionAddReward, actionAddToCart } from '../../state/reducers/cart/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);
const pageType = 'homePage';

const GoodCard = props => {
  const dispatch = useDispatch();

  const { name, variations, description, nextPage, view } = props;

  const [size, setSize] = useState(0);
  const [variation, setVariation] = useState({});
  const [isImgLoaded, setIsImgLoaded] = useState(false);
  const { isPhone } = useContext(ResponsiveContext);
  const { type } = useSelector(state => state.cart.stockData);

  const sizes = getSizes(variations);
  const isMoreThanOneSize = sizes.length > 1;

  const isGiftType = isGift(props);
  const isSetType = isSet(props);
  const isPizzaType = isPizza(variations[0]);

  const setImageLoaded = () => setIsImgLoaded(true);

  const showSnackbarHandler = () => {
    dispatch(setSnackbar({ title: `Товар ${name} успешно добавлен в корзину` }));
  };

  const addToCartHandler = () => {
    if (type === 'reward' && view === 'stock') {
      dispatch(actionAddReward({ gifts: [{ id: variation.id, ingredients: [] }] }));
    } else {
      dispatch(actionAddToCart([{ type: 'good', item: variation }]));
    }

    dispatch(setPagePopout(pageType, null));

    if (type && view === 'stock') {
      Promise.resolve(nextPage(CHECKOUT_PANEL, true)).then(() => {
        setTimeout(showSnackbarHandler, 200);
      });
    } else {
      showSnackbarHandler();
    }
  };

  const addConfirmAlert = () => (
    <ConfirmAlert
      onAgreeTitle="Добавить"
      onAgreeAction={addToCartHandler}
      title={`Хотите добавить "${name}" в корзину?`}
      onCloseAction={() => dispatch(setPagePopout(pageType, null))}
    />
  );

  const onSizeChangeHandler = e => {
    e.stopPropagation();

    const sizesAlert = (
      <SizesPopout
        onClick={setSize}
        variations={variations}
        onClose={() => dispatch(setPagePopout(pageType, null))}
      />
    );

    return isMoreThanOneSize ? dispatch(setPagePopout(pageType, sizesAlert)) : false;
  };

  const onClickHandler = e => {
    e.stopPropagation();

    if (!isGiftType && (isPizzaType || isSetType)) {
      dispatch(chooseProduct(props));
      nextPage(PRODUCT_CARD_PANEL, view === 'stock');
    } else {
      dispatch(setPagePopout(pageType, addConfirmAlert()));
    }
  };

  const setDescription = () => {
    return description.length <= 50 ? description : `${description.slice(0, 51)}...`;
  };

  useEffect(() => {
    if (isMoreThanOneSize && !isPizzaType && !isSetType) {
      setSize(sizes[0].value);
    }
  }, []);

  useEffect(() => {
    setVariation(getVariation(variations, size));
  }, [size, variations]);

  return (
    <div className={cx('GoodCard')} onClick={onClickHandler}>
      <Transition in={isImgLoaded} timeout={80}>
        {state => (
          <div className={cx('GoodCard__imageContainer', `GoodCard__imageContainer_${state}`)}>
            <LazyLoadImage
              src={variation.image_cart}
              alt=""
              className={cx('GoodCard__image')}
              onLoad={setImageLoaded}
            />
          </div>
        )}
      </Transition>
      <Subhead className={cx('GoodCard__title')} weight="bold">
        {name}
      </Subhead>
      <Text className={cx('GoodCard__description')}>{setDescription()}</Text>
      {sizes.length >= 1 && !isPizzaType && !isSetType && (
        <div onClick={onSizeChangeHandler} className={cx('GoodCard__sizes')}>
          <SizeSelector
            className={cx('GoodCard__selector')}
            onChange={() => null}
            currentSize={size}
            sizes={sizes}
          />
        </div>
      )}
      <div className={cx('GoodCard__bottom')}>
        {!isGiftType && (
          <Amount
            size={isPhone ? 'm' : 'l'}
            className={cx('GoodCard__price')}
            prefix={(isPizzaType || isSetType) && 'от'}
            value={variation.old_price || variation.price}
          />
        )}
        <Button onClick={() => null} size={isPhone ? 's' : 'l'} className={cx('GoodCard__button')}>
          {isPhone ? <Icon16Add /> : 'В корзину'}
        </Button>
      </div>
    </div>
  );
};

GoodCard.defaultProps = {
  description: '',
};

GoodCard.propTypes = {
  description: PropTypes.string,
  name: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
  variations: PropTypes.array.isRequired,
  view: PropTypes.oneOf(['catalog', 'stock']).isRequired,
};

export default GoodCard;
