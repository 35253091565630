import { useMediaQuery } from 'react-responsive';
import media from '../styles/mediaQueries.config.json';

export default function useResponsive() {
  const isPhoneSmall = useMediaQuery({ query: `(max-width: ${media.phoneSmall}px)` });
  const isPhoneMedium = useMediaQuery({ query: `(max-width: ${media.phoneMedium}px)` });
  const isPhone = useMediaQuery({ query: `(max-width: ${media.phone}px)` });

  return { isPhoneSmall, isPhoneMedium, isPhone };
}
