import { get } from '../../../utils/Request';

export default {
  getCities: data => get({ url: 'city/list', data }),
  getAllowedCities: data =>
    get({
      url: `settings/allowed-cities`,
      data: {
        lang: 'ru',
        ...data,
      },
    }),
  getUserCity: (data, headers = {}) => get({ url: 'city/user-city', data, headers }),
};
