module.exports = {
  apiUrl:
    process.env.NODE_ENV === 'production'
      ? 'https://api.papajohns.ru/'
      : 'https://staging-api.papajohns.ru/',
  platform: 'VK',
  legal: 'ru',
  lang: 'ru',
  phone: {
    show: true,
    digitsCount: 12,
    region: 'ru-RU',
    firstNumbers: '+7',
    name: 'Россия',
    iso2: 'ru',
    dialCode: '7',
    priority: 0,
    format: '+. (...) ...-..-..',
  },
  // Yandex maps API key
  yandexApiKey: 'e667f831-0acd-42eb-a5e6-232cfcc37acc',
  // Suggestions API key
  dadataToken: '0118f2c409466e208dd43c61b3250c6d14ccec5e',
  // Центр Москвы
  defaultMapPoint: [55.753564, 37.621085],
};
