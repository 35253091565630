import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import styles from './styles.styl';

const cx = classNames.bind(styles);

class Button extends PureComponent {
  get symbol() {
    return this.props.type === 'plus' ? '&#43;' : '&#8722;';
  }

  render() {
    const { disabled, type, children, ...otherProps } = this.props;
    const symbol = { __html: this.symbol };
    return (
      <button
        type="button"
        className={cx('QuantityControl__button', {
          [`QuantityControl__button_${type}`]: type,
          QuantityControl__button_disabled: disabled,
          QuantityControl__button_removeButton: children,
        })}
        disabled={disabled}
        {...otherProps}
      >
        {children ? (
          <span className={cx('QuantityControl__button-symbol')}>{children}</span>
        ) : (
          <span className={cx('QuantityControl__button-symbol')} dangerouslySetInnerHTML={symbol} />
        )}
      </button>
    );
  }
}

Button.defaultProps = {
  type: 'minus',
  disabled: false,
};

Button.propTypes = {
  type: PropTypes.oneOf(['plus', 'minus']),
  disabled: PropTypes.bool,
  children: PropTypes.any,
};

export default Button;
