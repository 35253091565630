import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { connect } from 'react-redux';
import _get from 'lodash/get';

import styles from './styles.styl';

const cx = classNames.bind(styles);

class Currency extends Component {
  getUnit = () => {
    const { name, unit } = this.props.currency;
    if (name === 'ruble') {
      return '₽';
    }
    return unit;
  };

  render() {
    const {
      currency: { name },
    } = this.props;
    return (
      <span className={cx('Currency', this.props.className, { [`Currency_${name}`]: name })}>
        {this.getUnit()}
      </span>
    );
  }
}

const connector = connect(
  state => ({
    currency: _get(state, 'city.userCity.data.currency', {}),
  }),
  null,
);

Currency.defaultProps = {
  className: '',
};

Currency.propTypes = {
  className: PropTypes.string,
  currency: PropTypes.object.isRequired,
};

export default connector(Currency);
