import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Actions
import { setSnackbar } from '../state/reducers/ui/actions';

const getErrorMessage = errorMessage => {
  return errorMessage === 'Failed to fetch'
    ? 'Нет соединения с сервером. Проверьте подключение к интернету'
    : errorMessage;
};

export default function useHandleError(getError, clearError) {
  const dispatch = useDispatch();

  const { error } = useSelector(getError);

  const handleSetError = title => {
    dispatch(setSnackbar({ title, type: 'error' }));
    dispatch(clearError());
  };

  useEffect(() => {
    if (error) {
      if (typeof error === 'string') {
        const errorMessage = getErrorMessage(error);
        handleSetError(errorMessage);
      } else {
        const errorFieldsKeys = Object.keys(error);
        errorFieldsKeys.forEach(errorKey => {
          const subErrorKeys = Object.keys(error[errorKey]);
          const errorMessage = getErrorMessage(error[errorKey][subErrorKeys[0]]);

          handleSetError(errorMessage);
        });
      }
    }
  }, [error]);
}
