import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

import RemoveIcon from '../../icons/common/close.svg';

import Button from './Button';
import styles from './styles.styl';

const cx = classNames.bind(styles);

class QuantityControl extends PureComponent {
  state = {
    quantity: this.props.quantity,
  };

  UNSAFE_componentWillReceiveProps({ quantity }) {
    if (quantity !== this.state.quantity) {
      this.setState({ quantity });
    }
  }

  changeQuantity = quantity => {
    const { onChange } = this.props;
    this.setState({ quantity });
    if (onChange) {
      onChange(quantity);
    }
  };

  increment = () => {
    const quantity = this.state.quantity + 1;
    this.changeQuantity(Math.min(quantity, this.props.max));
  };

  decrement = () => {
    const quantity = this.state.quantity - 1;
    this.changeQuantity(Math.max(quantity, this.props.min));
  };

  get canDecrement() {
    return this.state.quantity >= this.props.min + 1;
  }

  get canIncrement() {
    return this.state.quantity <= this.props.max - 1;
  }

  render() {
    return (
      <div className={cx('QuantityControl', this.props.className)}>
        {this.props.quantity === this.props.min ? (
          <Button onClick={this.props.onRemove}>
            <RemoveIcon width={15} height={15} />
          </Button>
        ) : (
          <Button type="minus" onClick={this.decrement} />
        )}
        <div className={cx('QuantityControl__display')}>{this.props.quantity}</div>
        <Button type="plus" onClick={this.increment} disabled={!this.canIncrement} />
      </div>
    );
  }
}

QuantityControl.defaultProps = {
  className: '',
  quantity: 1,
  min: 1,
  max: 100,
  onRemove: () => null,
};

QuantityControl.propTypes = {
  className: PropTypes.string,
  quantity: PropTypes.number,
  onChange: PropTypes.func,
  min: PropTypes.number,
  max: PropTypes.number,
  onRemove: PropTypes.func,
};

export default QuantityControl;
