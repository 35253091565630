import React, { useState, useRef, useMemo } from 'react';
import { YMaps, Map, GeoObject } from 'react-yandex-maps';
import { PanelSpinner } from '@vkontakte/vkui';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Config
import config from '../../../../core/config';

// Icons
import pin from '../../../../assets/map/pin.svg';
import location from '../../../../assets/map/person.svg';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function OrderMap(props) {
  const { className, zoom, orderData } = props;
  const { delivery_type, delivery_point, restaurant_point } = orderData;

  const map = useRef(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const getMapBoundArray = () => {
    if (delivery_type === 'delivery' && delivery_point && restaurant_point) {
      const restaurantBound = [parseFloat(restaurant_point[1]), parseFloat(restaurant_point[0])];
      const deliveryBound = [parseFloat(delivery_point[1]), parseFloat(delivery_point[0])];

      const leftBottom = [
        Math.min(restaurantBound[0], deliveryBound[0]),
        Math.min(restaurantBound[1], deliveryBound[1]),
      ];

      const rightTop = [
        Math.max(restaurantBound[0], deliveryBound[0]),
        Math.max(restaurantBound[1], deliveryBound[1]),
      ];

      return [leftBottom, rightTop];
    }

    return null;
  };

  const mapBoundryArray = useMemo(() => getMapBoundArray(), []);
  const mapCenter = useMemo(() => [restaurant_point[1], restaurant_point[0]], []);

  const handleApiAvaliable = () => setIsLoaded(true);

  const getMapRef = ref => {
    if (ref) {
      map.current = ref;
      const bounds = map.current.getBounds();

      if (bounds && delivery_type === 'delivery') {
        map.current.setBounds(bounds);
      }

      map.current.behaviors.disable('drag');
      map.current.container.fitToViewport();
    }
  };

  return (
    <div className={cx('Map', className)}>
      {!isLoaded && <PanelSpinner />}
      <YMaps query={{ lang: config.lang, apikey: config.yandexApiKey }}>
        <Map
          width="100%"
          height="100%"
          instanceRef={getMapRef}
          onLoad={handleApiAvaliable}
          state={{
            zoom,
            center: mapCenter,
            bounds: mapBoundryArray,
            autoFitToViewport: 'allways',
          }}
        >
          <GeoObject
            geometry={{
              type: 'Point',
              coordinates: [restaurant_point[1], restaurant_point[0]],
            }}
            options={{
              iconLayout: 'default#image',
              iconImageHref: pin,
              iconImageSize: [32, 32],
              iconImageOffset: [-16, -32],
              cursor: 'default',
            }}
          />
          {delivery_type === 'delivery' && (
            <>
              {delivery_point && (
                <GeoObject
                  geometry={{
                    type: 'Point',
                    coordinates: [delivery_point[1], delivery_point[0]],
                  }}
                  options={{
                    iconLayout: 'default#image',
                    iconImageHref: location,
                    iconImageSize: [26, 26],
                    iconImageOffset: [-13, -26],
                    cursor: 'default',
                  }}
                />
              )}
            </>
          )}
        </Map>
      </YMaps>
    </div>
  );
}

OrderMap.defaultProps = {
  className: '',
};

OrderMap.propTypes = {
  className: PropTypes.string,
  zoom: PropTypes.number.isRequired,
  orderData: PropTypes.object.isRequired,
};
