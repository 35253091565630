import * as types from './actionTypes';

export const setPagePopout = (page, popout) => ({
  type: types.SET_PAGE_POPUOT,
  payload: { page, popout },
});

export const setActiveModal = modal => ({
  type: types.SET_ACTIVE_MODAL,
  payload: modal,
});

export const setSnackbar = ({ title, duration = 1500, type = 'success' }) => ({
  type: types.SET_SNACKBAR,
  payload: { title, duration, type },
});

export const setView = view => ({
  type: types.SET_ACTIVE_VIEW,
  payload: view,
});

export const setStory = story => ({
  type: types.SET_ACTIVE_STORY,
  payload: story,
});
