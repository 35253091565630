/* eslint-disable prefer-promise-reject-errors */
/* eslint-disable lines-between-class-members */
import config from '../core/config';
import { TokenStorage, CityStorage } from './Storage';

class Request {
  static baseUrl = config.apiUrl;
  static defaultHeaders = { Accept: 'application/json' };

  constructor({ method, headers, data, url }) {
    this.method = method;
    this.setHeaders(headers);
    this.setData(data);
    this.setBody();
    this.setQueryString();
    this.setUrl(url);
  }

  setHeaders = (headers = { 'Content-Type': 'application/json' }) => {
    this.headers = { ...Request.defaultHeaders, ...headers };
  };

  setBody = () => {
    this.body = ['POST', 'PUT'].includes(this.method) ? JSON.stringify(this.data) : null;
  };

  setQueryString = () => {
    if (['GET', 'DELETE'].includes(this.method)) {
      this.qs = `?${this.createQueryString(this.data)}`;
    } else {
      this.qs = '';
    }
  };

  setData = data => {
    this.data = {
      lang: 'ru',
      city_id: CityStorage.value,
      unauthorized_token: TokenStorage.value,
      platform: config.platform,
      ...data,
    };
  };

  createQueryString = (data = {}) => {
    const encode = encodeURIComponent;

    return Object.keys(data)
      .map(key => `${encode(key)}=${encode(data[key])}`)
      .join('&');
  };

  setUrl = (url = '') => {
    this.url = Request.baseUrl + url + this.qs;
  };

  request = () => {
    return fetch(this.url, {
      method: this.method,
      headers: this.headers,
      body: this.body,
    }).then(this.handleResonse);
  };

  handleResonse = response => {
    return response.json().then(json => {
      if (response.ok) {
        return json;
      }

      // const { message } = json;

      if (response.status === 401) {
        TokenStorage.remove();
      }
      // if (message) {
      //   if (typeof message === "object") {
      //     Object.keys(message).map((key) =>
      //       store.dispatch(setError(message[key]))
      //     );
      //   } else if (
      //     typeof message === "string" &&
      //     message.toLowerCase().indexOf("промо-код") === -1 &&
      //     message.toLowerCase().indexOf("promo") === -1
      //   ) {
      //     store.dispatch(setError(message));
      //   }
      // }

      return Promise.reject({
        ...json,
        status: response.status,
        statusText: response.statusText,
      });
    });
  };
}

export const get = params => new Request({ method: 'GET', ...params }).request();
export const del = params => new Request({ method: 'GET', ...params }).request();
export const post = params => new Request({ method: 'POST', ...params }).request();
export const put = params => new Request({ method: 'DELETE', ...params }).request();
export const patch = params => new Request({ method: 'PATCH', ...params }).request();
