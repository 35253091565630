const prefix = '@city';

export const FETCH_CITIES_REQUEST = `${prefix}/FETCH_CITIES_REQUEST`;
export const FETCH_CITIES_SUCCESS = `${prefix}/FETCH_CITIES_SUCCESS`;
export const FETCH_CITIES_FAILURE = `${prefix}/FETCH_CITIES_FAILURE`;

export const FETCH_ALLOWED_CITIES_REQUEST = `${prefix}/FETCH_ALLOWED_CITIES_REQUEST`;
export const FETCH_ALLOWED_CITIES_SUCCESS = `${prefix}/FETCH_ALLOWED_CITIES_SUCCESS`;
export const FETCH_ALLOWED_CITIES_FAILURE = `${prefix}/FETCH_ALLOWED_CITIES_FAILURE`;

export const FETCH_USER_CITY_REQUEST = `${prefix}/FETCH_USER_CITY_REQUEST`;
export const FETCH_USER_CITY_SUCCESS = `${prefix}/FETCH_USER_CITY_SUCCESS`;
export const FETCH_USER_CITY_FAILURE = `${prefix}/FETCH_USER_CITY_FAILURE`;

export const SET_USER_COORDINATES = `${prefix}/SET_USER_COORDINATES`;
