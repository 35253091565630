import _pick from 'lodash/pick';
import { plainPhoneNumber } from '../../../utils/telephone';
import * as types from './actionTypes';
import orderApi from './api';

// CHECKOUT

export const setCheckoutUserData = payload => ({ type: types.SET_CHECKOUT_USER_DATA, payload });

export const setCheckoutDeliveryType = payload => ({
  type: types.SET_CHECKOUT_DELIVERY_TYPE,
  payload,
});

export const setCheckoutCurrentAddress = payload => ({
  type: types.SET_CHECKOUT_CURRENT_ADDRESS,
  payload,
});

export const setCheckoutPayType = payload => ({ type: types.SET_CHECKOUT_PAY_TYPE, payload });

export const setCheckoutDescription = payload => ({
  type: types.SET_CHECKOUT_DESCRIPTION,
  payload,
});

export const setCheckoutDeliveryTimeType = payload => ({
  type: types.SET_CHECKOUT_DELIVERY_TIME_TYPE,
  payload,
});

export const setCheckoutDeliveryTime = payload => ({
  type: types.SET_CHECKOUT_DELIVERY_TIME,
  payload,
});

export const resetCheckout = payload => ({ type: types.RESET_CHECKOUT, payload });

export const resetError = () => ({
  type: types.CHECKOUT_CLEAR_ERROR,
});

export const resetOrderPayment = () => ({
  type: types.RESET_ORDER_PAYMENT,
});

// PAY

/**
 * Устанавливаем isPayed после онлайн оплаты
 * @param {boolean} payload
 */
export const setCheckoutIsPayed = payload => ({
  type: types.SET_CHECKOUT_IS_PAYED,
  payload: { isPayed: payload.transaction_status, error: payload.error || '' },
});

const payOrderStart = () => ({ type: types.PAY_REQUEST });

const payOrderDone = payload => ({
  type: types.PAY_SUCCESS,
  payload: {
    ...payload,
    order_data: payload.order_data,
    order_id: payload.order_id,
    payment: payload.payment,
    error: payload.payment?.error || '',
    isPayed: !payload.payment || (!payload.payment.acs_url && payload.payment.transaction_status),
  },
});

const payOrderFail = payload => ({ type: types.PAY_FAILURE, payload });

export const payOrder = (payload, callback) => {
  return (dispatch, getState) => {
    const {
      card: { holderName, cryptogram },
      restaurant: { details },
      order: {
        checkout: {
          change,
          payType,
          user_data,
          description,
          deliveryTime,
          deliveryType,
          currentAddress,
          deliveryTimeType,
          isContactFreeDelivery,
        },
      },
    } = getState();

    dispatch(payOrderStart());

    user_data.phone = plainPhoneNumber(user_data.phone);

    const restaurant_id = details.data.id;
    const address_id = currentAddress.id;
    const address_json = _pick(currentAddress, [
      'address_in_line',
      'intercom',
      'flat',
      'floor',
      'porch',
    ]);

    const wanted_time = `${deliveryTime.day} ${deliveryTime.hoursMinutes}`;
    const cloudpayments_data = {
      is_token: 0,
      name: holderName,
      card_cryptogram_packet: cryptogram,
    };

    const params = {
      description: `${isContactFreeDelivery ? 'БЕСКОНТАКТНАЯ ДОСТАВКА; ' : ''}${description}`,
      address_json: !address_id && deliveryType === 'delivery' ? address_json : undefined,
      address_id: address_id && deliveryType === 'delivery' ? address_id : undefined,
      cloudpayments_data: payType === 'online' ? cloudpayments_data : undefined,
      restaurant_id: deliveryType === 'pickup' ? restaurant_id : undefined,
      wanted_time: deliveryTimeType === 'intime' ? wanted_time : undefined,
      change: change && payType === 'cash' ? change : undefined,
      pay_type: payType,
      user_data,
      ...payload,
    };

    return orderApi
      .pay(params)
      .then(data => {
        dispatch(payOrderDone(data));

        if (callback) {
          callback(data);
        }

        return true;
      })
      .catch(({ message }) => {
        dispatch(payOrderFail(message));

        if (callback) {
          callback(message);
        }
      });
  };
};
