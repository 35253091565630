/* eslint-disable react/button-has-type */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { Spinner } from '@vkontakte/vkui';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const Button = props => {
  const {
    type,
    size,
    bold,
    brand,
    before,
    margin,
    onClick,
    loading,
    children,
    disabled,
    stretched,
    className,
  } = props;

  const classes = cx('Button', `Button_${size}`, className, {
    Button_stretched_margin: stretched && margin,
    Button_stretched: stretched,
    Button_disabled: disabled,
    Button_margin: margin,
    Button_brand: brand,
    Button_bold: bold,
  });

  const loader = <Spinner size="small" style={{ color: brand ? '#fff' : '#009471' }} />;

  return (
    <button type={type} onClick={onClick} className={classes} disabled={disabled}>
      {before}
      <span className={cx('Button__content')}>{loading ? loader : children}</span>
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  size: 'l',
  brand: true,
  className: '',
  before: null,
  stretched: false,
  disabled: false,
  loading: false,
  bold: false,
  margin: false,
};

Button.propTypes = {
  size: PropTypes.oneOf(['xl', 'l', 'm', 's']),
  type: PropTypes.oneOf(['button', 'submit']),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  onClick: PropTypes.func.isRequired,
  before: PropTypes.element,
  stretched: PropTypes.bool,
  brand: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  bold: PropTypes.bool,
  margin: PropTypes.bool,
};

export default Button;
