import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Panel, PanelHeader, Title, Spinner, Separator, PanelHeaderBack } from '@vkontakte/vkui';
import _isEmpty from 'lodash/isEmpty';

// Components
import Snackbar from '../../components/Snackbar';
import Map from './partials/Map';
import Cart from './partials/Cart';
import OrderStatusSteps from './partials/OrderStatusSteps';

// Utils
import { MAIN_VIEW, MAIN_STORY } from '../../constants/ViewConstants';
import { TokenStorage } from '../../utils/Storage';
import { get } from '../../utils/Request';

// Actions
import { setUserCoordinates } from '../../state/reducers/city/actions';
import { setView, setStory } from '../../state/reducers/ui/actions';
import { resetCartState } from '../../state/reducers/cart/actions';
import { resetCheckout } from '../../state/reducers/order/actions';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

export default function ThankYouPanel(props) {
  const dispatch = useDispatch();

  const { id } = props;

  const { order_id } = useSelector(state => state.order.checkout);
  const mapZoom = useSelector(state => state.city.userCity?.zoom) || 12;

  const [orderData, setOrderData] = useState({});
  const intervalRef = useRef();

  const toMainPanel = () => {
    dispatch(setStory(MAIN_STORY));
    dispatch(setView(MAIN_VIEW));
  };

  useEffect(() => {
    return () => {
      TokenStorage.remove();
      dispatch(resetCheckout());
      dispatch(resetCartState());
      dispatch(setUserCoordinates([]));
    };
  }, []);

  const handleSetOrderData = newOrderData => setOrderData({ ...newOrderData });

  const data = {
    action: 'status',
    order_id,
  };

  const getOrderStatus = () =>
    get({
      url: `order/status`,
      data: TokenStorage.withToken(data),
    })
      .then(res => handleSetOrderData(res))
      .catch(console.warn);

  useEffect(() => {
    getOrderStatus();

    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  useEffect(() => {
    clearInterval(intervalRef.current);

    const time =
      Number(orderData.order_status) === 0 || Number(orderData.order_status) === 4 ? 10000 : 60000;

    intervalRef.current = setInterval(() => {
      getOrderStatus();
    }, time);
  }, [orderData.order_status]);

  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack className={cx('ThankYouPanel__back')} onClick={toMainPanel} />}
      />
      <div className={cx('ThankYouPanel__top')}>
        <Title level="1" weight="semibold" className={cx('ThankYouPanel__title')}>
          Спасибо за заказ!
        </Title>
        {!orderData.client_number ? (
          <div className={cx('ThankYouPanel__sending')}>
            <span>Отправляем ваш заказ</span>
            <Spinner size="small" className={cx('ThankYouPanel__spinner')} />
          </div>
        ) : (
          <div className={cx('OrderStatus__orderId')}>
            <span>Номер заказа</span>
            :&nbsp;
            <span className={cx('ThankYouPanel__orderNumber')}>{orderData.client_number}</span>
          </div>
        )}
      </div>
      {_isEmpty(orderData) ? (
        <Spinner />
      ) : (
        <>
          <OrderStatusSteps
            className={cx('OrderStatus__steps')}
            orderId={order_id}
            orderData={orderData}
            handleSetOrderData={handleSetOrderData}
          />
          <Separator wide />
          <Map className={cx('OrderStatus__map')} zoom={mapZoom} orderData={orderData} />
          <Cart orderData={orderData} />
        </>
      )}
      <Snackbar />
    </Panel>
  );
}

ThankYouPanel.propTypes = {
  id: PropTypes.string.isRequired,
};
