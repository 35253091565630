// State
import cityApi from './api';
import * as types from './actionTypes';

export const fetchCities = data => dispatch => {
  dispatch({ type: types.FETCH_CITIES_REQUEST });

  return cityApi
    .getCities(data)
    .then(payload => dispatch({ type: types.FETCH_CITIES_SUCCESS, payload }))
    .catch(() => dispatch({ type: types.FETCH_CITIES_FAILURE, hasError: true }));
};

export const fetchCitiesIfNeeded = data => (dispatch, getState) => {
  const {
    city: { isLoading, isLoaded },
  } = getState();

  if (!isLoading && !isLoaded) {
    return dispatch(fetchCities(data));
  }

  return Promise.resolve();
};

//

export const fetchAllowedCities = data => dispatch => {
  dispatch({ type: types.FETCH_ALLOWED_CITIES_REQUEST });

  return cityApi
    .getAllowedCities(data)
    .then(payload => dispatch({ type: types.FETCH_ALLOWED_CITIES_SUCCESS, payload }))
    .catch(() => dispatch({ type: types.FETCH_ALLOWED_CITIES_FAILURE, hasError: true }));
};

//

export const fetchUserCity = data => dispatch => {
  dispatch({ type: types.FETCH_USER_CITY_REQUEST });

  return cityApi
    .getUserCity(data)
    .then(payload => dispatch({ type: types.FETCH_USER_CITY_SUCCESS, payload: payload[0] }))
    .catch(err => dispatch({ type: types.FETCH_USER_CITY_FAILURE, hasError: true, errorMsg: err }));
};

export const fetchUserCityIfNeeded = data => (dispatch, getState) => {
  if (getState().city.userCity.isLoaded) {
    return Promise.resolve();
  }

  return dispatch(fetchUserCity(data));
};

//
export const setUserCoordinates = coordinates => ({
  type: types.SET_USER_COORDINATES,
  payload: coordinates,
});
