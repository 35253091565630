import React, { memo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';

// Components
import Type from './Type';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const DoughSelector = props => {
  const { currentDough, onChange, className, types } = props;

  // REDO вынести о типах теста
  // const doughTypes = getDoughTypes(variations);

  const handleChange = type => {
    if (type !== currentDough) {
      onChange(type);
    }
    return false;
  };

  return (
    <div className={cx('DoughSelector', className)}>
      {types.map(type => (
        <Type
          type={type.id}
          key={type.label}
          label={type.label}
          onChange={handleChange}
          isActive={type.id === Number(currentDough)}
        />
      ))}
    </div>
  );
};

DoughSelector.defaultProps = {
  className: '',
};

DoughSelector.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  currentDough: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  types: PropTypes.array.isRequired,
};

export default memo(DoughSelector);
