import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames/bind';
import { useSelector, useDispatch } from 'react-redux';
import { Panel, PanelHeader, PanelHeaderBack, ScreenSpinner } from '@vkontakte/vkui';

// Components
import Snackbar from '../../components/Snackbar';

// Actions
import { setView, setSnackbar } from '../../state/reducers/ui/actions';
import { setCheckoutIsPayed, resetOrderPayment } from '../../state/reducers/order/actions';

// Utils
import config from '../../core/config';
import { THANK_YOU_VIEW, MAIN_VIEW } from '../../constants/ViewConstants';
import { getUserCityId } from '../../state/reducers/city/selectors';

// Styles
import styles from './styles.styl';

const cx = classNames.bind(styles);

const Panel3Ds = props => {
  const { id, payment, order_id, setPopout } = props;

  const dispatch = useDispatch();
  const cityId = useSelector(getUserCityId);
  const ref = useRef();

  const handleBack = () => dispatch(setView(MAIN_VIEW));

  useEffect(() => {
    setPopout(<ScreenSpinner />);

    const formPostRequest = (action, fields) => {
      const form = document.createElement('form');
      form.setAttribute('target', '3dsiframe');
      form.setAttribute('action', action);
      form.setAttribute('method', 'post');

      Object.keys(fields).map(field => {
        const input = document.createElement('input');
        input.setAttribute('name', field);
        input.setAttribute('value', fields[field]);
        input.setAttribute('hidden', true);
        return form.appendChild(input);
      });

      document.body.appendChild(form);
      form.submit();
      document.body.removeChild(form);
    };

    const auth3DsCallback = e => {
      const { transaction_status, error } = e.data;

      if (error) {
        dispatch(setView(MAIN_VIEW));
        setTimeout(() => {
          dispatch(setSnackbar({ title: error, type: 'error' }));
        }, 800);
      }

      if (transaction_status) {
        dispatch(setCheckoutIsPayed(e.data));
        dispatch(setView(THANK_YOU_VIEW));
      }
    };

    window.addEventListener('message', auth3DsCallback);

    formPostRequest(payment.acs_url, {
      PaReq: payment.pa_req,
      MD: payment.md,
      TermUrl: `${config.apiUrl}payment/3ds-callback?lang=ru&order_id=${order_id}&city_id=${cityId}&CultureName=ru-RU&platform=VK`,
    });

    return () => {
      dispatch(resetOrderPayment());
      window.removeEventListener('message', auth3DsCallback);
    };
  }, []);
  return (
    <Panel id={id}>
      <PanelHeader
        left={<PanelHeaderBack className={cx('Panel3Ds__back')} onClick={handleBack} />}
      />
      <iframe
        ref={ref}
        frameBorder="0"
        id="ds3iframe"
        name="3dsiframe"
        title="3dsiframe"
        onLoad={() => setPopout(null)}
        className={cx('Panel3Ds__frame')}
      />
      <Snackbar />
    </Panel>
  );
};

Panel3Ds.defaultProps = {
  order_id: NaN,
};

Panel3Ds.propTypes = {
  order_id: PropTypes.number,
  id: PropTypes.string.isRequired,
  payment: PropTypes.object.isRequired,
  setPopout: PropTypes.func.isRequired,
};

export default Panel3Ds;
