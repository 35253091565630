export const getAllAmountOfGoodsInCart = composition =>
  composition.reduce((accum, item) => accum + item.count, 0);

export const getStockData = state => {
  const { conditions, rewards } = state.cart.stockLoader.data;
  const { index, type } = state.cart.stockData;

  if (type === 'reward' && rewards) {
    const { goods, message } = rewards[index];
    return { goods, title: message };
  }

  if (type === 'condition' && conditions) {
    const { goods, criteria } = conditions[index];
    return { goods, title: criteria };
  }

  return {};
};
